/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 06/09/2021
 */

import _                    from "lodash";
import PropTypes            from 'prop-types';
import React, { Component } from "react";
import {Tooltip}            from "diginet-core-ui/components";
import moment from 'moment';
import { useTheme } from "diginet-core-ui/theme";

class DynamicStepComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            withState: 0,
            totalStatusID: 0
        };
    };

    componentDidMount() {
        const { statusList } = this.props;
        if (!_.isEmpty(statusList)) this.changeWidth(statusList);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.changeWidth(nextProps.statusList);
    };

    changeWidth = (statusList) => {
        const { step } = this.props;
        const totalStatusID = _.get(statusList, "TotalStatusID", 0);
        const withState = step >= totalStatusID ? 100 : (100 / (totalStatusID + 1)) * step;
        setTimeout(() => {
            this.setState({
                withState,
                totalStatusID
            });
        }, 500);
    };

    renderTooltip = (data) => {
        if (_.isEmpty(data)) return "";
        return <div>
            {data && data.map((item, idx) => {
                const date = item.CreateDate ? moment(item.CreateDate).format("DD/MM/YYYY HH:mm") : "";
                return (
                    <div key={idx} className={"text-left pd1x"}>
                        <div>
                            <span style={{fontWeight: "bold"}}>{item.CreateUserName}</span>
                            <span style={{fontStyle: "italic"}}>{date ? ", " + date : ""}</span>
                        </div>
                        <div>{item.Content}</div>
                    </div>
                );
            })}
        </div>;
    };

    render() {
        const { spacing } = useTheme();
        const { withState } = this.state;
        const { step } = this.props;
        const listStatus = _.get(this.props, "statusList.ListStatus", []);
        if (_.isEmpty(listStatus) || !_.isArray(listStatus)) return null;

        return (
            <div style={{ padding: spacing(13) }}>
                <div className={'progressBarAc'}>
                    <div className={'progressBarLi'} ref={'valueProgress'} style={{ width: withState + '%' }} />
                </div>
                <div className={'progressBarBc'}>
                    {listStatus.map((value, index) => {
                        const width = (100 / (listStatus.length + 1)) * (index + 1);
                        const tooltip = this.renderTooltip(value?.info || []);
                        const isActive = index < step;
                        return (
                            <div key={index}>
                                    <span className={'progressActiveAc'} style={{
                                        cursor: 'default',
                                        left: 'calc(' + width + '% - 15px)', top: -16,
                                        border: isActive ? '1px solid #f24646' : '1px solid gray', backgroundColor: isActive ? '#f24646' : 'gray',
                                        color: 'white'
                                    }}>
                                        <Tooltip title={tooltip} direction={"up"} arrow>
                                            <div style={{padding: spacing([1, 2])}}>{value.number}</div>
                                        </Tooltip>
                                    </span>
                                <span className={'progressActiveBc'} style={{ left: 'calc(' + width + '% - 70px)', top: 20, color: isActive ? 'black' : 'gray' }}>
                                    {value.title}
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

DynamicStepComponent.propTypes = {
    step: PropTypes.number,
    statusList: PropTypes.object,
};

export default DynamicStepComponent;
