/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/16 13:49
 * @update 2019/01/16 13:49
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";

class ButtonAdd extends Component {

    onClick() {
        if(this.props.onClick) this.props.onClick();
    }

    render() {
        const {name, className,disabled,id} = this.props;
        return (
            <ButtonBasic id={id} disabled={disabled} className={`${className?className:''} btn-crm`} onClick={()=>this.onClick()}>
                <i className="fas fa-plus color-add" /> {name&&'\u00A0'} {name}
            </ButtonBasic>
        );
    }
}

ButtonAdd.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    id: PropTypes.string,

};

export default ButtonAdd;