/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 */

import React, {useEffect, useRef, useState} from 'react';
import {
    Row,
    Col,
    Button,
    TextInput, Checkbox, Image, Dropdown, Typography, PasswordInput
}                                           from 'diginet-core-ui/components';
import * as userActions                     from "../../actions/user-actions";
import Config                               from "../../config";
import {connect}                            from "react-redux";
import {bindActionCreators}                 from "redux";
import {Button as ButtonSM}                 from 'devextreme-react';
import LocalizedStrings                     from "react-localization";
import EventTracking from "../common/tracking/event";
// import W00F1015                             from "../W0X/W00/W00F1015/W00F1015";
import moment                               from 'moment';

const logoImg      = require('../../images/logo-crm.png');
const dataLanguage = [
    {
        ID:   "vi",
        Name: "Tiếng Việt"
    },
    {
        ID:   "en",
        Name: "English"
    }
];
const Login        = (props) => {
    const [userID, setUserID]                       = useState("");
    const [passW, setPassW]                         = useState("");
    const [message, setMessage]                     = useState("");
    const [isSubmit, setIsSubmit]                   = useState(false);
    const [lang, setLang]                           = useState("vi");
    const [saveAccount, setSaveAccount]             = useState(false);
    const [userTracking, setUserTracking] = useState(null);
    // const [openPopupW00F1015, setOpenPopupW00F1015] = useState(false);

    const refUserID    = useRef(null);

    useEffect(() => {
        let lang               = Config.getLocalStorage("langCRM");
        let accountSaved       = Config.getLocalStorage('LOGINCRM');
        const hasRememberLogin = String(Config.getSetting("FORCE_USER_LOGOUT")).toLowerCase() === "false";
        if (hasRememberLogin) {
            accountSaved = accountSaved && Config.isJson(accountSaved) ? JSON.parse(accountSaved) : null;
            if (accountSaved) {
                setSaveAccount(true);
                setUserID(accountSaved.user);
                setPassW(accountSaved.pass);
            }
        }
        if (lang !== null && typeof lang !== 'undefined') {
            setLang(lang);
        }
        if (refUserID.current) refUserID.current.focus();
    }, []);

    const onKeyDown = (e) => {
        if (e?.key === "Enter") {
            switch (e.target.name) {
                case 'UserID':
                    if (passW) onFormSubmit();
                    break;
                case 'PassW':
                    if (userID) onFormSubmit();
                    break;
                default:
                    break;
            }
        }
    }

    const onEventSave = () => {
        const submit = document.getElementById("btnSubmit");
        submit.click();
    }

    const handleChange = (e) => {
        switch (e.target.name) {
            case "UserID":
                setUserID(e.target.value);
                break;
            case "PassW":
                setPassW(e.target.value);
                break;
            default:
                break;
        }
    };

    const handleLanguageChange = (e) => {
        const {value = "vi"} = e || {};
        Config.setLocalStorage('langCRM', value);
        loadLocalize();
        setLang(value);
    };

    const handleSaveAccount = (e) => {
        setSaveAccount(!!e?.value);
    };

    const loadLocalize = () => {
        let cfLocalize = null;
        let lang       = Config.getLocalStorage("langCRM");

        if (!lang || lang.length !== 2) {
            lang = "vi";
            Config.setLocalStorage('langCRM', 'vi');
        }

        try {
            const lc   = Config.getLocalStorage('LOCALIZE');
            cfLocalize = lc ? JSON.parse(lc) : null;

        } catch (e) {

        }

        Config.localization = new LocalizedStrings(cfLocalize);
        Config.localization.setLanguage(lang);

        if (lang === "vi") {
            Config.language = "84";
        } else {
            Config.language = "01";
        }
    };

    //get user lockout time..
    const getUserLockoutTime = (defaultValue = 10) => {
        let lockoutTime = Config.getSetting("USER_LOCKOUT_DURATION");
        if (!lockoutTime && lockoutTime !== 0) return defaultValue;
        return parseInt(lockoutTime) || defaultValue;
    };

    const onFormSubmit = () => {
        setMessage("");
        if (!userID || !passW) {
            setMessage(Config.lang("Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung"));
            return null;
        }

        // check User is login failed 3 times
        let blockListLogin = {};
        if (userID) {
            blockListLogin = Config.getLocalStorage('BLOCK_LIST_LOGIN', true) || {};
            const lockoutTime = getUserLockoutTime()*60*1000;
            const time     = blockListLogin[userID] ? moment().valueOf() - blockListLogin[userID] : null;
            if (time && time < lockoutTime) { //compare time dynamic from settings
                setMessage(Config.lang(`Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%${moment(lockoutTime - time).format(`m [${Config.lang('phut')}] ss ${Config.lang('Giay').toLowerCase()}`)}%_de_dang_nhap_lai`));
                return null;
            } else if (blockListLogin[userID]) {
                delete blockListLogin[userID];
                Config.setLocalStorage('BLOCK_LIST_LOGIN', JSON.stringify(blockListLogin));
            }
        }

        setIsSubmit(true);
        props.userActions.login({
            username: userID,
            password: passW,
            language: Config.language || "84"
        }, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message   = "";

                switch (errorCode) {
                    case "US034":
                        message = Config.lang("Tai_khoan_chua_duoc_thiet_lap_nhan_vien_Ban_khong_the_dang_nhap");
                        break;
                    case "US004":
                        message = Config.lang("Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    case "US005":
                    case "US029":
                        message = Config.lang("Tai_khoan_khong_co_quyen");
                        break;
                    case "US038":
                        const lockoutTime = getUserLockoutTime();
                        message = Config.lang(`Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%${lockoutTime} ${Config.lang('phut')}%_de_dang_nhap_lai`);

                        // save User to list user is login failed 3 times
                        const blockListLogin   = Config.getLocalStorage('BLOCK_LIST_LOGIN', true) || {};
                        blockListLogin[userID] = moment().valueOf();
                        Config.setLocalStorage('BLOCK_LIST_LOGIN', JSON.stringify(blockListLogin));

                        break;
                    default:
                        message = error.message || Config.lang("Loi_chua_xac_dinh");
                        break;
                }

                setIsSubmit(false);
                setMessage(message);
                return false;
            } else if (data) {
                let user = data.user || {};
                const expire = new Date().getTime() + 7*24*60*60*1000;

                if (saveAccount) {
                    const login = {
                        user: userID,
                        pass: passW
                    };
                    Config.setLocalStorage("LOGINCRM", JSON.stringify(login));
                } else {
                    Config.removeLocalStorage("LOGINCRM");
                }

                Config.token = {
                    id: data.token,
                    expire: expire
                };

                user.type = 2;
                if(Config.localization!==null){
                    Config.localization.setLanguage(lang);
                    Config.setLocalStorage("langCRM", lang);
                }

                Config.setLocalStorage('PROFILE', Config.encryptData(JSON.stringify(user)));
                Config.setLocalStorage('TOKEN', JSON.stringify(Config.token));

                setUserTracking(user);

                setTimeout(()=>{
                    setIsSubmit(false);
                    setMessage("");
                    window.location.reload();
                }, 1000);
            }
        });
    };

    // const toggleW00F1015 = (flag) => {
    //     setOpenPopupW00F1015(flag);
    // }

    const logoImgCustomer  = Config.getSetting('LOGO_URL') ? Config.getSetting('LOGO_URL') : logoImg;
    const allowMultiLang   = process && process.env && process.env.REACT_APP_MULTILANG ? process.env.REACT_APP_MULTILANG : 'YES';
    const hasRememberLogin = String(Config.getSetting("FORCE_USER_LOGOUT")).toLowerCase() === "false";
    return (
        <div className="login-container page-login">
            {userTracking && <EventTracking category={"TRACKING_USER"} action={"LOGIN"} label={userTracking.UserID} /> }
            <div className="login-bound">
                <div className="display_row align-between" style={{width: "100%"}}>
                    <div className={"display_col align-between"} style={{width: "100%"}}>
                        <div className="header form-group">
                            <Typography>{Config.lang("Dang_nhap")}</Typography>
                            <Image className="logo" src={logoImg} width={"auto"} height={"100"}/>
                        </div>
                        <div className="login-form">
                            <Row>
                                <Col xs={12}>
                                    <TextInput
                                        ref={refUserID}
                                        label={Config.lang("Ten_dang_nhap")}
                                        placeholder={Config.lang("Ten_dang_nhap")}
                                        viewType={"underlined"}
                                        value={userID}
                                        onKeyDown={onKeyDown}
                                        name={"UserID"}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12}>
                                    {!hasRememberLogin
                                        ? <PasswordInput
                                            label={Config.lang("Mat_khau")}
                                            placeholder={Config.lang("Mat_khau")}
                                            viewType={"underlined"}
                                            value={passW}
                                            onKeyDown={onKeyDown}
                                            name={"PassW"}
                                            autoComplete={"off"}
                                            secureText
                                            delayOnChange={0}
                                            visibilityToggle={false}
                                            onChange={handleChange}
                                        />
                                        : <PasswordInput
                                            label={Config.lang("Mat_khau")}
                                            placeholder={Config.lang("Mat_khau")}
                                            viewType={"underlined"}
                                            value={passW}
                                            onKeyDown={onKeyDown}
                                            name={"PassW"}
                                            delayOnChange={0}
                                            visibilityToggle={false}
                                            onChange={handleChange}
                                        />
                                    }
                                </Col>
                                <Col xs={12}>
                                    {hasRememberLogin && <Checkbox
                                        checked={saveAccount}
                                        label={Config.lang("Nho_mat_khau1")}
                                        onChange={handleSaveAccount}
                                    />}
                                </Col>
                            </Row>
                            <div className="error">{message}</div>
                            <div className="display_row valign-middle" style={{
                                width:   "100%",
                                padding: "0 20px 20px"
                            }}>
                                <Button
                                    viewType={"filled"}
                                    color={"primary"}
                                    size={"large"}
                                    className={"login-bt"}
                                    label={Config.lang("Dang_nhap")}
                                    loading={isSubmit}
                                    onClick={onEventSave}
                                />
                            </div>
                            {/*<div className="display_row valign-middle" style={{*/}
                            {/*    width:  "100%",*/}
                            {/*    margin: "12px auto"*/}
                            {/*}}>*/}
                            {/*    <Button*/}
                            {/*        viewType={"link"}*/}
                            {/*        color={"primary"}*/}
                            {/*        label={`${Config.lang("Quen_mat_khau")}?`}*/}
                            {/*        onClick={() => toggleW00F1015(true)}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <ButtonSM id="btnSubmit" useSubmitBehavior={true} onClick={onFormSubmit} className="hide"/>
                            <div className="display_row align-between" style={{width: "100%"}}>
                                <div className={"span-language"}>
                                    <Image src={require("../../images/internet.svg")} width={20} height={20}
                                           className={"mgr5"}/>
                                    <Dropdown
                                        dataSource={dataLanguage}
                                        displayExpr={"Name"}
                                        valueExpr={"ID"}
                                        style={{margin: 0, minWidth: 100}}
                                        allowSearch={false}
                                        disabled={allowMultiLang === "NO"}
                                        value={lang}
                                        placeholder={Config.lang("Ngon_ngu")}
                                        onChange={handleLanguageChange}
                                    />
                                </div>
                                <div
                                    className={"span-power"}>{Config.lang("Phien_ban")}: {Config.getSetting('APP_VERSION')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="display_row align-center valign-middle login-company-logo">
                        <div className="display_row align-center valign-middle panel-logo">
                            {Config.getSetting("LOGO_URL") &&
                                <Image src={logoImgCustomer} width={"100%"} height={"auto"}/>}
                            {!Config.getSetting("LOGO_URL") && (
                                <React.Fragment>
                                    <Image src={logoImgCustomer} style={{width: "35%"}}/>
                                    <span style={{
                                        marginLeft: 5,
                                        fontSize:   "2.125rem",
                                        fontWeight: 500
                                    }}>LemonHR</span>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={"login-wrapper-footer"}>
                <Row className={"login-footer"}>
                    <Col sm={6} md={6} xs={12}>
                        <span>DIGINET CORPORATION</span>
                        <label>© Copyright 2020 DigiNet Corporation.</label>
                    </Col>
                    <Col sm={6} md={6} xs={12}>
                        <Image src={require("../../images/logo.png")} width={"auto"} height={"auto"}/>
                    </Col>
                </Row>
            </div>
            {/*<W00F1015 open={openPopupW00F1015} onClose={() => toggleW00F1015(false)}/>*/}
        </div>
    );
}

export default connect(state => ({
        token: state.loading.token,
        setting: state.loading.setting,
        profile: state.loading.profile
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch)
    })
)(Login);
