/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 8/9/2021
 * @Example
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";

class Button extends Component {

    onClick = () => {
        if (this.props.onClick) this.props.onClick();
    }

    getTypeButton = () => {
        const {type, iconProps} = this.props;
        let icon = "";
        switch (type.toLowerCase()) {
            case "excel":
                icon = <i className="fas fa-file-excel text-success" {...iconProps}/>;
                break;
            case "pdf":
                icon = <i className="fas fa-file-pdf text-danger" {...iconProps}/>;
                break;
            case "word":
                icon = <i className="fas fa-file-word text-blue" {...iconProps}/>;
                break;
            default:
                break;
        }

        return {icon};
    };

    render() {
        const {name, className, id, disabled} = this.props;
        const objTypes = this.getTypeButton();
        return (
            <ButtonBasic id={id} className={`${className ? className : ''} btn-crm`}
                         disabled={disabled}
                         onClick={() => this.onClick()}>
                <div className={'display_row align-center justify-middle'}>
                    {this.props.isLoading
                        ? <i className="fas fa-circle-notch fa-spin  text-cornflowerblue"/>
                        : objTypes?.icon}&nbsp;
                    {name && ` ${name}`}
                    {/*{!this.props.isLoading && name ? (' ' + name) : ''}*/}
                </div>
            </ButtonBasic>
        );
    }
}

Button.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    iconProps: PropTypes.object
};

export default Button;
