/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/components/popup-yes-no/popup-yes-no.js
 */

import React, {Component} from 'react';
import './popup.scss';
import Config from "../../config";
import DOMPurify from 'dompurify';

class PopupYesNo extends Component{

    _cbConfirm=()=>{
        if(this.props.cbConfirm){
            this.props.cbConfirm();
        }
    };

    _cbCancel=()=>{
        if(this.props.cbCancel){
            this.props.cbCancel();
        }
    };
    
    render(){
        let {data} = this.props;
        data = data ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} /> : null;
        return(
            <div className="popup">
                {data && <span className="txt-popup-yes-no">{data}</span>}
                <div className="popup-bottom">
                    <button className="popup-btn-ok"
                         autoFocus={true}
                         onClick={this._cbConfirm} >
                        {Config.lang("CRM_Dong_y")}
                    </button>
                    <button className="popup-btn-cancel"
                         onClick={this._cbCancel}>
                        {Config.lang("CRM_Tu_choi")}
                    </button>
                </div>
            </div>
        )
    }
}


export default PopupYesNo;