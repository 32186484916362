/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/container/index.js
 */

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

import * as loadingActions from '../actions/loading-actions';
import * as deviceActions from '../actions/device-actions';
import * as mainActions from '../actions/main-actions';
import * as w17Actions from "../actions/w17-actions";
import * as userActions from "../actions/user-actions";

import Popup from '../components/popup/popup';
import Config from '../config';
import Header from '../components/header/header';
import LoginPage from "../components/login/login";
import {browserHistory} from "react-router";
import {Image} from 'react-bootstrap';
import { Drawer,Toolbar, List } from 'devextreme-react';
import 'core-js';
import NotPermission from '../components/not-permission/not-permission';
import AuthPage from '../components/login/auth';
import Notify from "../components/common/notify/notify";
import Maintenance         from "../components/common/layouts/maintenance";
import moment              from "moment";
import Debugger from "../components/debugger/debugger";
import packaged from '../../package.json';
moment.locale('vi');

const navigation = [
    { id: 1, text: 'Database', icon: 'columnchooser' },
    { id: 2, text: 'Mail', icon: 'email' },
    { id: 3, text: 'General', icon: 'columnfield' },
    { id: 4, text: 'Setting', icon: 'preferences' },
];

function onClickMenu(e) {
    switch (e.itemData.id)
    {
        case 1:
            browserHistory.push(Config.env.url + '/admin/W00F0001');
            break;
        case 2:
            browserHistory.push(Config.env.url + '/admin/W00F0002');
            break;
        case 3:
            browserHistory.push(Config.env.url + '/admin/W00F0003');
            break;
        case 4:
            browserHistory.push(Config.env.url + '/admin/W00F0004');
            break;
        default:
            break;
    }
}

const MyComponent = () => {
    return <div className={'list'}>
        <List
            className={"menu-admin"}
            dataSource={navigation}
            hoverStateEnabled={false}
            activeStateEnabled={false}
            focusStateEnabled={false}
            onItemClick={(e)=>onClickMenu(e)}
            elementAttr={{ class: 'panel-list dx-theme-accent-as-background-color' }}
            width={200} />
    </div>
};

class Index extends Component {

    constructor(props){
        super(props);
        this.state = {
            opened: true,
        };
        this.loading = true;
        this.toolbarItems = [{
            widget: 'dxButton',
            location: 'before',
            options: {
                icon: 'menu',
                onClick: () => this.setState({ opened: !this.state.opened })
            }
        },
            {
                widget: 'dxButton',
                location: 'before',
                options: {
                    icon: 'home',
                    text:'Back to Home',
                    onClick: () => {
                        Config.removeLocalStorage("PROFILE");
                        Config.removeLocalStorage("GNITTES");
                        Config.removeLocalStorage("BD-EGNAHC");
                        browserHistory.push(Config.env.url+"/");
                    }
                }
        },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    icon: 'startswith',
                    text:'Update file language CRM',
                    // onClick: () => this.onGetResource()
                    onClick: () => {}
                }
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    icon: 'startswith',
                    text:'Update file language RPC',
                    // onClick: () => this.onGetResourceRPC()
                    onClick: () => {}
                }
            },
        ];
    }

    updateBadge = () => {
        if (!document.hidden) this.props.mainActions.getNumberBadge();
    };

    componentWillMount = () => {
        this.props.userActions.resetPermission();
        this.props.loadingActions.loading();
        Config.setLangDevextreme();
        this.redirectPage();
    };

    componentDidMount () {
        // Log ngày build code cuối cùng.
        console.log("updatedAt:", moment(packaged?.updatedAt || null).format('LLL'));

        // Log thời gian commit cuối của git log.
        console.log("codeLatest:", moment(new Date(packaged?.gitLatest)).format('LLL'));
    }

    onLogout () {
        this.props.userActions.resetPermission();
        Config.logout();
    }

    /**
     * func update fav icon from system LOGO_URL
     */
    setFaviconEl = () => {

        // get element favicon
        let svg = Config.getLocalStorage("LOGO_URL");

        // check setting change logo
        if(svg && svg === Config.getSetting('LOGO_URL')) return;

        // // save LOGO URL to LocalStorage
        svg = Config.getSetting("LOGO_URL");
        Config.setLocalStorage("LOGO_URL", svg);

        // update fav icon
        const link = document.querySelector('#favicon');
        link.href = svg;

    };

    redirectPage = () => {
        let pathinfo = Config.getLocalStorage('AUTHREDIRECT');
        if (pathinfo) {
            pathinfo = JSON.parse(pathinfo);
            Config.removeLocalStorage('AUTHREDIRECT');
            browserHistory.push({
                pathname: pathinfo.pathname || "",
                state: pathinfo.state
            });
        }
    };

    checkMaintenance = () => {
        const keyFrom = "DateFrom";
        const keyTo = "DateTo";
        const {maintenance} = this.props;
        let result = null;
        if (maintenance && maintenance[keyFrom]) {
            const now = moment();
            //Check current time greater than from time..
            const checkFrom = now.diff(moment(maintenance[keyFrom])) >= 0;
            //Check has't to or current time less than to time..
            const checkTo = !maintenance[keyTo] || now.diff(moment(maintenance[keyTo])) <= 0;
            result = checkFrom && checkTo ? maintenance : null;
        }
        return result
    };

    render() {
        const {children, token, setting, profile, isPermission} = this.props;

        let permission = false;

        if(token && token.id && setting && setting.length > 0) {
            this.loading = false;

            const _loading = document.getElementById("_preloader_loading");
            if (_loading) {
                _loading.style.display = "none";
            }
        }
        if(setting) this.setFaviconEl();

        /* {1: seeker, 2: employer, 3: admin, 4: supporter, 5: bot} */
        if(profile && profile.type !== 0) {
            permission = profile.type;
        }

        const path = window.location.href;
        const pathname = children?.props?.route?.path || "";

        this.isAdmin = path.indexOf("/admin")!==-1 ? true : false;
        if (!this.isAdmin ){
            //Check Has change DB or not?
            const isChangeDB = Config.getLocalStorage('BD-EGNAHC');

            if(isChangeDB!==null && isChangeDB==='1') // Has but clear cache
            {
                Config.removeLocalStorage("PROFILE");
                Config.removeLocalStorage("GNITTES");
                Config.removeLocalStorage("BD-EGNAHC");
            }
            if(permission === 3) permission =false;
        }
        const arrRouterPublic = ["report"];

        // check maintenance
        let maintenance = null;
        const url = new window.URLSearchParams(window.location.search);
        let pass = url.get('pass');
        let localDeployer = Config.getLocalStorage('DEPLOYER_SESSION');
        localDeployer = localDeployer && Config.isJson(localDeployer) ? JSON.parse(localDeployer) : null;

        if(!pass && localDeployer?.pass && moment(localDeployer.time).isAfter(moment())){
          pass = localDeployer?.pass;
        }

        if(Config.deployer !== pass){
            maintenance = this.checkMaintenance();
        }

        if(pass){
          Config.setLocalStorage('DEPLOYER_SESSION', JSON.stringify({pass:pass, time: moment().add(4,'hours')}))
        }

        if((!pass && localDeployer) || (maintenance?.DateTo && moment(maintenance.DateTo).isBefore(moment()))){
          Config.removeLocalStorage('DEPLOYER_SESSION');
        }

        if(this.isAdmin){
            window.location.replace(`${Config.env?.api}/admin`);
        }

        return (
            <div className="display_col full_w">
                {/*{this.loading && (*/}
                {/*    <div className="loading">*/}
                {/*        <div className={'display_row align-center valign-middle'}>*/}
                {/*            <ReactLoading type={'spin'} color="#6FA3F8" />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}

                {!this.isAdmin && !arrRouterPublic.includes(pathname) &&  path.indexOf("/auth") !== -1 &&
                    <div className="display_col">
                        <AuthPage loading={this.loading}/>
                    </div>
                }

                {!this.isAdmin && maintenance &&
                    <div className="display_col">
                        <Maintenance data={maintenance}/>
                    </div>
                }

                {path.indexOf("/auth") === -1 && !maintenance ? (
                    <>
                        {!this.isAdmin && !this.loading && !permission && !arrRouterPublic.includes(pathname) &&
                        <div className="display_col">
                            <LoginPage/>
                        </div>}

                        {/*{this.isAdmin && !this.loading && !permission && <div className="display_col">*/}
                        {/*    <LoginPageAdmin/>*/}
                        {/*</div>}*/}

                        {this.isAdmin && !this.loading && permission===3 &&
                        <div className="body-container display_col">
                            <div className="display_col" style={{display: 'flex', minHeight:'100%'}}>
                                <Toolbar items={this.toolbarItems } style={{backgroundColor: "rgba(18, 13, 17, 0.78)"}}  />
                                <Drawer
                                    opened={this.state.opened}
                                    openedStateMode={"shrink"}
                                    position={"left"}
                                    revealMode={"slide"}
                                    component={MyComponent}
                                    closeOnOutsideClick={true}
                                    height={"100%"}>

                                    <div id={'content-admin'} className={'dx-theme-background-color'}>
                                        {children}
                                        <Image className="logo-admin" src={require('../images/logo.png')}/>
                                    </div>
                                </Drawer>
                            </div>
                        </div>}
                        {/*{this.isAdmin && !this.loading && permission === 2 &&  <LoginPageAdmin/>}*/}
                        {!this.isAdmin && ((!this.loading && arrRouterPublic.includes(pathname)) || (profile && !this.loading && permission)) &&
                        <div className="body-container">
                            {arrRouterPublic.includes(pathname) &&
                                <div className="body-container_right display_col">
                                    {children}
                                    <Notify ref={(ref) => Config.notify = ref}/>
                                </div>
                            }

                            {!arrRouterPublic.includes(pathname) && permission === 2 && profile &&
                            <div className="body-container_right display_col">
                                <div className="display_col" style={{display: 'flex', minHeight:'100%', height: '100vh'}}>
                                    <Header ref={ref=>this.elHeader=ref} profile={profile} permission={permission}/>
                                    <div className="display_row align-between" style={{height:'100%'}}>
                                        <div className='display_col main' style={{position:'relative'}}>
                                            {/*{isPermission === -1 &&*/}
                                            {/*    <Loading/>*/}
                                            {/*}*/}
                                            {isPermission === 0 &&
                                                <NotPermission/>
                                            }
                                            <div style={{opacity: isPermission !== null && !isPermission ? 0 : 1, height: '100%'}}>
                                                {children}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>}
                    </>
                ) : null}

                <Popup ref={(ref)=>Config.popup = ref} />
                <Notify ref={(ref) => Config.notifyError = ref} minWidth={"50%"} anchorVertical={"top"} anchorHorizontal={"center"}/>
                {Config.getSetting('DEBUGGER') === "true" && <Debugger ref={ref => Config.debugger = ref} />}
            </div>
        );
    }
}

export default connect(state => ({
        token: state.loading.token,
        setting: state.loading.setting,
        listMenu: state.w17.listMenu,
        profile: state.loading.profile,
        isPermission: state.user.isPermission,
        maintenance: state.loading.maintenance,
    }),
    (dispatch) => ({
        w17Actions: bindActionCreators(w17Actions, dispatch),
        loadingActions: bindActionCreators(loadingActions, dispatch),
        deviceActions: bindActionCreators(deviceActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)(Index);
