/**
 * @copyright 2019 @ DigiNet
 * @author KIMLONG
 * @create 2019/01/16 13:49
 * @update 2019/01/16 13:49
 */

import React, {Component} from 'react';
import ReactLoading from "react-loading";
import GridContainer from "../grid-container/grid-container";

const styleGridContainer = {
    position: 'absolute',
    bottom: 40,
    left: 0,
    width: '100%',
    zIndex: 1000,
    maxHeight: 300,
    overflowY:'scroll'
};

const styleGridLoading = {
    position: 'absolute',
    bottom: 60,
    left: 0,
    width: '100%',
    zIndex: 1001,
    maxHeight: 30,
};

const styleArrow = {
    position: 'absolute',
    lineHeight:'30px',
    width:34,
    height:34,
    right: 0,
    bottom: 0,
    // zIndex: 1002,
    maxHeight: 34,
    textAlign:'center',
    pointerEvents: 'none',
};

class InputGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFocus: false,
            isSubmit: false
        };
        this.isSubmit = false;
    };

    loadMore = (cb) => {
        if(this.state.isSubmit) return;
        this.setState({
            isSubmit: true
        },()=>{
            if(this.props.loadMore) this.props.loadMore(()=>{
                this.setState({
                    isSubmit: false
                });
                cb && cb();
            });
        });
    };

    onSearch = (e) => {
        if(this.isSubmit) return;
        clearTimeout(this.timeout);
        const vl = e.target.value;
        this.timeout = setTimeout( () => {
            this.setState({
                isSubmit: true
            },()=> {
                if(this.props.onSearch) this.props.onSearch(()=>{
                    this.setState({
                        isSubmit: false
                    });
                },vl);
            })
        }, 800);
    };

    onRowClick = (e) => {
        clearTimeout(this.offGrid);
        if(this.props.onRowClick){
            this.props.onRowClick(e,()=>{
                this.setState({isFocus: false})
            });
        }
    };

    onBlur = () => {
        this.offGrid = setTimeout(()=> {
            this.setState({isFocus: false})
        },200);
    };

    render(){
        const {data, children, keyExpr, placeholder, id, disabled} = this.props;
        const {isSubmit} = this.state;
        return (
            <div style={{position:'relative'}}>
                <input
                    id={id}
                    disabled={disabled && disabled}
                    className={'form-control'}
                    type="text"
                    ref={ref => {this.inputSearch = ref}}
                    placeholder={placeholder ? placeholder : "Enter text"}
                    onChange={this.onSearch}
                    onFocus={()=>{this.setState({isFocus: true})}}
                    onBlur={this.onBlur}
                />
                <i className="fa fa-sort-down" style={styleArrow}/>
                {this.state.isFocus &&
                    <>
                        <GridContainer
                            loadMore={this.loadMore}
                            enabledPage={'disabled'}
                            ref={'inputGrid'}
                            style={styleGridContainer}
                            dataSource={data || []}
                            title={""}
                            widthFull={true}
                            keyExpr={keyExpr}
                            selection={{mode: 'single'}}
                            allowColumnReordering={true}
                            rowAlternationEnabled={true}
                            onRowClick={this.onRowClick}
                        >
                            {children}
                        </GridContainer>
                        {isSubmit &&
                            <div className="display_row align-center valign-middle"
                                 style={styleGridLoading}>
                                <ReactLoading type={"spinningBubbles"}
                                              color={'cornflowerblue'}
                                              height={20}
                                              width={20}
                                />
                            </div>
                        }
                    </>
                }
            </div>

        );

    }
}

export default InputGrid;
