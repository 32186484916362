/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 4/22/2021
 * @Example 
 */

import React from "react";
import Config from "../../../config";

class CORE extends React.Component {

    /**
     * Host (url) core
     * @type {string}
     */
    static host = "https://core.diginet.com.vn";

    /**
     * Token core..
     * @type {string}
     */
    static token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRoIjoiNmY1OTQxM2YtMTdiOS00NWY5LWE3NjgtYjY0MGRhMzZkZGVlIiwiaWF0IjoxNTkzMTY1NzYwLCJleHAiOjE2Nzk1NjU3NjB9.DQsEu7aU6_vhPHhFhnDcNq-x8nceBqVstNgxXNH1Aj8"

    /**
     * Header for core
     * @type {{Accept: string, "Access-Control-Request-Headers": string, "X-Access-Token": string, "Content-Type": string}}
     */
    static headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'content-type,token,secret',
        'X-Access-Token': this.token
    };

    static setHeaders = (header = {}) => {
        if (!header) return false;
        Object.assign(this.headers, header);
    };
    static getHeaders = () => {
        return this.headers;
    };
    static showError = (error) => {
        if (error) {
            Config.popup.show(error);
            return null;
        }
        return null;
    };

    static getProducts = async (config, cb) => {
        const route = "/api/v1.0.0/company/apps";
        let url = config?.url || this.host + route;
        if (config?.url) delete config.url;
        const params = {
            UserID: Config.profile.UserID,
            CompanyCode: Config.env.companyCode || "",
            ...config
        };
        const options = {
            method: "PUT",
            headers: this.getHeaders(),
            body: params ? JSON.stringify(params) : null
        };

        return await fetch(url, options)
            .then(async (resp) => {
                if (resp && resp.ok) {
                    try {
                        const data = await resp.json();
                        if (data && data.data && data.data.length > 0 && data.data[0].apps) {
                            // Config.products = data.data[0].apps;
                            cb && cb(null, data.data);
                            return data.data[0].apps;
                        } else {
                            this.showError({code: 'SYS001', message: 'System error!'});
                        }

                    } catch (e) {
                        this.showError({code: 'SYS001', message: 'System error!'});
                    }
                } else {
                    this.showError({code: 'SYS001', message: 'System error!'});
                }
            }).catch(e => {this.showError(e)});
    };

}

export default CORE;
