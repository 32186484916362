import React from 'react';
import {DropDownBox} from 'devextreme-react';
import DataGrid, {FilterRow, Paging, Scrolling, Selection} from 'devextreme-react/data-grid';
import 'whatwg-fetch';
import PropTypes from 'prop-types';
import {RequiredRule, Validator,} from 'devextreme-react/validator';
import Config from "../../../config";

class Combo extends React.Component {
    constructor(props) {
        super(props);
        this.combo = null;
        this.state = {
            gridBoxValue: '',
            gridSelectedRowKeys: [],
            dataSource: ''
        };
        this.syncDataGridSelection = this.syncDataGridSelection.bind(this);
        this.dataGrid_onSelectionChanged = this.dataGrid_onSelectionChanged.bind(this);
        this.dataGridRender = this.dataGridRender.bind(this);
        this.clear_Value = this.clear_Value.bind(this);
    }

    clearValueDropDown = () => {
        this.setState({
            gridBoxValue: 'clear'
        })
    };

    render() {
        const {value, dataSource, displayExpr, valueExpr, placeholder, reset, required, disabled, showClearButton, id, onOpened, width, styleCustom, className} = this.props;
        const {gridBoxValue} = this.state;

        if (reset) {
            this.clear_Value();
        }
        return (
            <React.Fragment>
                <DropDownBox
                    id={id && id}
                    width={width}
                    ref={(ref) => this.combo = ref}
                    className={"input-devextreme" + (className ? (' ' + className) : '')}
                    value={gridBoxValue === 'clear' ? '' : (Config.isEmpty(gridBoxValue, true) ? value : gridBoxValue)} 
                    disabled={disabled && disabled}
                    valueExpr={valueExpr}
                    deferRendering={false}
                    displayExpr={displayExpr}
                    placeholder={placeholder}
                    showClearButton={showClearButton}
                    dataSource={dataSource}
                    onValueChanged={this.syncDataGridSelection}
                    contentRender={this.dataGridRender}
                    onOpened={(e) => {
                        onOpened && onOpened(e)
                    }}
                    style={styleCustom}
                >
                    {required &&
                    <Validator>
                        <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                    </Validator>
                    }
                </DropDownBox>
            </React.Fragment>
        );
    }

    setValueDropDown = (value) => {
        this.setState({
            gridBoxValue: value
        })
    };

    dataGridRender() {
        const {showHeader, children, virtual, dataSource, height, onContentReady, value, valueExpr, hideSearch, getDataGridRef, idDataGrid, wordWrapEnabled} = this.props;

        if (virtual) {
            return (
                <DataGrid
                    wordWrapEnabled={wordWrapEnabled ? wordWrapEnabled : false}
                    id={idDataGrid && idDataGrid}
                    ref={getDataGridRef}
                    columnResizingMode={'widget'}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    width={"100%"}
                    height={height || 300}
                    showRowLines={true}
                    showBorders={true}
                    dataSource={dataSource}
                    hoverStateEnabled={true}
                    showColumnHeaders={showHeader}
                    selectedRowKeys={!this.state.gridSelectedRowKeys.length ? !Config.isEmpty(value, true) ? [value] : [] : this.state.gridSelectedRowKeys}
                    onSelectionChanged={this.dataGrid_onSelectionChanged}
                    remoteOperations={true}
                    onContentReady={(e) => {
                        onContentReady && onContentReady(e)
                    }}
                >
                    {children}
                    <Selection mode={'single'}/>
                    <Scrolling mode={'virtual'} preloadEnabled={true}/>
                    <Paging pageSize={'50'}/>
                    <FilterRow showOperationChooser={false} showAllText={false} visible={true}/>
                </DataGrid>
            );
        }

        return (
            <DataGrid
                wordWrapEnabled={wordWrapEnabled ? wordWrapEnabled : false}
                id={idDataGrid && idDataGrid}
                ref={getDataGridRef}
                columnResizingMode={'widget'}
                allowColumnResizing={true}
                columnAutoWidth={true}
                width={"100%"}
                height={height || 300}
                showRowLines={true}
                showBorders={true}
                dataSource={dataSource}
                hoverStateEnabled={true}
                keyExpr={!Config.isEmpty(value, true) ? valueExpr : ''}
                showColumnHeaders={showHeader}
                selectedRowKeys={!this.state.gridSelectedRowKeys.length ? !Config.isEmpty(value, true) ? [value] : [] : this.state.gridSelectedRowKeys}
                onSelectionChanged={this.dataGrid_onSelectionChanged}
                onContentReady={(e) => {
                    onContentReady && onContentReady(e)
                }}
            >
                {children}
                <Selection mode={'single'}/>
                <Scrolling mode={'infinite'}/>
                <FilterRow showOperationChooser={false} showAllText={false} visible={!hideSearch}/>
            </DataGrid>

        );

    }

    syncDataGridSelection(e) {
        if (this.props.showClearButton && this.props.onActionWhenSelectChange)
            this.props.onActionWhenSelectChange(e.value);
        if (Config.isEmpty(this.props.value, true) && !!this.state.gridBoxValue !== !!e.value && this.state.gridBoxValue !== e.value) {
            // If set state here, syncDataGridSelection() will be re-do => it runs infinity
            this.setState({
                gridBoxValue: e.value,
                gridSelectedRowKeys: !e.value ? [] : [e.value]
            });
        }
    }

    dataGrid_onSelectionChanged = (e) => {
        const key = this.props.virtual ? e.selectedRowKeys : e.selectedRowsData;
        const newValue = key.length > 0 ? key[0] : null;
        if (newValue) {
            const value = newValue[this.props.valueExpr];
            if (this.props.onActionWhenSelectChange) this.props.onActionWhenSelectChange(newValue, e);
            //nếu onActionWhenSelect setstate thì sẽ render lại và props sẽ cập nhật giá trị và mới chạy tiếp if bên dưới
            if (Config.isEmpty(this.props.value, true) && !this.props.length) {
                this.setState({
                    gridBoxValue: this.props.virtual ? key : value,
                    gridSelectedRowKeys: !value ? [] : key
                });
            } else {
                //Clear state khi props value  có giá trị
                const state = {};
                if (this.state.gridBoxValue) state.gridBoxValue = '';
                if (this.state.gridSelectedRowKeys) state.gridSelectedRowKeys = [];
                if (Object.keys(state).length > 0) this.setState(state);
            }
        }
        if (this.combo && this.combo.instance) this.combo.instance.close();
    };

    clear_Value() {
        if (this.combo && this.combo.instance)
            this.combo.instance.reset();
    }

}

Combo.propTypes = {
    wordWrapEnabled: PropTypes.bool,
    method: PropTypes.any,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    dataSource: PropTypes.any,
    valueExpr: PropTypes.string,
    displayExpr: PropTypes.string,
    onOpened: PropTypes.func,
    showHeader: PropTypes.bool,
    onActionWhenSelectChange: PropTypes.func,
    onContentReady: PropTypes.func,
    required: PropTypes.bool,  //true: input required
    reset: PropTypes.bool, //true: clear value dropdown
    disabled: PropTypes.bool,
    showClearButton: PropTypes.bool,
    virtual: PropTypes.bool, //true: dropdown load more , false: dropdown load all
    height: PropTypes.any,
    id: PropTypes.string,
    idDataGrid: PropTypes.string,
    getDataGridRef: PropTypes.func,

};

export default Combo;
