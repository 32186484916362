/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/components/header/header.js
 */

import React              from 'react';
import {Image, Tab, Tabs} from 'react-bootstrap';
import Config             from '../../config';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {browserHistory} from "react-router";
import * as w17Actions from "../../actions/w17-actions";
import * as deviceActions from '../../actions/device-actions';
import MenuBar from "../common/menu-bar";
import PopupView from "../popup/popup-view";
import * as mainActions from "../../actions/main-actions";
import * as userActions from "../../actions/user-actions";
import ChangPassWord from "../W17/W17F0020";
import ReactLoading from "react-loading";
import * as Firebase from "./push-notification";

import moment from "moment";
import 'moment/locale/vi'
import LinkProducts from "../libs/dgn-core/link-products";

moment.locale('vi');

class Header extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            adminName: "No Name",
            displayMenu: false,
            displayUser: false,
            logoURL: '',
            isLoadingNotify: false,
            totalNew: 0,
            showMore: false,
            tabKey: 0
        };

        this.perPageNotify = 50;
        this.numPageNotify = 0;
        this.isLoadFull = false;
        this.dataFilterNotify = {
            AppID: Config.env.productID || "WCRM",
            limit: this.perPageNotify,
            skip: this.numPageNotify * this.perPageNotify,
            sort: JSON.stringify([{"createdAt": "DESC"}])
        };
        this.theFirst = true;

        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
        this.showDropUser = this.showDropUser.bind(this);
        this.hideDropUser = this.hideDropUser.bind(this);
    };

    getDevice = () => {
        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1 )
        {
            return 'Opera';
        }
        else if(navigator.userAgent.indexOf("Chrome") !== -1 )
        {
            return 'Chrome';
        }
        else if(navigator.userAgent.indexOf("Safari") !== -1)
        {
            return 'Safari';
        }
        else if(navigator.userAgent.indexOf("Firefox") !== -1 )
        {
            return 'Firefox';
        }
        else if((navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true ))
        {
            return 'IE';
        }
        else
        {
            return 'unknown';
        }
    };

    componentDidMount() {
        let login = Config.decryptData(Config.getLocalStorage('PROFILE'));
        login = login ? JSON.parse(login) : null;
        if (login) {
            this.props.mainActions.getNumberBadge();
            const config = Config.getSetting("FIREBASE_UI_CONFIG");
            if (config) {
                Firebase.fbInitializeApp();
                Firebase.fbRequestPermission((error, token) => {
                    if (token) {
                        Config.deviceToken = token;
                        let params         = {
                            md:       this.getDevice(),
                            token:    token,
                            os:       'WEB',
                            language: Config.language
                        };
                        this.props.deviceActions.addDevice(params);
                        Firebase.fbOnMessage((error, {data}) => {
                            const dataPush = JSON.parse(data.payload);
                            // Thêm vào Debugger
                            if (Config.debugger)
                                Config.debugger.pushData({
                                    debugger: { startTime: moment().format() },
                                    code: 200,
                                    data: { ...data, payload: dataPush },
                                    route: "Firebase.fbOnMessage",
                                });
                            const elNotify = document.querySelector('.div-notify');
                            if (elNotify) {
                                this.loadNotify();
                            } else {
                                this.props.mainActions.getNumberBadge();
                            }
                        });
                    }
                });
            }
        }

        this.props.w17Actions.listNotify({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message});
                return false;
            }
        });
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '84';
        const dataName = Config.getSetting("DB_CRM_NAME") || 'DRD02V41';
        const authProduct = Config.getLocalStorage("AUTHPRODUCT");
        const mnParam = {
            "UserID": uName,
            "Language": uLanguage,
            "CompanyID": dataName,
            "ProductID": authProduct || Config.env.productID || ""
        };

        this.props.w17Actions.listMenu(mnParam, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message});
                return false;
            } else if (data && data.length > 0) {
                this.checkShowMore(data);
            }
        });

        this.props.mainActions.listUser(async (errlistUser, datalistUser) => {
            if (errlistUser) {
                let message = errlistUser.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message});
                return false;
            }
            else if (datalistUser && datalistUser.length > 0) {
                Config.setLocalStorage('DATAUSERLIST',Config.encryptData(JSON.stringify(datalistUser)));
            }
        });

        this.loadLogo();
    }

    getNestedChildren = (arr, parent) => {
        let out = [];
        for (let i in arr) {
            if (arr[i].ParentMenuID === parent) {
                let children = this.getNestedChildren(arr, arr[i].MenuID);

                if (children.length) {
                    arr[i].children = children;
                }
                out.push(arr[i]);
            }
        }
        return out;
    };

    checkShowMore = (listMenu) => {
        const data = this.getNestedChildren(listMenu, "");
        data.forEach((m) => {
            if (m.children) {
                this.checkChildShowMore(m)
            }
        });
    };

    checkChildShowMore = (m) => {
        const pth = window.location.href.split('/');
        const mdl = (pth && pth.length > 0) ? pth[pth.length - 1] : '';
        m.children.forEach((child) => {
            if (mdl === child.FormID && child.FormID !== '') {
                this.theFirst = false;
                this.setState({
                    showMore: m.MenuID
                })
            }
            if (child.children) {
                this.checkChildShowMore(child)
            }
        })
    };

    loadNotify = () => {
        this.dataFilterNotify.limit = this.perPageNotify;
        this.setState({
            isLoadingNotify: true
        }, () => {
            if (this.state.displayMenu) {
                const {tabKey} = this.state;
                const action = tabKey === 1 ? "getListNotificationUnread" : "getListNotification";
                this.props.mainActions[action](this.dataFilterNotify, (err, res) => {
                    this.props.mainActions.getNumberBadge();
                    this.setState({
                        totalNew: res && res.totalNew ? res.totalNew : 0
                    });
                    if (res && res.rows && res.rows.length >= res.total) {
                        this.isLoadFull = true;
                    }
                    this.setState({
                        isLoadingNotify: false
                    });
                });
            }
        });
    };

    showDropdownMenu() {
        this.setState({displayMenu: true, displayUser: false}, () => {
            this.loadNotify();
            document.addEventListener('click', this.hideDropdownMenu);
        });

    }

    hideDropdownMenu(e) {
        const container = document.querySelector(".div-notify");
        if (container && !container.contains(e?.target)) {
            this.setState({displayMenu: false}, () => {
                document.removeEventListener('click', this.hideDropdownMenu);
            });
        }
    }

    showDropUser() {
        this.setState({displayUser: true, displayMenu: false}, () => {
            document.addEventListener('click', this.hideDropUser);
        });
    }

    hideDropUser() {
        this.setState({displayUser: false}, () => {
            document.removeEventListener('click', this.hideDropUser);
        });

    }

    hideAddNewPopup() {
        this.setState({showPopup: false, showMore: false});
    }

    onChangePassWord() {
        const rowNew = {
            UserID: '',
        };
        this.setState({row: rowNew, showPopup: true, changePassClass: "changePassCss"});

    }

    onDetailSalesPerson = () => {
        const usalesPersonID = Config.profile.SalesPersonID || '';
        const uUserID = Config.profile.UserID || '';

        if (usalesPersonID && uUserID) {

            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1031',
                state: {
                    mode: "detail",
                    UserID: encodeURIComponent(uUserID),
                    SalesPersonID: encodeURIComponent(usalesPersonID)
                }
            });
        } else {
            return false;
        }
    };

    loadLogo() {
        let data = Config.getLocalStorage('SETTING');
        if (data) {
            this.data = JSON.parse(data);
            let logo_URL = this.data.filter((value, index) => {
                return value.name === "LOGO_URL"
            })
            if (logo_URL.length > 0) {
                this.setState({
                    logoURL: logo_URL[0].value,
                });
            }

        }
    }

    onLogout = () => {
        this.props.userActions.resetPermission();
        Config.logout();
    };

    goHome = () => {
        this.props.userActions.resetPermission();
        browserHistory.push(Config.env.url + '/');
    };

    handleScrollNotify = (event) => {
        let scrollTop = event.target.scrollTop;
        let scrollHeight = event.target.offsetHeight;
        let viewHeight = this.listView.clientHeight;
        if (scrollTop + scrollHeight >= viewHeight) {
            if (this.state.isLoadingNotify || this.isLoadFull) return;
            this.setState({
                isLoadingNotify: true
            }, () => {
                this.perPageNotify += this.perPageNotify;
                this.loadNotify();
            })
        }

    };

    updateNotifications = ({id, payload, hasClose = false} = {}) => {
        const params = {
            id: id ? id : "ALL",
            AppID: Config.env.productID || "WCRM"
        };
        this.props.mainActions.updateNotification(params, (err, res) => {
            if (payload && params?.id !== "ALL") {
                const FormID = payload && payload.FormParentID ? payload.FormParentID : payload.form_id || "";
                browserHistory.push({
                    pathname: Config.getRootPath() + FormID,
                    state: {
                        ...payload,
                        voucher_id: payload && payload.voucher_id ? payload.voucher_id : "",
                    }
                });
            }
            if (this.state.displayMenu) {
                this.loadNotify();
            } else {
                this.props.mainActions.getNumberBadge();
            }
            if (hasClose) this.hideDropdownMenu();
        });
    };

    parseLanguage = (vn, en) => {
        return Config.language === '84' ? vn : en;
    };

    getClassName = (m, idx, child) => {
        // console.log('====== m: ', m);//FormID
        const pth = window.location.href.split('/');
        const mdl = (pth && pth.length > 0) ? pth[pth.length - 1] : '';

        let cls = 'menu-item-child';
        if (child) cls += '-child';

        if (m.Disabled === true) {
            cls += ' disabled-icon';
        }

        if (m && (mdl === m.FormID || (m.children && mdl === m.children.find(e=>e.FormID === mdl))) && m.FormID !== '') {
            cls += ' active';
        }

        return cls;
    };

    getClassNameHome = () => {
        const root = Config.env ? Config.env.REACT_APP_ROOT : '/';
        const pth = window.location.pathname.split(root);
        const mdl = pth && pth[1] ? pth[1] : '';
        let cls = 'menu-item-child-home';
        if (!mdl) {
            cls = 'menu-item-child-home active';
        }
        return cls;
    };

    onMenuClick(url) {
        if (!url || url === '' || url === '#') return false;
        this.props.userActions.resetPermission();
        if (url === '/') {
            browserHistory.push(Config.env.url + '/');
            let el = document.querySelector('.menu-item-child.active');
            let elChild = document.querySelector('.menu-item-child-child.active');
            if (el) el.className = 'menu-item-child';
            if (elChild) elChild.className = 'menu-item-child-child';
            const elHome = document.querySelector('.menu-item-child-home');
            if (elHome) elHome.className = 'menu-item-child-home active';
        } else {
            browserHistory.push(Config.env.url + '/' + url);
            let el = document.querySelector('.menu-item-child.active');
            let elChild = document.querySelector('.menu-item-child-child.active');
            if (el) el.className = 'menu-item-child';
            if (elChild) elChild.className = 'menu-item-child-child';
            const elHome = document.querySelector('.menu-item-child-home');
            if (elHome) elHome.className = 'menu-item-child-home';
            this.refs[url].classList.add('active');
        }
        this.toggleMenuMob();
    }

    toggleMenuMob = () => {
        let el = this.refs['display-menusidebar'];
        if (el) {
            el.className = el.className.indexOf('active') !== -1 ? 'display-menusidebar' : 'display-menusidebar active';
        }
    };

    showMore = (o, showMore, parent) => {
        if (!o.children || o.children.length === 0) return;
        this.setState({
            showMore: showMore ? (parent ? parent.MenuID : false) : o.MenuID,
        });
    };

    renderMenuChild = (o, idx, left, parent) => {
        const cls = this.getClassName(o, idx);
        const showMore = this.state.showMore === o.MenuID || (o.children && o.children.find(e=>e.MenuID === this.state.showMore));
        return (
            <ul className={'nav-item'} style={{
                height: (!showMore ? 30 : 'auto')
            }} key={'ul-' + idx}>
                <div className="display_row align-between align-center"
                     onClick={() => {
                         this.showMore(o, showMore, parent)
                     }}>
                    <div className={cls + ' like-a'}
                       key={o.FormID + idx}
                       ref={o.FormID}
                       style={{marginLeft:left}}
                       onClick={() => {
                           this.onMenuClick(o.FormID)
                       }}
                    >
                        {o.MenuIcon && <i className={o.MenuIcon}/>}
                        {o.MenuName}
                    </div>
                    {o.children && o.children.length > 0 &&
                    <i className={"fas " + (showMore ? 'fa-angle-down' : 'fa-angle-right')}/>
                    }
                </div>
                <div className="display_col wrap">
                    {o.children && o.children.map((item, idx)=>this.renderMenuChild(item, idx, left+10, o))}
                </div>
            </ul>
        )
    };

    onChangeTab = (tabKey) => {
        this.setState({ tabKey }, () => {
            this.loadNotify();
        });
    };

    handleNotify = (key, e) => {
        switch (key) {
            case "reset":
                const { totalNew } = this.state;
                if (e) e.preventDefault();
                if (!totalNew) return false;
                Config.popup.show("YES_NO", Config.lang("Danh_dau_tat_ca_la_da_doc") + "?", () => {
                    this.updateNotifications();
                });
                break;
            case "click":
                this.updateNotifications({...e, hasClose: true});
                break;
            case "scroll":
                let scrollTop = e.target.scrollTop;
                let scrollHeight = e.target.offsetHeight;
                let viewHeight = this.listView.clientHeight;
                if (scrollTop + scrollHeight >= viewHeight) {
                    if (this.state.isLoadingNotify || this.isLoadFull) return;
                    this.setState({
                        isLoadingNotify: true
                    }, () => {
                        this.perPageNotify += this.perPageNotify;
                        this.loadNotify();
                    })
                }
                break;
            default:
                break;
        }
    };

    render() {
        const {listMenu, listNotification, numberBadge} = this.props;
        const {isLoadingNotify, totalNew} = this.state;
        const salesPersonNameU = Config.profile.SalesPersonNameU || '';
        const salesURL = Config.profile && Config.profile.URL ? Config.profile.URL : require('../../images/icon-user-default.png');

        this.loading = (listNotification && listNotification.length > 0) || (listMenu && listMenu.length > 0);

        /* eslint-disable */
        const clsHome = this.getClassNameHome();

        if (listMenu && this.theFirst) this.checkShowMore();

        return (
            <div className="header-container">
                {this.loading && <div className="display_row">
                    <div className="display-menusidebar"
                         ref={'display-menusidebar'}>
                        <div className="icon-menu" onClick={this.toggleMenuMob}
                             style={{fontSize: 25, padding: "0 0px"}}>
                            <i className="fa fa-bars"/>
                        </div>
                        <div className="menu-fixed-bg" onClick={this.toggleMenuMob}/>
                        <div className="menu-fixed">
                            <a className={"avatar-mob"} style={{fontSize: "22px", padding: "13 9px"}}>
                                <Image className="img-avatar"
                                       src={salesURL}/>
                                <span className="user-name-profile">{salesPersonNameU ? salesPersonNameU : ''}</span>
                                {this.state.displayUser && <span className="line-profile"/>}
                            </a>
                            <div className="display_col menu-list">
                                <a className={clsHome}
                                   onClick={() => {
                                       this.onMenuClick('/')
                                   }}>
                                    <i className="fas fa-home"/>
                                    {Config.lang('CRM_Trang_chu')}
                                </a>
                                {listMenu && listMenu.map((item, idx)=>this.renderMenuChild(item, idx,0))}

                                <a className="menu-item-child"
                                   onClick={this.onLogout}>
                                    <i className="fas fa-sign-out-alt"/>
                                    {Config.lang("CRM_Dang_xuat1")}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div onClick={() => this.goHome()} style={{cursor: "pointer"}}>
                        <Image className="header-logo"
                               src={this.state.logoURL ? this.state.logoURL : require('../../images/logo.png')}/>
                    </div>

                    {listMenu && <MenuBar className="display_row align-center" data={listMenu}/>}
                </div>}
                {this.loading &&
                <div className="display_row header-right">
                    <div className="dropdown">
                        <div>
                            <a className="header-name"
                               style={{fontSize: "22px", padding: "10px 9px", textDecoration: 'none'}}
                               onClick={() => this.showDropdownMenu()}>
                                <i className="fa fa-bell text-warnings"/>
                                {!!numberBadge && <span className="txt-number-badge">{numberBadge}</span>}
                            </a>
                        </div>
                        {this.state.displayMenu &&
                        <ul className="div-notify">
                            <div className="fontsize">
                                <div className="notify-header dropdown-header display_row align-center align-between">
                                    <span>{Config.lang("Thong_bao")}</span>
                                    <div>
                                        {!!totalNew && <a onClick={(e) => this.handleNotify("reset", e)}>
                                            {Config.lang("Danh_dau_tat_ca_la_da_doc")}</a>}
                                    </div>
                                </div>
                                <div>
                                    <Tabs activeKey={this.state.tabKey} onSelect={this.onChangeTab}
                                          id="controlled-tab-example" className={"notify-tabs"}>
                                        <Tab eventKey={0} title={Config.lang("Tat_ca")}
                                             style={{padding: 10, border: '1px solid #ddd', borderTop: 0}}>
                                        </Tab>
                                        <Tab eventKey={1} disabled={!totalNew}
                                             title={`${Config.lang("Chua_doc")} (${totalNew ? totalNew : 0})`}
                                             style={{padding: 10, border: '1px solid #ddd', borderTop: 0}}>
                                        </Tab>
                                    </Tabs>
                                </div>
                                <div className="dropdown-body"
                                     onScroll={e => this.handleNotify("scroll", e)}
                                >
                                    <div ref={e => this.listView = e}>
                                        {listNotification && listNotification.length > 0 && listNotification.map((o, i) => {
                                            let userName, userImage;
                                            if(o.sender) {
                                                const user = Config.getUser({UserID: o.sender});
                                                if(user){
                                                    userName = user.SalesPersonNameU;
                                                    userImage = user.URL;
                                                }
                                            }
                                            return (
                                                <li onClick={() => this.handleNotify("click", o)} className="item-notify display_row"
                                                    style={{backgroundColor: o.read === 'N' ? '#edf2fa' : 'white'}}
                                                    key={i}>
                                                    <img src={userImage ? userImage : require('../../images/hinh3.png')}
                                                         className="avatar-sender"/>
                                                    <div style={{width: 'calc(100% - 50px)'}} title={this.parseLanguage(o.body, o.bodyE)}>
                                                        <small className="text-content">
                                                            <b>{userName}</b>{" " + this.parseLanguage(o.body, o.bodyE)}
                                                        </small>
                                                        <small
                                                            className="text-truncate">{moment(o.createdAt).format('DD/MM/YYYY HH:mm')}</small>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                        {isLoadingNotify &&
                                        <div className="display_row align-center valign-middle"
                                             style={{padding: '10px 0'}}>
                                            <ReactLoading type={"spinningBubbles"} color={'grey'} height={20}
                                                          width={20}/>
                                        </div>
                                        }
                                        {!isLoadingNotify && (!listNotification || listNotification.length === 0) &&
                                        <span className='text-no-data'>{Config.lang('CRM_Khong_co_du_lieu')}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </ul>
                        }
                    </div>

                    {/* Link to products */}
                    <LinkProducts />

                    <div className="dropdown">
                        <div onClick={this.showDropUser}>
                            <a className="avatar" style={{fontSize: "22px", padding: "13 9px"}}
                               onClick={() => this.showDropUser()}>
                                {Config.profile && <Image className="img-avatar" style={{marginLeft: 5}}
                                                          src={salesURL ? salesURL : require('../../images/user3.jpg')}/>}
                            </a>
                        </div>
                        {this.state.displayUser &&
                        <ul style={{zIndex: 1000, position: 'absolute', top: '46px', right: '0px', width: '300px'}}>
                            <div className="dropdown-change">
                                <div className="dropdown-header">
                                    <span>{salesPersonNameU ? salesPersonNameU : ''}</span>
                                </div>
                                <div className="dropdown-body" style={{borderBottom: '1px soild #FCEABB !important'}}>
                                    <li className="liUser">
                                        <a style={{fontSize: '12px'}} className="text-truncate font-weight-bold mgl10"
                                           onClick={() => this.onDetailSalesPerson()}>
                                            <i className="fa fa-user mgr10"/>
                                            {Config.lang("CRM_Thong_tin_ca_nhan")}
                                        </a>
                                    </li>
                                    <li className="liUser">
                                        <a style={{fontSize: '12px'}} className="text-truncate font-weight-bold mgl10"
                                           onClick={() => this.onChangePassWord()}>
                                            <i className="fas fa-key mgr10"/>
                                            {Config.lang("CRM_Doi_mat_khau")}
                                        </a>
                                    </li>
                                </div>
                                <div className="dropdown-footer">
                                    <a className="text-truncate font-weight-bold mgl10" onClick={() => this.onLogout()}>
                                        <i className="fas fa-sign-out-alt mgr10"/>
                                        {Config.lang("CRM_Dang_xuat1")}
                                    </a>
                                </div>
                            </div>
                        </ul>
                        }
                    </div>
                    {this.state.showPopup && <PopupView
                        title={"   "}
                        className={`popup-size-40 ${this.state.changePassClass ? this.state.changePassClass : ''}`}
                        show={true}
                        animation={true}
                        onHide={() => this.hideAddNewPopup()}>
                        <ChangPassWord data={this.state.row} onHide={() => this.hideAddNewPopup()}/>
                    </PopupView>}
                </div>}
            </div>
        );
        /* eslint-enable */
    }
}

export default connect(state => ({
        listNotification: state.main.listNotification,
        listMenu: state.w17.listMenu,
        numberBadge: state.main.numberBadge,
    }),
    (dispatch) => ({
        w17Actions: bindActionCreators(w17Actions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        deviceActions: bindActionCreators(deviceActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(Header);

