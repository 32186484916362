/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/01/16 3:23
 * @update 2019/01/16 3:23
 */

import React from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";
import PopUpPrint from "../../common/print/print";
import * as templateDropdownActions from "../../../actions/template-dropdown-actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Config from "../../../config";
import checkExportReport from '../../../actions/async/check-export-report-async';
import ReactLoading from "react-loading";
import Api from "../../../services/api";


class ButtonPrint extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showPopupPrint: false,
            urlExcel: '',
            download: '',
            bDisplay: null,//null không hiện button, 1 hiện excel pdf, 0 hiện pdf
            isLoading: false,
            isLoadingPopup: false,
            isDisabledPDF: false,
            isDisabledEXCEL: false,
            isLoadingPDF: false,
            isLoadingEXCEL: false,
        };
        this.exportExcel = this.exportExcel.bind(this);
    };

    onClick() {
        const data = this.props.cboReportTemplate;
        const param = [...this.props.param];
        const reportTypeID = this.props.reportTypeID;

        if (data && data.length && data.length > 0) {
            if (data.length === 1) {
                param.push({type: "Varchar", id: "ReportID", value: data[0].ReportID});
                const options = {
                    reportURL: data[0].URL,
                    reportID: data[0].ReportID,
                    reportTypeID: reportTypeID,
                    isPDF: '1'
                };
                this.exportExcel(param, options, false);
            } else {
                this.setState({showPopupPrint: true});
            }
        } else {
            Config.popup.show('INFO', Config.lang("CRM_Ban_chua_thiet_lap_Mau_bao_cao"));
        }
    }

    componentDidMount() {
        this.displayButtonExportExcel();
        this.loadData();
    }

    loadData() {
        const param = {
            "reportTypeID": this.props.reportTypeID
        };
        this.props.templateDropdownActions.cboReportTemplate(param, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    }

    async displayButtonExportExcel() {
        const param = {
            "ReportTypeID": this.props.transTypeID
        };
        const result = await checkExportReport(param);
        if (result && result.length > 0) {
            const value = parseInt(result[0].NumValue);
            this.setState({bDisplay: value});
        }
    }

    exportExcel(param, options, popup) {
        if (this.state.isLoading) return;
        this.setState({
            isLoading: !popup,
            isLoadingPopup: popup
        });
        if (options && options.isPDF === '1') {
            this.setState({
                isDisabledPDF: true,
                isDisabledEXCEL: true,
                isLoadingPDF: true,
                isLoadingEXCEL: false,
            });
        }
        if (options && options.isPDF === '0') {
            this.setState({
                isDisabledPDF: true,
                isDisabledEXCEL: true,
                isLoadingPDF: false,
                isLoadingEXCEL: true,
            });
        }
        let url = Config.env.api + "/w17/excel-report-general";
        let params = {
            "token": Config.token.id,
            "TemplateURL": options.reportURL,
            "ReportFileName": options.reportID,
            "ReportTypeID": options.reportTypeID,
            "isPDF": options.isPDF,
            "Param": JSON.stringify(param)
        };

        const data = {
            method: 'PUT',
            headers: Api.headers(),
            body: JSON.stringify(params)
        };

        fetch(url, data)
            .then(response => {
                let content_dispostion = response.headers.get('Content-Disposition');
                if (content_dispostion) {
                    content_dispostion = content_dispostion ? content_dispostion.split('filename=') : '';
                    const filename = content_dispostion.length > 1 ? content_dispostion[1] : 'report-filename.xlsx';
                    this.setState({
                        download: filename.replace(/"/g, '')
                    });
                    return response.blob();
                } else {
                    return response.json();
                }
            })
            .then((result) => {
                if (!result.code) {
                    const objectURL = URL.createObjectURL(result);
                    this.setState({
                        urlExcel: objectURL,
                        isLoading: false,
                        isLoadingPopup: false,
                        isDisabledPDF: false,
                        isDisabledEXCEL: false,
                        isLoadingPDF: false,
                        isLoadingEXCEL: false
                    });
                    let el = document.getElementById("linkExportExcel_1");
                    el.click();
                } else {
                    let message = Config.lang("CRM_Loi_chua_xac_dinh");
                    switch (result.code) {
                        case "EXP001":
                            message = Config.lang("CRM_Khong_co_du_lieu");
                            break;
                        case 'F4000E007':
                            message = Config.lang("CRM_Ban_chua_chon_mau_bao_cao");
                            break;
                        default :
                            message = result.message;
                            break;
                    }
                    this.setState({
                        isLoading: false,
                        isLoadingPopup: false,
                        isDisabledPDF: false,
                        isDisabledEXCEL: false,
                        isLoadingPDF: false,
                        isLoadingEXCEL: false,
                    });
                    Config.popup.show("INFO", message);
                }
            })
            .catch(() => {
                let message = Config.lang("CRM_Loi_chua_xac_dinh");
                this.setState({
                    isLoading: false,
                    isLoadingPopup: false,
                    isDisabledPDF: false,
                    isDisabledEXCEL: false,
                    isLoadingPDF: false,
                    isLoadingEXCEL: false,
                });
                Config.popup.show("INFO", message);
            });
    }

    render() {
        const {name, className, disabled, id, reportTypeID, param} = this.props;
        const {bDisplay, isLoading} = this.state;
        const imageCSS = {
            width: '18px',
            height: '18px'
        };

        return (<span id={'popupPrint'}>
            {this.state.showPopupPrint &&
            <PopUpPrint hideBtnExcel={bDisplay === 1}
                        hideBtnPdf={Number.isInteger(bDisplay)}
                        reportTypeID={reportTypeID}
                        onExportExcel={(param, options) => this.exportExcel(param, options, true)}
                        param={param}
                        loadingPDF={this.state.isLoadingPopup}
                        isDisabledPDF={this.state.isDisabledPDF}
                        isDisabledEXCEL={this.state.isDisabledEXCEL}
                        isLoadingPDF={this.state.isLoadingPDF}
                        isLoadingEXCEL={this.state.isLoadingEXCEL}
                        onHide={() => {
                            this.setState({showPopupPrint: false});
                        }}
            />
            }
                <ButtonBasic id={id}
                             disabled={isLoading || disabled}
                             className={`${className ? className : ''} btn-crm`}
                             onClick={() => this.onClick()}>
                <div className={'display_row align-center justify-middle'}>
                    {
                        !isLoading && <img alt={'not found'}
                                           style={imageCSS}
                                           src={require('../../../images/export-file.png')}/>
                    }
                    {isLoading && (
                        <ReactLoading width={13}
                                      height={16}
                                      type={'spinningBubbles'}
                                      color="#6FA3F8"/>
                    )}
                    &nbsp; {name}
                </div>
            </ButtonBasic>
                  <a id="linkExportExcel_1"
                     className="hide"
                     download={this.state.download}
                     href={this.state.urlExcel}>File</a>
            </span>
        );
    }
}

ButtonPrint.propTypes = {
    disabled: PropTypes.bool,
    startDownload: PropTypes.bool,
    endDownload: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
    reportTypeID: PropTypes.string,
    transTypeID: PropTypes.string,
    param: PropTypes.array
};

export default connect(state => ({
        cboReportTemplate: state.templateDropdown.cboReportTemplate
    }),
    (dispatch) => ({
        templateDropdownActions: bindActionCreators(templateDropdownActions, dispatch)
    })
)(ButtonPrint);