/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/components/popup-info/popup-info.js
 */

import React, {Component} from 'react';
import './popup.scss';
import Config from "../../config";
import DOMPurify from 'dompurify';

class PopupInfo extends Component{

    cbClose=()=>{
        if(this.props.cbClose){
            this.props.cbClose();
        }
    };

    render(){
        let {data} = this.props;
        data = data ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} /> : null;
        return(
            <div className="popup">
                {data && <span className="txt-popup-yes-no">{data}</span>}
                <div className="popup-bottom display_row valign-middle">
                    <button className="popup-btn-cancel"
                            onClick={this.cbClose}>
                        {Config.lang("CRM_Dong_y")}
                    </button>
                </div>
            </div>
        )
    }
}


export default PopupInfo;