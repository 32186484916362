/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/26 19:08
 * @update 2019/02/26 19:08
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w18F1020Actions from "../../../actions/w18F1020-actions";

import {DropDownBox} from 'devextreme-react';
import TreeList, {Column, Selection} from 'devextreme-react/tree-list';
import Config from "../../../config";

class DropDownSaleGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            gridBoxValue: '',
            gridSelectedRowKeys: []
        };

        this.timer = null;
        this.treeList = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 3000;
    }


    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    loadData() {
        const param = {
            "mode": "0"
        };
        this.props.w18F1020Actions.cboGroupSaleW18F1020(param, (error, data) => {
            this.setState({disabled: false});
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else if (data && this.props.loadSuccess) {
                this.props.loadSuccess(data);
            }
        });
    }

    contentRender() {
        const {width, height, cboGroupSaleW18F1020, onGroupValue, value} = this.props;
        let dataSource = [];
        cboGroupSaleW18F1020.forEach(o => {
            if (!onGroupValue || (onGroupValue && o.GroupSalesID !== onGroupValue)) {
                const item = {
                    GroupSalesID: o.GroupSalesID
                    , GroupSalesNameU: o.GroupSalesNameU
                    , GroupSalesParentID: o.GroupSalesParentID
                };
                dataSource.push(item);
            }
        });

        return (
            <TreeList
                width={width || "100%"}
                height={height || 300}
                dataSource={dataSource}
                showRowLines={true}
                showBorders={true}
                columnAutoWidth={true}
                autoExpandAll={true}
                keyExpr={"GroupSalesID"}
                parentIdExpr={"GroupSalesParentID"}
                showColumnHeaders={false}
                selectedRowKeys={!this.state.gridSelectedRowKeys ? [value] : this.state.gridSelectedRowKeys}
                onSelectionChanged={(e) => this.onSelectionChanged(e)}>
                <Selection mode={'single'}/>
                {/*<Scrolling mode={'infinite'} />*/}
                <Column dataField={"GroupSalesID"} visible={false}/>

                <Column dataField={"GroupSalesNameU"}/>
            </TreeList>
        )
    }

    onValueChanged(e) {
        // console.log('======== onValueChanged =======',e);
        if (this.props.showClearButton && this.props.onChange) {
            this.props.onChange(e.value);
        }
        if (!this.props.value) {
            this.setState({
                gridBoxValue: e.value,
                gridSelectedRowKeys: !e.value ? [] : [e.value]
            });
        }

    }

    onSelectionChanged(e) {
        const newValue = e.selectedRowKeys.length > 0 ? e.selectedRowKeys[0] : null;

        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
        if (newValue && !this.props.value) {
            this.setState({
                gridBoxValue: newValue,
                gridSelectedRowKeys: [newValue]
            });
        }
        if (newValue) this.treeList.instance.close();
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        // life cycle này dùng để set giá trị cho state từ props
        let newState = {};
        if (nextProps.value && nextProps.value !== prevState.gridBoxValue && nextProps.value) {
            newState.gridBoxValue = '';
            newState.gridSelectedRowKeys = ''
        }
        if (Object.keys(newState).length) {
            return newState;
        }
        return null;
    }


    render() {
        const {cboGroupSaleW18F1020, value, placeholder, showClearButton} = this.props;

        let dataSource = [];
        if (cboGroupSaleW18F1020) {
            cboGroupSaleW18F1020.map(o => {
                const item = {
                    GroupSalesID: o.GroupSalesID
                    , GroupSalesNameU: o.GroupSalesNameU
                    , GroupSalesParentID: o.GroupSalesParentID
                };
                dataSource.push(item);
                return o;
            });
        }


        return (
            <React.Fragment>
                <DropDownBox
                    ref={(ref) => this.treeList = ref}
                    width={'100%'}
                    className="input-devextreme"
                    value={!this.state.gridBoxValue ? value : this.state.gridBoxValue}
                    valueExpr={"GroupSalesID"}
                    displayExpr={"GroupSalesNameU"}
                    placeholder={placeholder}
                    disabled={this.props.disabled ? this.props.disabled : this.state.disabled}
                    showClearButton={showClearButton}
                    dataSource={dataSource}
                    onValueChanged={e => this.onValueChanged(e)}
                    contentRender={() => this.contentRender()}
                />
            </React.Fragment>
        );
    }
}

DropDownSaleGroup.propTypes = {
    width: PropTypes.any,
    height: PropTypes.any,
    showClearButton: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onGroupValue: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    reset: PropTypes.bool,
};

export default connect(state => ({
        cboGroupSaleW18F1020: state.w18F1020.cboGroupSaleW18F1020
    }),
    (dispatch) => ({
        w18F1020Actions: bindActionCreators(w18F1020Actions, dispatch)
    })
)(DropDownSaleGroup);

