import firebase from 'firebase/app';
import 'firebase/messaging';
import "firebase/analytics";
import Config   from "../../config";

let config = null;
export const fbInitializeApp = () => {
    try {
        config = JSON.parse(Config.getSetting("FIREBASE_UI_CONFIG"));
        if (!config) return null;
        firebase.initializeApp(config);
        firebase.analytics();
    } catch (err) {
        if (!/already exists/.test(err.message)) {
            console.error('Firebase initialization error', err.stack)
        }
    }
};

const fbGetToken = (options, cb) => {
    const vapidKey = Config.getSetting("FIREBASE_UI_VAPIDKEY");
    const messaging = firebase.messaging();
    messaging.getToken({vapidKey: vapidKey || "", ...options})
        .then((token) => {
            if (token) {
                if (cb) cb(null, token);
                return token;
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log('No registration token available. Request permission to generate one.');
                // shows on the UI that permission is required
            }
        }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
};

export const fbRequestPermission = async (cb) => {
    try {
    // console.log('====== fbRequestPermission ========');
        if (!firebase.messaging.isSupported() || !config) {
            return;
        }
    const swUrl = Config.getRootPath() + "firebase-messaging-sw.js?c=" + encodeURIComponent(JSON.stringify(config));
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register(swUrl)
                .then(function(registration) {
                    // console.log("Registration successful, scope is:", registration.scope);
                    fbGetToken({serviceWorkerRegistration : registration}, cb);
                })
                .catch(function(err) {
                    fbGetToken(null, cb);
                    // console.log("Service worker registration failed, error:"  , err );
                });
        } else {
            fbGetToken(null, cb);
        }
    } catch (error) {
        if (cb) cb(error);
        console.error(error);
    }
};

export const fbOnMessage = (cb) => {
    // console.log('====== fbOnMessage ========');

    try {
        if (!firebase.messaging.isSupported() || !config) {
            return;
        }

        const messaging = firebase.messaging();
        // console.log('====== fbOnMessage1 ========');

        messaging.onMessage(function(payload) {
            if (cb) cb(null, payload);
        });
    } catch (error) {
        if (cb) cb(error);
        console.error(error);
    }
};
