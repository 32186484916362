/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/16 3:23
 * @update 2019/01/16 3:23
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";
const styleProp={
    color:'cornflowerblue'
};
class ButtonSaveNext extends Component {

    onClick() {
        if(this.props.onClick) this.props.onClick();
    }

    render() {
        const {name,className,disabled,id} = this.props;
        return (
            <ButtonBasic id={id} disabled={disabled} className={`${className?className:''} btn-crm`} onClick={()=>this.onClick()}>
                <i style={styleProp}  className="fas fa-plus"/> {name&&'\u00A0'} {name}
            </ButtonBasic>
        );
    }
}

ButtonSaveNext.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,

};

export default ButtonSaveNext;