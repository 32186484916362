/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/02/26 19:08
 * @update 2019/02/26 19:08
 * Dropdown nhân viên kinh doanh dưới win
 * Xử lý và đổ nguồn khác nhau - trùng tên
 */

import React, {PureComponent, memo, Fragment} from 'react';
import PropTypes from 'prop-types';
import * as action from "../../../../actions/template-dropdown-actions";
import Combo from '../combo';
import {Column} from 'devextreme-react/data-grid';

class Container extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: null,
            reset: false
        }
    }

    getDataSource = () => {
        const param = {
            SalesGroupID: this.props.salesGroupID
        };

        action.cboSalePersonWin(param, (error, data) => {
            this.setState({
                dataSource: data
            })
        })

    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.salesGroupID !== this.props.salesGroupID) {
            this.setState({
                reset: true
            });
            this.getDataSource();
        }
    }

    componentDidMount() {
        this.getDataSource();
    }

    onChange = (e) => {
        const {onChange} = this.props;
        this.setState({
            reset: false
        });
        onChange && onChange(e);
    };

    render() {
        const {showClearButton, placeholder, disabled, value,} = this.props;
        const {reset} = this.state;
        const {dataSource} = this.state;

        return (
            <Ui showClearButton={showClearButton}
                placeholder={placeholder}
                disabled={disabled}
                onChange={this.onChange}
                value={value}
                reset={reset}
                dataSource={dataSource}
            />)

    }
}

const Ui = memo((props) => {
    const {showClearButton, placeholder, disabled, onChange, reset, dataSource, value} = props;

    return <Fragment>
        <Combo
            disabled={disabled}
            reset={reset}
            showClearButton={showClearButton}
            showHeader={false}
            dataSource={dataSource}
            placeholder={placeholder}
            value={value}
            valueExpr="SalesPersonID"
            displayExpr="SalesPersonName"
            onActionWhenSelectChange={onChange}
        >
            <Column dataField={'SalesPersonID'}
                    visible={false}
                    dataType={'string'}/>
            <Column dataField={'SalesPersonName'}
                    dataType={'string'}/>
        </Combo>
    </Fragment>
});


Container.propTypes = {
    showClearButton: PropTypes.bool,
    placeholder: PropTypes.string,
    salesGroupID: PropTypes.string,
    value: PropTypes.string,
    reset: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    width: PropTypes.any,
};

export default Container