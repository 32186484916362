/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/4/2019
 * @Example 
 */

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Config from "../../../config";
import {withStyles} from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";

const styles = {
    dpaper: {
        alignSelf: 'start',
        maxHeight: 'calc(100% - 30px)',
    },
    dwrapper: {
        maxHeight: 'calc(100vh - 30px)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '100%'
    },
    dactions: {
        padding: '12px 24px'
    },
	title: {
		fontSize: '17px',
		fontWeight: 500
	}
};

class Modal extends PureComponent {
    constructor(props) {
        super(props);
        this.component = React.createRef();
        this.dialog = null;
        this.state={
            isContentWrapper: true
        }
    }

    componentDidMount() {
        const {onOpen} = this.props;
        if (onOpen) onOpen();
    }

    onEntering = () => {
        if (this.dialog) {
            if (this.dialog.getElementsByClassName("MuiDialogContent-root").length < 1) {
                this.setState({isContentWrapper: false})
            }
        }
    };

    onEntered = () => {
        setTimeout(() => {
            if (this.dialog) {
                this.dialog.getElementsByClassName("MuiDialog-container")[0].setAttribute("tabindex", "auto");
            }
        }, 400);

    };

    onClose = (e) => {
        const {onClose} = this.props;
        if (onClose) onClose(e);
    };

    render() {
        const {open, title, component, children, className, style, fullWidth, maxWidth, transition,
            classes, paperProps, onOpen, onClose, disableBackdropClick, actionComponents, ...props} = this.props;
        const {isContentWrapper} = this.state;
        const popupTransitions = Config.helpers.popupTransitions;
        const Trans = transition && popupTransitions[transition] ? popupTransitions[transition] : Config.popupTransition;
		let titleStr = '';
		let fileName = '';
		if (title.includes('#%#')) {
			titleStr = title.split('#%#')[0];
			fileName = title.split('#%#')[1];
		} else {
			titleStr = title;
		}
        return (
            <React.Fragment>
                <Dialog
                    {...props}
                    ref={ref => this.dialog = ref}
                    open={open}
                    className={className}
                    style={style}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    classes={{paper: classes.dpaper}}
                    disableBackdropClick={typeof disableBackdropClick === "undefined" ? true : disableBackdropClick}
                    PaperProps={{
                        style: paperProps && paperProps.style ? {...styles.dpaper, ...paperProps.style} : styles.dpaper,
                        ...paperProps
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onEntering={this.onEntering}
                    onEntered={this.onEntered}
                    onClose={this.onClose}
                    TransitionComponent={Trans}
                >
                    <div className={classes.dwrapper}>
                        {(title || onClose) && <DialogTitle disableTypography>
						<Typography variant="h6" style={styles.title}><img style={{marginTop: -1, transform: 'rotate(-45deg)'}} alt="" src={require("../../../images/file/attachment_24.png")}/> {titleStr} <i style={{marginLeft: 35, fontWeight: 600}}>{fileName}</i></Typography>
                            <IconButton aria-label="close" style={styles.closeButton} onClick={this.onClose}>
                                <CloseIcon style={{fontSize: 20}}/>
                            </IconButton>
                        </DialogTitle>}
                        {!!title && <Divider />}
                        {!isContentWrapper && <Content>
                            {component && component}
                            {!component && children}
                        </Content>}
                        {isContentWrapper && <>
                            {component && component}
                            {!component && children}
                        </>}
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

class Actions extends PureComponent {
    render() {
        const {children} = this.props;

        return (
            <React.Fragment>
                <Divider />
                <DialogActions style={styles.dactions} {...this.props}>
                    {children}
                </DialogActions>
            </React.Fragment>
        );
    }
}

class Content extends PureComponent {
    render() {
        const {children} = this.props;

        return (
            <React.Fragment>
                <DialogContent {...this.props}>
                    {children}
                </DialogContent>
            </React.Fragment>
        );
    }
}

Modal.propTypes = {
    open: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.any,
    component: PropTypes.any,
    title: PropTypes.string,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    transition: PropTypes.string,
    paperProps: PropTypes.any,
    disableBackdropClick: PropTypes.bool,

    actionComponents: PropTypes.any,

    onClose: PropTypes.func,
    onOpen: PropTypes.func,
};
Modal.Content = Content;
Modal.Actions = Actions;

export default withStyles(styles)(Modal);