/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2019/10/16 16:23
 * @update 2019/10/16 16:23
 */

import React from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";
import PopUpPrint from "../../common/print/print";
import * as templateDropdownActions from "../../../actions/template-dropdown-actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Config from "../../../config";
import ReactLoading from "react-loading";
import Api from "../../../services/api";
import Button from "./button";
import ButtonLoading from "./button-loading";
import {createPortal} from "react-dom";

const _initBtnLoading = {
    pdf: false,
    excel: false,
    word: false,
};
class ButtonExport extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showPopupPrint: false,
            urlExcel: '',
            download: '',
            bDisplay: true,
            isLoading: false,
            isLoadingPopup: false,
            btnLoading: _initBtnLoading,
            isImportantData: 0,
            isEditDataExport: 0,
            cboReportTemplate: [],
            loading: false
        };
        this.exportExcel = this.exportExcel.bind(this);
    };

    onClick(mode) {
        let {param, reportTypeID, onShowPop, getParamPrint, onlyShowPopup} = this.props;
        const {cboReportTemplate} = this.state;
        if(getParamPrint){
            param = getParamPrint();
        }
        else{
            param = param ? [...param] : [];
        }
        const data = cboReportTemplate;
        if (data && data.length && data.length > 0) {
            if (data.length === 1 && !onlyShowPopup) {
                param.push({type: "Varchar", id: "ReportID", value: data[0].ReportID});
                const options = {
                    reportURL: data[0].URL,
                    reportID: data[0].ReportID,
                    reportName: data[0].FileName,
                    reportTypeID: reportTypeID,
                    isPDF: mode === 1 ? 1 : 0,
                    exportType: mode === 1 ? "pdf" : (data[0].FileExt ? data[0].FileExt.replace(".", "") : ""),
                    strSQL: data[0]?.StrSQL ? data[0]?.StrSQL : "",
                    fileExt: data[0].FileExt ? data[0].FileExt.replace(".", "") : ""
                };
                this.exportExcel(param, options, false);
            } else {
                this.setState({showPopupPrint: true}, () => {
                    onShowPop && onShowPop();
                });
            }
        } else {
            Config.popup.show('INFO', Config.lang("CRM_Ban_chua_thiet_lap_Mau_bao_cao"));
        }
    }

    loadReportType = () => {
        const param = {
            ReportTypeID: this.props.reportTypeID
        };
        this.props.templateDropdownActions.cboReportType(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else if (data && data.length > 0) {
                this.setState({
                    isImportantData: data[0].IsImportantData ? data[0].IsImportantData : 0,
                    isEditDataExport: data[0].IsEditDataExport ? data[0].IsEditDataExport : 0
                });
            }
        });
    };

    componentDidMount() {
        this.loadReportType();
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.reportTypeID !== this.props.reportTypeID) {
            this.loadReportType();
            this.loadData();
        }

    }

    loadData() {
        const param = {
            "reportTypeID": this.props.reportTypeID
        };


        this.props.templateDropdownActions.cboReportTemplate(param, (error, data) => {
            this.setState({
                loading: true
            });
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            this.setState({
                cboReportTemplate: data
            })
        }, 1);
    }

    exportExcel(param, options, popup) {
        if (this.state.isLoading) return;
        this.setState({
            isLoading: !popup,
            isLoadingPopup: popup
        });
        if (options && this.props.FormID) {
            options.reportTypeID = this.props.FormID
        }
        if (options && options.exportType) {
            this.setState({
                btnLoading: {
                    pdf: options.exportType === "pdf",
                    excel: options.exportType === "xlsx" || options.exportType === "xls",
                    word: options.exportType === "docx" || options.exportType === "docx"
                }
            });
        }

        let url = Config.env.api + "/export/export-flex-report";
        let params = {
            "token": Config.token.id,
            "TemplateURL": options.reportURL,
            "ReportFileName": options.reportID,
            "isPDF": options.isPDF,
            "StoreParams": JSON.stringify(param),
            "ExportType": options?.exportType || "xlsx",
            "FileExt": options?.fileExt || "xlsx",
            "strSQL": options?.strSQL || "",
        };

        const data = {
            method: 'PUT',
            headers: Api.headers(),
            body: JSON.stringify(params)
        };

        fetch(url, data)
            .then(response => {
                let content_dispostion = response.headers.get('Content-Disposition');
                if (content_dispostion) {
                    content_dispostion = content_dispostion ? content_dispostion.split('filename=') : '';
                    const filename = content_dispostion.length > 1 ? content_dispostion[1] : 'report-filename.xlsx';
                    this.setState({
                        download: filename.replace(/"/g, '')
                    });
                    return response.blob();
                } else {
                    return response.json();
                }
            })
            .then((result) => {
                if (!result.code) {
                    const objectURL = URL.createObjectURL(result);
                    const {id} = this.props;
                    this.setState({
                        urlExcel: objectURL,
                        isLoading: false,
                        isLoadingPopup: false,
                        btnLoading: _initBtnLoading
                    });

                    let el = document.getElementById(`${id ? id : ''}linkExportExcel_1`);
                    el.click();
                    this.props.onDownloadComplete && this.props.onDownloadComplete();

                } else {
                    let message = Config.lang("CRM_Loi_chua_xac_dinh");
                    switch (result.code) {
                        case "EXP001":
                            message = Config.lang("CRM_Khong_co_du_lieu");
                            break;
                        case 'F4000E007':
                            message = Config.lang("CRM_Ban_chua_chon_mau_bao_cao");
                            break;
                        default :
                            message = result.message;
                            break;
                    }
                    this.setState({
                        isLoading: false,
                        isLoadingPopup: false,
                        btnLoading: _initBtnLoading
                    });
                    Config.popup.show("INFO", message);
                }
            })
            .catch(() => {
                let message = Config.lang("CRM_Loi_chua_xac_dinh");
                this.setState({
                    isLoading: false,
                    isLoadingPopup: false,
                    btnLoading: _initBtnLoading
                });
                Config.popup.show("INFO", message);
            });
    }

    render() {
        let {name, className, disabled, reportTypeID, param, hideButton, id, onHidePop,
                getParamPrint, onlyShowPopup, btnWord, btnPDF, btnExcel, icon
        } = this.props;
        let {cboReportTemplate, loading, isImportantData, isEditDataExport, btnLoading} = this.state;
        const displayButtonExcel = !(isImportantData === 1 && isEditDataExport === 0);
        /*
         * lƯU Ý KHI GẮN VÀO LƯỚI NÚT HAMBURGER
         *khi gắn vào btnHamburger thì phải disabled 1 nút. hiện tại có trường hợp đổ cùng lúc 2 nút pdf và excel ví dụ màn hình W20F1000
         * phải tách rời ra 2 btn riêng,
         * NAME CỦA NÚT EXCEL, PDF VÀ NAME CỦA NÚT XUẤT DỮ LIỆU PHẢI GIỐNG NHAU
         * */
        const _disabled = !!Object.keys(btnLoading).find(l => btnLoading[l]);
        return (
            <span id={'popupPrint'}>
                {!loading && <ButtonLoading hideText={true}
                                            disabled={true}
                                            name={name}/>}
                {this.state.showPopupPrint &&
                createPortal(<PopUpPrint
                    hideBtnExcel={hideButton ? hideButton !== 'all' && hideButton !== 'excel' : displayButtonExcel}
                    hideBtnPdf={hideButton !== 'all' && hideButton !== 'pdf'}
                    // popupprint hideBtnExcel hideBtnPdf  = true thì hiện false thì ẩn, code cũ không sửa
                    reportTypeID={reportTypeID}
                    onExportExcel={(param, options) => this.exportExcel(param, options, true)}
                    param={getParamPrint ? getParamPrint() : param}
                    btnLoading={this.state.btnLoading}
                    onHide={() => {
                        this.setState({showPopupPrint: false}, () => {
                            onHidePop && onHidePop();
                        });
                    }}
                />, document.getElementById('root'))
                }
                {cboReportTemplate && cboReportTemplate.length === 1 && hideButton !== 'all' && !onlyShowPopup
                    && (
                        <>
                            {hideButton !== 'pdf' && cboReportTemplate[0].IsConvertPDF === 1 &&
                            <Button className={`${className ? className : 'pull-right'} mgr5 btn-pdf-report`}
                                    disabled={_disabled || disabled}
                                    type={"pdf"}
                                    onClick={() => this.onClick(1)}
                                    isLoading={btnLoading?.pdf || false}
                                    name={Config.lang("CRM_Xuat_PDF")}
                                    {...btnPDF}
                            />}
                            {hideButton !== 'excel' && displayButtonExcel && cboReportTemplate[0].ReportType === "Excel" &&
                            <Button isLoading={btnLoading?.excel || false}
                                    disabled={_disabled || disabled}
                                    onClick={() => this.onClick(0)}
                                    type={"excel"}
                                    className={`${className ? className : 'pull-right'} mgr5 btn-excel-report`}
                                    name={Config.lang("CRM_Xuat_Excel")}
                                    {...btnExcel}
                            />}
                            {hideButton !== 'word' && cboReportTemplate[0].ReportType === "Word" &&
                            <Button isLoading={btnLoading?.word || false}
                                    disabled={_disabled || disabled}
                                    onClick={() => this.onClick(2)}
                                    type={"word"}
                                    className={`${className ? className : 'pull-right'} mgr5 btn-excel-report`}
                                    name={Config.lang("CRM_Xuat_word")}
                                    {...btnWord}
                            />}
                        </>
                )}
                {cboReportTemplate && (cboReportTemplate.length > 1 || (cboReportTemplate.length > 0 && onlyShowPopup)) &&
                <ButtonBasic disabled={disabled} className={`${className ? className : ''} btn-crm btn-popup-open`}
                             onClick={() => this.onClick()}>
                    <div className={'display_row align-center justify-middle'}>
                        {icon ? icon : <img alt='' src={require('../../../images/icon-print.png')} width={18} height={18}/>}&nbsp;&nbsp;
                        {this.state.isLoading && (
                            <ReactLoading width={13} height={16} type={'spinningBubbles'} color="#6FA3F8"/>
                        )}
                        {!this.state.isLoading && name ? name : ''}
                    </div>
                </ButtonBasic>}
                <a id={`${id ? id : ''}linkExportExcel_1`}
                   className='hide'
                   download={this.state.download}
                   href={this.state.urlExcel}>File</a>
            </span>
        );
    }
}

ButtonExport.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,// nếu import button export nhiều lần cùng 1 màn hình thì phải khai báo id để không bị trùng
    startDownload: PropTypes.bool,
    endDownload: PropTypes.bool,
    onDownloadComplete: PropTypes.func,
    onHidePop: PropTypes.func,
    onShowPop: PropTypes.func,
    name: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    reportTypeID: PropTypes.string,
    FormID: PropTypes.string,
    transTypeID: PropTypes.string,
    param: PropTypes.array,
    icon: PropTypes.any, //full icon tag, ex: <i className="" />
    IsImportantData: PropTypes.number,
    IsEditDataExport: PropTypes.number,
    btnExcelName: PropTypes.string,
    hideButton: PropTypes.oneOf(['pdf', 'excel', 'all']),
    btnExcel: PropTypes.object, //{name : '', className: ''}
    btnWord: PropTypes.object, //{name : '', className: ''}
    btnPDF: PropTypes.object, //{name : '', className: ''}
    onlyShowPopup: PropTypes.bool
};

export default connect(null,
    (dispatch) => ({
        templateDropdownActions: bindActionCreators(templateDropdownActions, dispatch)
    }),null, {withRef: true}
)(ButtonExport);
