/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/components/info/info.js
 */

import React from 'react';
import {Modal, Image} from 'react-bootstrap';
import './popup.scss';

class PopupView extends React.PureComponent {

    onHide() {
        if (this.props.onHide) this.props.onHide();
    }

    render() {
        const {show, title, children, animation, className, style, styleView} = this.props;
        let strClass = 'popup';
        if (className && className !== '') {
            strClass += ' ' + className;
        }
        // console.log("=============PopupView=============",strClass);
        return (
            <Modal.Dialog onScroll={(e) => e.preventDefault()} style={styleView ? styleView : {}} className={strClass}
                          bsSize="large" show={show ? "true" : "false"} dialogClassName="custom-modal "
                          animation={animation ? "true" : "false"}>
                <div className="table-info display_col" style={style ? style : {}}>
                    <div className="title-bar display_row align-between popup-header">
                        <div className="pu-title">{title || "View detail"}</div>

                        <Image className="btn-close popup-btn-close" onClick={() => this.onHide()}
                               src={require('../../images/icon-close2.png')}/>
                    </div>

                    {children}
                </div>
            </Modal.Dialog>
        );
    }
}

export default PopupView;