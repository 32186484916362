/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/9/2019
 * @Example
 */

import React, {Component} from 'react';

import {createMuiTheme, MuiThemeProvider}   from "@material-ui/core";
import * as _                               from "lodash";
import {ThemeProvider as CoreThemeProvider, useTheme} from "diginet-core-ui/theme";

const coreTheme = useTheme();
class ThemeWrapper extends Component {

    getFontSize = (fontSize) => {
        if (!fontSize) return false;
        return parseInt(fontSize).toString() + "px";
    };

    render () {
        const {color: _color} = this.props;
        const color           = {
            SubColor:       _.get(_color, "SubColor", coreTheme?.colors?.hover || "#111D5E21"),
            MainColor:      _.get(_color, "MainColor", coreTheme?.colors?.primary || "#111D5E"),
            Success:        _.get(_color, "Success", coreTheme?.colors?.success || "#00D68F"),
            Info:           _.get(_color, "Info", coreTheme?.colors?.info || "#0095FF"),
            Warning:        _.get(_color, "Warning", coreTheme?.colors?.warning || "#FFAA00"),
            Danger:         _.get(_color, "Danger", coreTheme?.colors?.danger || "#FF3D71"),
            SecondaryColor: _.get(_color, "SecondaryColor", coreTheme?.colors?.secondary || "#f44336"),
            text:           _.get(_color, "contrastText", coreTheme?.colors?.text.main || "#222B45"),
            placeholder:    coreTheme?.colors?.placeholder || "#7F828E",
            labelText:      coreTheme?.colors?.label || "#222B45",
            subText:        "#687799",
            disabledText:   coreTheme?.colors?.disabled || "#8F9BB3",
            fontSize:       this.getFontSize(coreTheme?.typography?.fontSize || 14),
            fontFamily:     coreTheme?.typography?.fontFamily,
        };
        const typography      = {
            fontFamily: coreTheme?.typography?.fontFamily,
            fontSize:   coreTheme?.typography?.fontSize || 14
        };
        // this.setColorScssStatic(color);
        const theme = createMuiTheme({
            palette:    {
                primary:   {
                    light:        color && color.SubColor ? color.SubColor : '#111D5E21',
                    main:         color && color.MainColor ? color.MainColor : '#111D5E',
                    contrastText: color && color.contrastText ? color.contrastText : '#fafafa',
                },
                secondary: {
                    main: color && color.SecondaryColor ? color.SecondaryColor : '#f44336',
                },
                success:   {
                    main: color && color.Success ? color.Success : '#00D68F',
                },
                info:      {
                    main: color && color.Info ? color.Info : '#0095FF',
                },
                warning:   {
                    main: color && color.Warning ? color.Warning : '#FFAA00',
                },
                danger:    {
                    main: color && color.Danger ? color.Danger : '#FF3D71',
                },
                hover:     {
                    main: '#EFF5FF'
                },
                text:      {
                    subtext: color.subText
                }
            },
            props:      {
                MuiTextField: {
                    margin: 'dense',
                },
            },
            overrides:  {
                MuiTextField:          {
                    root: {
                        backgroundColor: '#fff'
                    }
                },
                MuiFormControlLabel:   {
                    root: {
                        marginLeft: 0
                    }
                },
                MuiTabs:               {
                    root:      {
                        minHeight: 42,
                    },
                    indicator: {
                        height:       4,
                        borderRadius: 1,
                        width:        "95%",
                        marginRight:  2
                    }
                },
                MuiTab:                {
                    root:             {
                        fontSize:  '1rem !important',
                        minHeight: 42,
                        margin:    '0 2px'
                    },
                    textColorPrimary: {
                        color: color.subText
                    }
                },
                MuiOutlinedInput:      {
                    root: {
                        height: '100%'
                    }
                },
                MuiSelect:             {
                    icon:         {
                        top: 'calc(50% - 14px)'
                    },
                    iconOutlined: {
                        right: 0
                    }
                },
                MuiCardContent:        {
                    root: {
                        padding: 15
                    }
                },
                MuiFormHelperText:     {
                    root: {
                        position: "absolute",
                        bottom:   '-20px',
                        left:     0,
                        fontSize: 11
                    }
                },
                MuiAutocomplete:       {
                    popup: {
                        zIndex: 10000
                    }
                },
                PrivateNotchedOutline: {
                    legend: {
                        border: 'none'
                    }
                },
                MuiDialogTitle:        {
                    root: {
                        backgroundColor: '#F7F9FC'
                    }
                },
                MuiDialogActions:      {
                    root: {
                        backgroundColor: '#F7F9FC'
                    }
                },
                MuiButton:             {
                    root: {
                        '&:hover':        {
                            backgroundColor: '#EFF5FF'
                        },
                        '&.Mui-disabled': {
                            color: color.disabledText
                        }
                    },
                },
                MuiFormLabel:          {
                    root: {
                        color:            color.labelText,
                        fontWeight:       coreTheme.typography.label.fontWeight || "bold",
                        fontSize:         coreTheme.typography.label.fontSize || "0.75rem",
                        '&.Mui-disabled': {
                            color: color.disabledText
                        }
                    }
                },
                MuiInput:              {
                    underline: {
                        '&:before': {
                            borderBottom: '1px solid ' + color.subText
                        }
                    }
                },
                MuiInputBase:          {
                    root: {
                        fontWeight:       500,
                        color:            color.MainColor,
                        fontSize:         color.fontSize,
                        '&.Mui-disabled': {color: color.disabledText}
                    }
                },
                MuiDivider:            {root: {backgroundColor: color.disabledText || '#E4E9F2'}},
                MuiIconButton:         {
                    root: {
                        color:            color.subText,
                        '&.Mui-disabled': {color: color.disabledText}
                    }
                },
                MuiChip:               {root: {color: color.subText}}
            },
            typography: {
                fontFamily:    typography.fontFamily || "",
                textTransform: "none",
                fontSize:      parseInt(typography.fontSize),
                button:        {
                    textTransform: 'none',
                },
                input:         {
                    padding: 'inherit'
                },
            }
        });

        const cTheme = {
            typography: {
                button: {
                    textTransform: 'none',
                }
            }
        };

        return (
            <CoreThemeProvider theme={cTheme}>
                <MuiThemeProvider theme={theme}>
                    <div>
                        {this.props.children}
                    </div>
                </MuiThemeProvider>
            </CoreThemeProvider>
        )
    }
}

export default ThemeWrapper;
