import React from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import styled from "styled-components";
import Config from "../../../config";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as W17F0051Action from "../../../actions/w17/w17F0051/w17F0051-actions";
import PropTypes from 'prop-types';

//Đổ nguồn trộn trường
// Kiểu dữ liệu của React beautiful DND
//     tasks: {
//         'task-1': {id: 'task-1', content: ' one 123'},
//         'task-2': {id: 'task-2', content: ' 5415'},
//         'task-3': {id: 'task-3', content: ' 12323314'},
//         'task-4': {id: 'task-4', content: ' 4351116'},
//     },
//     columns: {
//         'column-1': {
//             id: 'column-1',
//             title: 'to do',
//             taskIds: ['task-1', 'task-2', 'task-3', 'task-4'] // Thứ tự sắp xếp
//         }
//     },
//     columnOrder: ['column-1'],


const Container2 = styled.div`
        border: 1px solid lightgrey;
        padding: 10px 0 10px 10px;
        margin-bottom:10px;
        border-top:none;
        border-right:none;
        font-weight:600;
        color:#807676
        background-color:${props => (props.isDragging ? '#0fe8a242' : 'white')}
        box-shadow: -3px 3px 0 0 rgba(53,168,236,.32);
        `;
const Container = styled.div`
        margin:8px;
        border-radius: 2px;
        `;
const Title = styled.h3`
    padding:8px;
    color: #2f78b9;
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    height: 25px;
    padding: 2px 0 0 2px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: #fff;
        `;

const TaskList = styled.div`
        padding:8px`;

export class TemplateMail extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            loading: false,
            tasks: null,
            columns: null,
            columnOrder: ['column-1'],
        };
        this.allowLoad = true;
        this.filter = {
            skip: 0,
            limit: 50,
        }
    }

    componentDidMount() {
        this.loadParamField();
    }

    onDragEnd(result) {
        const {destination, source, draggableId} = result;
        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        const column = this.state.columns[source.droppableId];
        const newTaskIds = Array.from(column.taskIds);
        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, draggableId);
        const newColumn = {
            ...column,
            taskIds: newTaskIds
        };
        const newState = {
            ...this.state,
            columns: {
                ...this.state.dataSourcecolumns,
                [newColumn.id]: newColumn
            }
        };
        this.setState(newState);
    }

    loadParamField() {
        const param = {
            skip: this.filter.skip,
            limit: this.filter.limit,
        };
        this.props.W17F0051Action.loadParamField(param, (error, data) => {

            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({loading: false});
                return false;
            }
            if (data && data.length > 0) {
                this.allowLoad = data.length === this.filter.limit;
                this.setState({loading: false});
                this.formatDataSource(data);
            }
        })
    }

    formatDataSource(data) {
        if (!data) return false;
        let taskData = {}, columnData;
        let tasks = this.state.tasks;
        let columns = this.state.columns;

        this.filter.totalRows = data && data.length > 0 ? data[0].TotalRecord : 0;
        data.forEach((val) => {
            taskData[val.id] = {id: val.id, content: val.name84, code: val.code}
        });
        let taskIds = data.map((val) => {
            return val.id;
        });
        columnData = {
            'column-1': {
                id: 'column-1',
                title: this.props.title && this.props.title,
                taskIds: columns ? [...columns['column-1'].taskIds, ...taskIds] : taskIds
            }
        };

        this.setState({
            tasks: {...tasks, ...taskData},
            columns: columnData,
        })

    }

    loadMore() {
        if (!this.allowLoad) {
            return false;
        }
        const el = document.getElementById('Template-Mail-Body');
        if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
            this.filter.skip = this.filter.skip + 50;
            this.loadParamField();
        }
    }

    render() {
        if (!this.state.tasks || !this.state.columns) return null;
        return (
            <DragDropContext style={{height:'calc(100vh - 500px',overflow:'auto'}}
                             id={'Template-Mail'}
                             onDragEnd={(e) => this.onDragEnd(e)}>
                {this.state.columnOrder.map((columnId, index) => {
                    const column = this.state.columns['column-1'];
                    const task = column.taskIds.map((taskId) => this.state.tasks[taskId]);
                    return (
                        <Container key={index}>
                            <Title>{column.title}</Title>
                            <Droppable droppableId={column.id}>
                                {(provided) => (
                                    <TaskList
                                        id={'Template-Mail-Body'}
                                        onScroll={() => this.loadMore()}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {task.map((tasks, index) => {
                                            return (
                                                <Draggable key={tasks.id} index={index}
                                                           draggableId={tasks.id}>
                                                    {(provided, snapshot) => (
                                                        <Container2
                                                            onClick={() => {
                                                                this.props.onClick && this.props.onClick(tasks)
                                                            }}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                            isDragging={snapshot.isDragging}
                                                        >
                                                            <div>
                                                                <i className="fa-lg fas fa-sticky-note text-orange mgr5"/>{tasks.code}: {tasks.content}
                                                            </div>
                                                        </Container2>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </TaskList>
                                )}

                            </Droppable>
                        </Container>
                    )
                })}

            </DragDropContext>
        )
    }

}

TemplateMail.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
};
export default connect(() => ({}

    ), (dispatch) => ({
        W17F0051Action: bindActionCreators(W17F0051Action, dispatch),
    })
)(TemplateMail);
