/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/16 13:49
 * @update 2019/01/16 13:49
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GridContainer from "../common/grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as w18F1020Actions from "../../actions/w18F1020-actions";
import Config from "../../config";
import {browserHistory} from "react-router";
import GridActionBar from "../common/action-bar/grid-action-bar";
import notify from "devextreme/ui/notify";

import moment from "moment"
import 'moment/locale/vi'

moment.locale('vi');

class ScheduleTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: []
        };
        this.data = {
            salesPersonID: props.data.salesPersonID,
            typeTimeID: props.data.typeTimeID,
            groupSalesID: props.data.groupSalesID,
            taskTypeID: props.data.taskTypeID
        };
    }

    onClick() {
        if (this.props.onClick) this.props.onClick();
    }

    loadSchedule() {
        if (this.props.loadSchedule) this.props.loadSchedule();
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.data) !== JSON.stringify(this.data)) {
            this.data = nextProps.data;
            this.loadSchedule();
        }

    }

    componentDidMount() {
        this.loadSchedule();
        const height = document.querySelector('.list_padding').clientHeight;
        document.querySelector('#scheduleTemplate .dx-datagrid-rowsview').style.maxHeight = "calc(100vh - " + (height + 200) + "px)";
    }

    loadDetail = (taskID) => {
        if (this.props.loadDetail) this.props.loadDetail(taskID);
    };

    onDelete(e) {
        const data = e.row.data;
        const param = {
            TaskID: data.TaskID,

        };
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            this.props.w18F1020Actions.deleteTask(param, (error) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }

                notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 300);
                this.loadSchedule();
            })
        })


    }

    renderActionSchedule(e) {
        const rowData = e.row.data;
        if (!e || (rowData.SalesPersonID !== Config.profile.SalesPersonID)) return null;
        return (
            <GridActionBar
                isPer={4}
                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDelete(e)
                }}
            />
        );
    }

    renderCompanyName(data) {
        if (!data) return null;
        const name = data && data.value ? data.value : '';
        /* eslint-disable */
        return (
            <div><a onClick={() => this.onDetail(data)}>{name}</a></div>
        );
        /* eslint-enable */
    }

    onDetail(e) {
        const companyID = e && e.data && e.data.CompanyID ? e.data.CompanyID : '';
        const IsCustomer = e && e.data && e.data.IsCustomer ? e.data.IsCustomer : 0;
        const IsPartner = e && e.data && e.data.IsPartner ? e.data.IsPartner : 0;
        const IsCompetitor = e && e.data && e.data.IsCompetitor ? e.data.IsCompetitor : 0;
        const permission = e && e.data && e.data.Permission ? e.data.Permission : 0;
        if (IsCustomer === 1) {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Customers', Permission: permission, CompanyID: companyID}
            });
        } else if (IsPartner === 1 && IsCompetitor === 1) {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Competitor', Permission: permission, CompanyID: companyID}
            });
        } else if (IsPartner === 1) {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Partner', Permission: permission, CompanyID: companyID}
            });
        } else if (IsCompetitor === 1) {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Competitor', Permission: permission, CompanyID: companyID}
            });
        } else if (IsCustomer === 1 && IsPartner === 1) {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Customers', Permission: permission, CompanyID: companyID}
            });
        } else if (IsCustomer === 1 && IsCompetitor === 1) {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Customers', Permission: permission, CompanyID: companyID}
            });
        }
    }

    render() {

        const {dataGrid} = this.props;
        const height = document.querySelector('.list_padding').clientHeight + 100;
        return (
            <div id={'scheduleTemplate'} className={'mgt10'}>
                <GridContainer
                    maxHeight={'calc(100vh - ' + height + 'px)'}
                    dataSource={dataGrid || []}
                >
                    <Column
                        dataField={'GroupDate'}
                        groupIndex={0}
                        groupCellRender={
                            (e) => {
                                if (!e) return null;
                                const data = e.row.data;
                                return (
                                    <div>{moment(data.key, 'MM-DD-YYYY').format("dddd - ngày DD/MM/YYYY").toLocaleUpperCase()}</div>
                                )
                            }
                        }
                    />
                    <Column
                        cellRender={(data) =>
                            this.renderActionSchedule(data)}
                        caption={Config.lang("CRM_Hanh_dong")}
                        alignment={'center'}
                        allowSorting={false}
                        showInColumnChooser={false}
                        width={120}
                        fixed={true}
                    />
                    <Column
                        caption={Config.lang("CRM_Thoi_gian")}
                        dataField={'TaskDateTo'}
                        allowSorting={false}
                        width={120}
                        alignment={'center'}
                        cellRender={(e) => {
                            if (!e) return null;
                            const row = e.row.data;
                            const dateF = row.TaskDateFrom;
                            const dateT = row.TaskDateTo;

                            if (moment(dateF).format("DD/MM/YYYY") === moment(dateT).format("DD/MM/YYYY")) {

                                return (<div
                                    className={"timeSchedule"}>{row.TaskDateFrom ? moment(row.TaskDateFrom).format("h:mm A") : ''} {row.TaskDateTo ? '-' : ''}{row.TaskDateTo ? moment(row.TaskDateTo).format("h:mm A") : ''}</div>)
                            } else {
                                return (<div
                                    className={"timeSchedule"}>{row.TaskDateTo ? moment(row.TaskDateTo).format("h:mm A") : ''}</div>)

                            }

                        }}
                    />
                    <Column
                        dataField={'TitleU'}
                        caption={Config.lang("CRM_Noi_dung_cong_viec")}
                        allowSorting={false}
                        width={500}
                        cellRender={(e) => {
                            if (!e || !e.row || !e.row.data) return null;
                            const row = e.row.data;
                            return (
                                <div className="text-blue"
                                     style={{cursor: 'pointer'}}
                                     onClick={() => this.loadDetail(row.TaskID)}>
                                    <i className={"fas fa-circle fa-xs "}>&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                    {row.TitleU}
                                </div>
                            )
                        }}
                    />
                    <Column
                        dataField={'SalesPersonName'}
                        caption={Config.lang("CRM_Nguoi_phu_trach")}
                        allowSorting={false}
                        width={200}
                    />
                    <Column
                        dataField={'CompanyName'}
                        caption={Config.lang("CRM_Khach_hang")}
                        width={200}
                        allowSorting={false}
                        cellRender={(data) => this.renderCompanyName(data)}
                    />
                    <Column
                        dataField={'Location'}
                        caption={Config.lang("CRM_Dia_diem")}
                        width={250}
                        allowSorting={false}

                    />
                    <Column
                        dataField={'ResultsU'}
                        width={300}
                        caption={Config.lang("CRM_Ket_qua")}
                        allowSorting={false}

                    />
                    <Column
                        dataField={'ActEvaluationU'}
                        caption={Config.lang("CRM_Danh_gia")}
                        width={300}
                        allowSorting={false}


                    />
                    <Column
                        dataField={'IsAttach'}
                        caption={Config.lang("CRM_Tai_lieu")}
                        alignment={'center'}
                        width={80}
                        allowSorting={false}
                        cellRender={(e) => {
                            if (!e) return null;
                            const row = e.row.data;
                            if (row.IsAttach >= 1) {
                                return (<div>{row.IsAttach}&nbsp;&nbsp;<i className={"fas fa-paperclip text-orange "}/>
                                </div>)
                            } else {
                                return null
                            }
                        }}
                    />
                    <Column
                        dataField={'CreateDate'}
                        caption={Config.lang("CRM_Ngay_tao")}
                        alignment={'center'}
                        format={'dd/MM/y'}
                        width={100}
                        allowSorting={false}
                        dataType={'date'}


                    />
                    <Column
                        dataField={'LastModifyDate'}
                        caption={Config.lang("CRM_Ngay_chinh_sua_cuoi")}
                        width={150}
                        allowSorting={false}
                        format={'dd/MM/y'}
                        dataType={'date'}
                        alignment={'center'}


                    />
                </GridContainer>
            </div>
        )
    }
}


ScheduleTemplate.propTypes = {
    onClick: PropTypes.func,
    data: PropTypes.object
};

export default connect(state => ({
        scheduleData: state.w18F1020.scheduleData
    }),
    (dispatch) => ({
        w18F1020Actions: bindActionCreators(w18F1020Actions, dispatch),
    })
)(ScheduleTemplate);