/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/06/30 15:34
 * @update 2017/06/30 15:34
 * @file src/components/common/course/course.js
 */

import React, {Component} from 'react';
import Select,{makeAnimated, createFilter} from 'react-select';
const animatedComponents = makeAnimated();

class DropDown extends Component {
    constructor(props){
        super(props);
        this.state={
            selectedOption: null
        };
    }

    onChange = (selectedOption) => {
        this.setState({ selectedOption });
        if(this.props.onActionWhenSelectChange) this.props.onActionWhenSelectChange(selectedOption)
    };

    clearValue = () => {
        const {displayExpr,valueExpr} = this.props;
        this.setState({ selectedOption: null });
        let dataReturn = {};
        dataReturn[valueExpr] = '';
        dataReturn[displayExpr] = '';
        if(this.props.onActionWhenSelectChange) this.props.onActionWhenSelectChange(dataReturn);
    };

    componentWillReceiveProps(nextProps) {
        const {dataSource,valueExpr,isMulti} = this.props;
        const {defaultValue} = nextProps;
        if(nextProps && defaultValue){
            const itemFind = dataSource.find(o=>o[valueExpr] === defaultValue);
            this.setState({ selectedOption: itemFind ? itemFind : null });
        }
        if(dataSource && defaultValue && isMulti){
            this.setState({ selectedOption: defaultValue ? defaultValue : null });
        }
    }

    componentDidMount() {
        const {defaultValue,dataSource,valueExpr,isMulti} = this.props;
        if(defaultValue){
            const itemFind = dataSource.find(o=>o[valueExpr] === defaultValue);
            this.setState({ selectedOption: itemFind ? itemFind : null });
        }
        if(defaultValue && isMulti){
            this.setState({ selectedOption: defaultValue ? defaultValue : null });
        }
    }

    render() {
        const {dataSource,placeholder,disabled,loading,isMulti,displayExpr,valueExpr} = this.props;
        const { selectedOption } = this.state;
        const colourStyles = {
            control: styles => ({ ...styles, backgroundColor: 'white' }),
            option: (styles) => {
                return {
                    ...styles,
                    backgroundColor:'white',
                    color:'#333'
                };
            },
            placeholder: styles => ({ ...styles, color:'lightgrey'}),
            menuPortal: styles => ({ ...styles, zIndex: 100 }),
            menu: styles => ({ ...styles, zIndex: 100 })
        };
        return (
            <Select
                components={animatedComponents}
                value={selectedOption}
                onChange={this.onChange}
                placeholder={placeholder}
                options={dataSource}
                isDisabled={disabled ? disabled : false}
                isLoading={loading ? loading : false}
                styles={colourStyles}
                isMulti={isMulti ? isMulti : false}
                className={isMulti ? "basic-multi-select" : ''}
                classNamePrefix="select"
                backspaceRemovesValue={false}
                // blurInputOnSelect={false}
                filterOption={createFilter({ignoreAccents: false})}
                getOptionLabel={(data) => data[displayExpr]}
                getOptionValue={(data) => data[valueExpr]}
            />
        );
    }


}

export default DropDown;
