/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/02/26 19:08
 * @update 2019/02/26 19:08
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as templateDropdownActions from "../../../../actions/template-dropdown-actions";
import Combo from '../combo';
import {Column} from 'devextreme-react/data-grid';

import Config from "../../../../config";

class DropDownTaskType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemType: this.props.itemType,
            ID: this.props.value,

        };
        this.dateFrom='';
        this.dateTo='';
        this.defaultID='';
        this.dataSource =[];
    }

    // componentWillUpdate(nextProps, nextState) {
    //     if (this.props.listType !== nextProps.listType) {
    //         this.setState({
    //             listType: nextProps.listType
    //         },()=>{ this.loadData();});
    //
    //     }
    // }

    componentDidMount() {
        this.loadData();

    }

    loadData() {
        let param = {
            'ItemType': this.state.itemType
        };
        this.props.templateDropdownActions.loadCBTaskType(param, (error, data) => {
            if (error) {
                let message = error.message ||  Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }

        });
    }

    onChange(data) {
            if(this.props.onChange)
                this.props.onChange(data);
    }

    render() {
        const {cboTaskType,showClearButton,placeholder} = this.props;

        this.dataSource =[];
        if (cboTaskType) {
            cboTaskType.map(o => {
                const item = {
                    ID: o.ItemID
                    , Name: o.DescriptionU
                };
                this.dataSource.push(item);
                return o;
            });
        }else{
            return null;
        }


        return (
            <div>
                <Combo
                    showHeader={false}
                    showClearButton={showClearButton}
                    dataSource={this.dataSource}
                    placeholder={placeholder}
                    valueExpr="ID"
                    displayExpr="Name"
                    onActionWhenSelectChange={(data) => {this.onChange(data)}}
                >
                    <Column dataField={'ID'} caption="" width={'100px'} visible={false} dataType={'string'}/>
                    <Column dataField={'Name'} caption=""  width={'100%'} dataType={'string'}/>
                </Combo>
            </div>
        );
    }
}

DropDownTaskType.propTypes = {
    showClearButton: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    itemType:PropTypes.string,

    onChange: PropTypes.func,
};

export default connect(state => ({
        cboTaskType: state.templateDropdown.cboTaskType
    }),
    (dispatch) => ({
        templateDropdownActions: bindActionCreators(templateDropdownActions, dispatch)
    })
)(DropDownTaskType);