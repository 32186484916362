import React from "react";

import { Typography } from "@material-ui/core";

import { useStyles } from "./styles";
import { Image, Typography as TypographyCore } from 'diginet-core-ui/components';
import Config from "../../config";
const HeaderReport = () => {
  const classes = useStyles();
  return (
    <>
      <div className={'mgv8x'}>
          <Image height={65} width={'auto'} src={Config.getLocalStorage('LOGO_URL') || require('../../images/logo.png')} />
      </div>
      <Typography
        className={classes.mainTitle}
        gutterBottom
        align="center"
        variant="h3"
      >
          {Config.lang("Khao_sat_y_kien_khach_hang")}
      </Typography>
      <div className={classes.boxSub} style={{ textAlign: 'center' }}>
        <TypographyCore type={"h4"} color={"secondary"} format={["italic"]}>
          {Config.lang("Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan")}
          <TypographyCore type={"h1"} color={"secondary"} format={["italic"]} className={'mgv1x'}>❧</TypographyCore>
        </TypographyCore>
      </div>
    </>
  );
};

export default HeaderReport;
