/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/16 13:49
 * @update 2019/01/16 13:49
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';

import moment from "moment";
import 'moment/locale/vi'
moment.locale('vi');

const cssInfo = {
    borderColor:'#3298FD',
    color:'#3298FD',
};

const cssLate = {
    borderColor:'#FE0101',
    color:'#FE0101',
    animation: 'fadeIn 1s infinite alternate'
};

class ItemTemplate extends Component {

    onClick() {
        if(this.props.onClick) this.props.onClick();
    }

    render() {
        const {data} = this.props;
        const dateTo =
            <div className="txt-date-time" style={(data.FinishDate > data.TaskDateTo && data.TaskStatus === 'COMPLETE') ? cssLate : cssInfo}>
                <i className="fas fa-clock"/>
                {moment(data.TaskDateTo).format('DD/MM')}
            </div>;

        let colorPriority = '';
        let namePriority = '';
        switch (data.Priority) {
            case 'N':
                colorPriority = 'orange';
                namePriority = 'Bình thuờng';
                ; break;
            case 'H':
                colorPriority = 'red';
                namePriority = 'Cao';
                break;
            case 'L':
                colorPriority = 'green';
                namePriority = 'Thấp';
                break;
            default :
                colorPriority = '';
                namePriority = '';
                break;
        }
        return (
            <div onClick={()=>{this.onClick()}}>
                <span className='wth100'>
                    {data.TitleU}
                </span>
                <div className="display_row align-center align-between">
                    {data.ActEvaluationU &&
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">{data.ActEvaluationU}</Tooltip>}>
                            <i style={{color: '#f5e342'}} className="fas fa-star"/>
                        </OverlayTrigger>
                    }
                    {!data.ActEvaluationU && <i style={{color: 'gray'}} className="fas fa-star"/>}
                    {!!data.IsAttach && <i className="fas fa-paperclip"/>}
                    <div className="full_w display_row align-left">
                        {data.SalesPersonName && <img alt="" src={data.URL ? data.URL : require('../../images/hinh3.png')}/>}
                    </div>
                    {namePriority && <span className="priority-view"
                                           style={{backgroundColor: colorPriority}}>
                                            <i className="fas fa-arrow-up"/>
                                            {namePriority}
                                    </span>}
                    {data.TaskDateTo && dateTo}
                </div>
            </div>
        );
}
}


ItemTemplate.propTypes = {
    onClick: PropTypes.func,
};

export default ItemTemplate;