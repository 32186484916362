/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/15 10:25
 * @update 2019/02/15 10:25
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonFilter from "../button/button-filter";
import Config from "../../../config";

class GridFilterBar extends Component {
    onFilter(o) {
        // console.log('==== GridFilterBar => onFilter => option: ', o);

        if (this.props.onFilter) {
            this.props.onFilter(o);
        }
    }

    getToTal () {
        const {filterData} = this.props;

        if (!filterData || filterData.length === 0) {
            return 0;
        }

        let total = 0;
        for (let i = 0; i < filterData.length; i++) {
            const f = filterData[i];
            total += f.Num;
        }

        return total;
    }

    render() {
        const {filterData} = this.props;

        // console.log('====== filterData', filterData);
        return (
            <div className="toolbar-container filter-bar wrap">
                <ButtonFilter key={0} name={Config.lang("CRM_Tat_ca") + ' (' + this.getToTal() + ')' } onClick={()=>this.onFilter('')}/>

                {filterData && filterData.map((o, i) => {
                    return (<ButtonFilter key={i+1} name={o.CompanyStatus} onClick={()=>this.onFilter(o)}/>);
                })}

            </div>
        );
    }
}

GridFilterBar.propTypes = {
    filterData: PropTypes.array,

    onFilter: PropTypes.func
};

export default GridFilterBar;