/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/16 3:23
 * @update 2019/01/16 3:23
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";

class ButtonDelete extends Component {

    onClick = (e) => {
        const {onClick} = this.props;
        onClick && onClick(e)
    }

    render() {
        const {name, className, disabled, id} = this.props;
        return (
            <ButtonBasic id={id}
                         disabled={disabled}
                         className={`${className ? className : ''} btn-crm`}
                         onClick={this.onClick}>
                <i className="fas fa-trash-alt text-red"/> {name && '\u00A0'} {name}
            </ButtonBasic>
        );
    }
}

ButtonDelete.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,

};

export default ButtonDelete;