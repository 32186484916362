/**
 * @copyright 2018 @ C.O.I
 * @author XuanVInh
 * @create 2017/06/30 15:34
 * @update 2017/06/30 15:34
 * @file src/components/common/course/course.js
 * Hiện tại ruyền đường dẫn API dổ hết data không có skip và limit
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as action from "../../../actions/advanced-search";

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: []
        }
    }

    loadData = () => {
        // Set data khi khởi tạo
        const {apiAddress, name, id, onInit} = this.props;
        action.getDynamicDropdown(apiAddress, (error, data) => {
            this.setState({
                dataSource: data
            });
            const param = {
                id: id,
                value: data.length ? data[0][name] : ''
            };
            onInit && onInit(param)
        })
    };

    componentDidMount() {
        this.loadData();
    }

    _onChange = (e) => {
        const {onChange} = this.props;
        const param = {
            id: e.target.id,
            value: e.target.options[e.target.selectedIndex].value
        };
        onChange && onChange(param);
    };


    render() {
        let {dataSource} = this.state;
        let {keyID, name, id, disabled, value} = this.props;

        return (
            <Ui keyID={keyID}
                value={value}
                disabled={disabled}
                id={id}
                name={name}
                dataSource={dataSource}
                onChange={this._onChange}/>
        )
    }
}

const Ui = (props) => {
    const {dataSource, keyID, name, value, onChange, id, disabled} = props;

    return <select id={id}
                   value={value}
                   className={'form-control'}
                   disabled={disabled}
                   onChange={onChange}
    >
        {dataSource.map((value, index) => {
            return <option key={`${value[keyID]}-${index}`} value={value[keyID]}>{value[name]}</option>
        })}
    </select>
};


Container.propTypes = {
    apiAddress: PropTypes.string,
    disabled: PropTypes.bool,
    keyID: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
};

export default Container;