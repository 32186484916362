/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/16 3:23
 * @update 2019/01/16 3:23
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";

class ButtonView extends Component {

    onClick() {
        if (this.props.onClick) this.props.onClick();
    }

    render() {
        const {name, className, disabled, id,domProps} = this.props;

        return (
            <ButtonBasic domProps={domProps}
                         id={id}
                         disabled={disabled}
                         className={`${className ? className : ''} btn-crm`}
                         onClick={() => this.onClick()}>
                <i style={{color:'cornflowerblue'}} className="fas fa-eye "/> {name && '\u00A0'} {name}
            </ButtonBasic>
        );
    }
}

ButtonView.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    domProps: PropTypes.object,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default ButtonView;