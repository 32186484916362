/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/16 3:23
 * @update 2019/01/16 3:23
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";

class ButtonSave extends Component {

    onClick() {
        if (this.props.onClick) this.props.onClick();
    }

    render() {
        const {name, className, disabled, id, hideText} = this.props;
        return (
            <ButtonBasic id={id} disabled={disabled} className={`${className ? className : ''} btn-crm mgr5`}
                         onClick={() => this.onClick()}>
                <i className="fas fa-spinner fa-spin text-muted"/> <span
                style={{visibility: hideText ? 'hidden' : 'visible'}}>{name && '\u00A0'} {name}</span>
            </ButtonBasic>
        );
    }
}

ButtonSave.propTypes = {
    disabled: PropTypes.bool,
    hideText: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,

};

export default ButtonSave;