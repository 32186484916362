import { DropDownBox } from 'devextreme-react';
import DataGrid, { FilterRow, Paging, Scrolling, Selection } from 'devextreme-react/data-grid';
import { RequiredRule, Validator } from 'devextreme-react/validator';
import _ from "lodash";
import PropTypes from 'prop-types';
import React from 'react';
import Config from "../../../../config/index";
import './combo.scss';


class ComboReportList extends React.Component {
    constructor(props) {
        super(props);
        this.combo = null;
        this.state = {
            clearComboData: false,
            gridBoxValue: "",
            dataSource: "",
            gridSelectedRowKeys: [],
        };
        this.tmpSelectedRowData = [];
        this.tmpSelectedRowKeys = [];
    }

    clearValueDropDown = () => {
        this.setState({
            gridBoxValue: "clear"
        })
    };

    syncDataGridSelection = (e, controlType) => {
        if (this.props.showClearButton && this.props.onActionWhenSelectChange)
            this.props.onActionWhenSelectChange(e.value);
        if (controlType === "CC") { // Multiple
            this.tmpSelectedRowKeys = this.tmpSelectedRowData = !_.isNull(e.value) ? e.value : [];
            this.setState({ clearComboData: true }) // Chỉ dùng muc đích render lại combo khi mà chúng ta clear data
        } else {
            if (!this.props.value && !!this.state.gridBoxValue !== !!e.value && this.state.gridBoxValue !== e.value) {
                // If set state here, syncDataGridSelection() will be re-do => it runs infinity
                this.setState({
                    gridBoxValue: e.value,
                    gridSelectedRowKeys: !e.value ? [] : [e.value]
                });
            }
        }
    }

    setSelectedRow = (e) => {
        const { valueExpr, displayExpr, onActionWhenSelectChange } = this.props;
        const currentSelectedRowKeys = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        if (currentDeselectedRowKeys.length > 0) {
            this.tmpSelectedRowData = this.tmpSelectedRowData.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e[valueExpr || displayExpr]) < 0;
            });
            this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
        } else if (currentSelectedRowKeys.length > 0) { //Multiple Select
            currentSelectedRowKeys.forEach((val) => {
                if (this.tmpSelectedRowData.indexOf(val) < 0) {
                    this.tmpSelectedRowKeys.push(val[valueExpr || displayExpr]);
                    this.tmpSelectedRowData.push(val);
                }
            });
        }
        const uniqueValue = [...new Set(e.selectedRowKeys)];
        if (onActionWhenSelectChange) onActionWhenSelectChange(uniqueValue)
    };

    dataGrid_onSelectionChanged = (e) => {
        const key = this.props.virtual ? e.selectedRowKeys : e.selectedRowsData;
        const newValue = key.length > 0 ? key[0] : null;
        if (newValue) {
            const value = newValue[this.props.valueExpr];
            if (this.props.onActionWhenSelectChange) this.props.onActionWhenSelectChange(newValue, e);
            //nếu onActionWhenSelect setstate thì sẽ render lại và props sẽ cập nhật giá trị và mới chạy tiếp if bên dưới
            if (!this.props.value && !this.props.length) {
                this.setState({
                    gridBoxValue: this.props.virtual ? key : value,
                    gridSelectedRowKeys: !value ? [] : key
                });
            } else {
                //Clear state khi props value  có giá trị
                const state = {};
                if (this.state.gridBoxValue) state.gridBoxValue = "";
                if (this.state.gridSelectedRowKeys) state.gridSelectedRowKeys = [];
                if (Object.keys(state).length > 0) this.setState(state);
            }
        }
        if (this.combo && this.combo.instance) this.combo.instance.close();
    };

    clear_Value = () => {
        if (this.combo && this.combo.instance)
            this.combo.instance.reset();
    }

    render() {
        const { controlType, value, dataSource, displayExpr, valueExpr, placeholder, reset, required, disabled, showClearButton, id, onOpened, width, styleCustom, className } = this.props;
        const { gridBoxValue, gridSelectedRowKeys } = this.state;
        const valueDropDown = controlType === "CC" ? value : (gridBoxValue === "clear" ? "" : (!gridBoxValue ? value : gridBoxValue));
        if (reset) {
            this.clear_Value();
        }
        return (
            <React.Fragment>
                <DropDownBox
                    id={id && id}
                    width={width}
                    ref={(ref) => this.combo = ref}
                    className={`input-devextreme noBoder  ${className}`}
                    value={_.isString(valueDropDown) ? valueDropDown : null} // Chỉ nhận string
                    disabled={disabled && disabled}
                    valueExpr={valueExpr}
                    deferRendering={false}
                    displayExpr={displayExpr}
                    placeholder={placeholder}
                    showClearButton={showClearButton}
                    dataSource={dataSource}
                    onValueChanged={(e) => this.syncDataGridSelection(e, controlType)}
                    contentRender={this.dataGridRender}
                    onOpened={(e) => {
                        if (_.isEmpty(this.tmpSelectedRowKeys) && !_.isEmpty(value) && !gridSelectedRowKeys.includes(value)) {
                            this.setState({ gridSelectedRowKeys: [value] }) //Chỉ set 1 lần duy nhất khi mở dropdown checked Keyvalue
                        }
                        onOpened && onOpened(e)
                    }}
                    style={styleCustom}
                >
                    {required &&
                        <Validator>
                            <RequiredRule message={Config.lang("Gia_tri_bat_buoc_nhap")} />
                        </Validator>
                    }
                </DropDownBox>
            </React.Fragment>
        );
    }

    setValueDropDown = (value) => {
        this.setState({
            gridBoxValue: value
        })
    };

    dataGridRender = () => {
        const { controlType, showHeader, children, virtual, dataSource, height, onContentReady, value, valueExpr, hideSearch, getDataGridRef, idDataGrid, wordWrapEnabled } = this.props;
        const { gridSelectedRowKeys } = this.state;
        const selectionMode = controlType === "CC" ? "multiple" : "single";
        const onSelectionChanged = controlType === "CC" ? this.setSelectedRow : this.dataGrid_onSelectionChanged;
        const selectKey = !_.isEmpty(value) && _.isEmpty(this.tmpSelectedRowKeys) ? gridSelectedRowKeys : this.tmpSelectedRowKeys;
        const selectedRowKeys = controlType === "CC" ? selectKey : (!gridSelectedRowKeys.length ? value ? [value] : [] : gridSelectedRowKeys);
        if (virtual) {
            return (
                <DataGrid
                    wordWrapEnabled={wordWrapEnabled ? wordWrapEnabled : false}
                    id={idDataGrid && idDataGrid}
                    ref={getDataGridRef}
                    columnResizingMode={"widget"}
                    elementAttr={{ class: `report-list-grid` }}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    width={'100%'}
                    height={height || 300}
                    showRowLines={true}
                    showBorders={true}
                    dataSource={dataSource}
                    hoverStateEnabled={true}
                    showColumnHeaders={showHeader}
                    selectedRowKeys={selectedRowKeys}
                    onSelectionChanged={onSelectionChanged}
                    remoteOperations={true}
                    onContentReady={(e) => {
                        onContentReady && onContentReady(e)
                    }}
                >
                    {children}
                    <Selection mode={selectionMode} allowSelectAll={false} showCheckBoxesMode={"always"} />
                    <Scrolling mode={"virtual"} preloadEnabled={true} />
                    <Paging pageSize={'50'} />
                    <FilterRow showOperationChooser={false} showAllText={false} visible={true} />
                </DataGrid>
            );
        }

        return (
            <DataGrid
                wordWrapEnabled={wordWrapEnabled ? wordWrapEnabled : false}
                id={idDataGrid && idDataGrid}
                ref={getDataGridRef}
                columnResizingMode={'widget'}
                elementAttr={{ class: `report-list-grid` }}
                allowColumnResizing={true}
                columnAutoWidth={true}
                width={'100%'}
                height={height || 300}
                showRowLines={true}
                showBorders={true}
                dataSource={dataSource}
                hoverStateEnabled={true}
                keyExpr={value ? valueExpr : ""}
                showColumnHeaders={showHeader}
                selectedRowKeys={selectedRowKeys}
                onSelectionChanged={onSelectionChanged}
                onContentReady={(e) => {
                    onContentReady && onContentReady(e)
                }}
            >
                {children}
                <Selection mode={selectionMode} allowSelectAll={false} showCheckBoxesMode={"always"} />
                <Scrolling mode={"infinite"} />
                <FilterRow showOperationChooser={false} showAllText={false} visible={!hideSearch} />
            </DataGrid>
        );
    }
}

ComboReportList.propTypes = {
    reset: PropTypes.bool, //true: clear value dropdown
    virtual: PropTypes.bool, //true: dropdown load more , false: dropdown load all
    disabled: PropTypes.bool,
    required: PropTypes.bool,  //true: input required
    showHeader: PropTypes.bool,
    showClearButton: PropTypes.bool,
    wordWrapEnabled: PropTypes.bool,
    id: PropTypes.string,
    valueExpr: PropTypes.string,
    idDataGrid: PropTypes.string,
    placeholder: PropTypes.string,
    controlType: PropTypes.string,
    displayExpr: PropTypes.string,
    onOpened: PropTypes.func,
    onContentReady: PropTypes.func,
    getDataGridRef: PropTypes.func,
    onActionWhenSelectChange: PropTypes.func,
    height: PropTypes.any,
    method: PropTypes.any,
    value: PropTypes.any,
    dataSource: PropTypes.any,
};

export default (ComboReportList);
