/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/16 13:49
 * @update 2019/01/16 13:49
 */

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";
import Config from "../../../config";

class Container extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLock: props.defaultValue ? props.defaultValue : false
        }
    }

    _onClick = () => {
        this.setState({
            isLock: !this.state.isLock
        }, () => {
            this.props.onValueChange(this.state.isLock)
        })
    };

    render() {
        return (
            <Ui isLock={this.state.isLock}
                disabled={this.props.disabled}
                onClick={this._onClick}
            />
        )
    }
}

const Ui = (props) => {
    const {name, className, disabled, id, isLock, onClick} = props;
    const customName = name ? name : isLock ? Config.lang('CRM_Dong1') : Config.lang('CRM_Mo');
    return (
        <ButtonBasic id={id}
                     disabled={disabled}
                     className={`${className ? className : ''} btn-crm`}
                     onClick={onClick}>
            <i className={`${isLock ? 'fas fa-lock' : 'fas fa-lock-open'} color-add`}/> {name && '\u00A0'} {customName}
        </ButtonBasic>
    );
};

Container.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.bool,
    onValueChange: PropTypes.func,
    id: PropTypes.string,

};

export default Container;