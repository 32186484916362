/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/16 13:23
 * @update 2021/01/26 02:12
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from "react-bootstrap";

class ButtonBasic extends Component {

    onClick = (e) => {
        const {onClick} = this.props;
        onClick && onClick(e)
    };

    render() {
        const {name, disabled, className, id, domProps, style} = this.props;
        const children = name ? name : this.props.children;

        return (
            <Button id={id}
                    {...domProps}
                    style={style}
                    disabled={disabled}
                    className={className}
                    type="button"
                    onClick={this.onClick}>
                {children}
            </Button>
        );
    }
}

ButtonBasic.propTypes = {
    name: PropTypes.string,
    style: PropTypes.any,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    domProps: PropTypes.object
};

export default ButtonBasic;
