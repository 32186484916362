/**
 * @copyright 2018 @ C.O.I
 * @author XuanVInh
 * @create 2017/06/30 15:34
 * @update 2017/06/30 15:34
 * @file src/components/common/course/course.js
 * Import động component
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reset: false // dành cho dropdown salesperson
        }
    }

    getComponent = () => {
        const {keyID} = this.props;
        switch (keyID) {
            case 'GroupSalesID':
                return require(`../dropdown/dropdown-sale-group`);
            case 'SalesPersonID':
            case 'CreatePersonID':
                return require(`../dropdown/template/dropdown-sale-person`);
            default:
                return null
        }
    };

    getParentParam = () => {
        const {parentID, parentValue} = this.props;
        let param = {};
        if (parentID === 'GroupSalesID') {
            param.groupSalesID = parentValue;    //hard code data trả ra là GroupSalesID component là groupSalesID
            param.reset = this.state.reset
        }
        return param;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {onChange, keyID, parentValue, disabled} = this.props;

        if (prevProps.parentValue !== parentValue && prevProps.parentValue && keyID === 'SalesPersonID' && prevProps.disabled === disabled) {
            this.setState({
                reset: true
            });
            const param = {
                id: keyID,
                value: '',
            };
            onChange && onChange(param)
        }
        if (prevProps.value && !this.props.value) {
            this.setState({
                reset: true
            });
            const param = {
                id: keyID,
                value: '',
            };
            onChange && onChange(param)
        }
        if (prevState.reset && this.state.reset) {
            this.setState({
                reset: false
            });
        }
    }


    _onChange = (e) => {
        if (e && this.state.reset) {
            this.setState(
                {
                    reset: false
                }
            )
        }
        const {onChange, keyID} = this.props;
        const valueF = keyID === 'SalesPersonID' ? e.SalesPersonID : e;
        const param = {
            id: keyID,
            value: valueF,
        };
        onChange && onChange(param)
    };

    render() {
        const {keyID, disabled, width, value} = this.props;

        return (<Ui
            parentData={this.getParentParam()}
            value={value}
            keyID={keyID}
            width={width}
            disabled={disabled}
            onChange={this._onChange}
            component={this.getComponent}/>)
    }
}

const Ui = (props) => {
    const Component = props.component() ? props.component().default : null;
    if (!Component) return null;
    const {value, onChange, disabled, parentData} = props;

    return (
        <React.Fragment>
            <Component value={value}
                       {...parentData}
                       width={'100%'}
                       disabled={disabled}
                       onChange={onChange}/>
        </React.Fragment>
    )
};


Container.propTypes = {
    parentID: PropTypes.string,
    parentValue: PropTypes.string,
    // ví dụ dropdown sales group  có props là groupSalesID={this.state.groupSalesID} groupSalesID là parentid và this.state.groupsalesID là  value
    keyID: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Container;