/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/15/2020
 * @Example
 */


import React                            from "react";
import {List, ListItemIcon, withStyles} from "@material-ui/core";
import propTypes                        from "prop-types";
import {compose}                        from "redux";
import {Image}                          from "react-bootstrap";
import Config                           from "../../../../config";
import {Popover}                        from "@material-ui/core"
import ListItem                         from "@material-ui/core/ListItem";
import {Link}                           from "react-router";
import CORE                             from "../index";

const styles = {
};
class LinkProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listProducts: [],
            anchorEl: null,
        };
        this.timer = null;
        this.isLoaded = false;
        this.host = CORE?.host || "";
    }

    componentWillUnmount () {
        if (this.timer) clearTimeout(this.timer);
    }

    onToggleMenu = (e) => {
        if (!e) return null;
        const {anchorEl} = this.state;
        this.setState({
            anchorEl: anchorEl ? null : e.target
        }, async () => {
            if (this.state.anchorEl && !this.isLoaded) {
                const listProducts = await CORE.getProducts();
                this.isLoaded = true;
                this.setState({listProducts: listProducts}, () => {
                    if (this.timer) clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        this.isLoaded = false;
                    }, 60 * 1000); //1p
                });
            }
        })
    };

    getLink = (item) => {
        if (!item) return "";
        const url = item.url && item.url.indexOf("/") === item.url.length - 1
            ? item.url.substring(0, item.url.length - 1)
            : item.url;
        const link = item.link ? item.link : url + "/auth?token=" + item.token;
        const paramLang = "lang=" + (Config.language || "84");
        return link.includes("?") ? `${link}&${paramLang}` : `${link}?${paramLang}`;
    };

    onItemLink = (item) => {
        if (!item) return false;
        if (item && item.link) {
            let link = this.getLink(item);
            link = link.indexOf("http") > -1 ? link : this.host + link;
            window.open(link);
        }
    };

    render() {
        const {style, className} = this.props;
        const {listProducts, anchorEl} = this.state;
        const _listProducts = listProducts?.filter(pro => pro.appID?.indexOf("W") === 0 && pro.appID !== Config.env?.productID) || [];

        return (
            <React.Fragment>
                <div className={className} style={style}>
                    <ListItem style={{height: "100%", padding: "10px 9px", textDecoration: 'none'}} dense button onClick={this.onToggleMenu}>
                        <Image src={require("./logo.svg")} width={20} height={"100%"}/>
                    </ListItem>
                    {/*Links to products*/}
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        PaperProps={{
                            style: {
                                padding: "10px 15px",
                                minWidth: 189
                            }
                        }}
                        onClose={this.onToggleMenu}
                        className="popup-choose-theme popup-tooltip"
                    >
                        {(_listProducts && _listProducts.length > 0) ? <List style={{minWidth: 192}}
                                                                           disablePadding
                                                                           className={'display_row align-center flex-wrap valign-middle'}>
                                {_listProducts && _listProducts.map((pro, i) => {
                                    if (pro.appID.indexOf("W") !== 0 || pro.appID === Config.env.productID) return null;
                                    const icon = pro.icon && pro.icon.indexOf("http") > -1 ? pro.icon : this.host + pro.icon;
                                    return (
                                        <ListItem button
                                                  key={i}
                                                  title={pro.appID}
                                                  aria-labelledby={pro.appID}
                                            // selected={theme && e.MainColor === theme.MainColor}
                                                  style={{width: 54, height: 54, padding: 2}}
                                                  className={''}
                                                  onClick={() => this.onItemLink(pro)}
                                                  value={pro}>
                                            <ListItemIcon style={{width: '100%', height: '100%',minWidth: 34}}>
                                                <Image src={icon} width={50} height={50}/>
                                            </ListItemIcon>
                                        </ListItem>
                                    );
                                })}
                            </List>
                            :
                            <div className={"display_col align-center valign-middle"}>
                                <label>{Config.lang("Chua_co_lien_ket_nao")}</label>
                                <Link to={"https://diginet.com.vn/san-pham"}
                                      target={"_blank"}
                                      onlyActiveOnIndex={false}
                                      style={{fontSize: 12}}>
                                    {Config.lang("Xem_them")}</Link>
                            </div>
                        }
                    </Popover>
                </div>
            </React.Fragment>
        );
    }
}

LinkProducts.propTypes = {
    className: propTypes.any,
    style: propTypes.any,
};
export default compose(withStyles(styles))(LinkProducts)
