/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/30 11:00
 * @update 2019/01/20 03:46
 * @example Form W17F1000
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {Col} from 'react-bootstrap'
import GridContainer from "../grid-container/grid-container";
import {Column, Editing, Lookup} from 'devextreme-react/data-grid';
import Config from '../../../config/index';

class FieldRequired extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
        };
    }

    editSelectionChanged(e) {
        e.component.columnOption("CodeType", "allowEditing", false);
        e.component.columnOption("FieldName", "allowEditing", false);
        e.component.columnOption("Description84", "allowEditing", false);
    }

    onChangeField(page){
        if(this.props.onChangeField){
            this.props.onChangeField(page);
        }
    }
    onChangePerField(per){
        if(this.props.onChangePerField){
            this.props.onChangePerField(per);
        }
    }

    updateField(e) {
        const m = e && e.key;
        if(this.props.updateField){
            this.props.updateField(m);
        }
    }

    setStateValue(newData,value,rowData) {
        newData.ValidMode = value;
        if(this.props.onCheckUpdateField){
            this.props.onCheckUpdateField();
        }
    }

    render() {
        const {data10,totalItems,itemPerField} = this.props;
        const states = [
            {
                ID: 'O',
                Name: Config.lang("CRM_Bat_buoc_nhap"),
            },
            {
                ID: 'W',
                Name: Config.lang("CRM_Canh_bao_nhap"),
            }

        ];
        return (
            <div>
                <Col xs={12} md={12} lg={12} sm={12}>
                    <GridContainer
                        dataSource={data10}
                        showColumnLines={true}
                        allowColumnReordering={true}
                        showBorders={false}
                        totalItems={totalItems}
                        itemPerPage={itemPerField}
                        onChangePage={(page) => {this.onChangeField(page) }}
                        onChangePerPage={(per) => {this.onChangePerField(per)}}
                        onEditingStart={(e) => this.editSelectionChanged(e)}
                        onRowUpdated={(e) => this.updateField(e)}
                    >
                        <Editing
                            mode={'cell'}
                            allowUpdating={true}
                        />

                        <Column dataField={'CodeType'} caption="Mã" visible={true} width={250} dataType={'string'}  allowEditing={false}
                                fixed={true}/>
                        <Column dataField={'FieldName'} caption={Config.lang("CRM_Truong_thiet_lap")} visible={true} width={300} dataType={'string'} allowEditing={false}/>
                        <Column dataField={'Description84'} caption={Config.lang("CRM_Ten_truong_thiet_lap")} visible={true} width={400} allowEditing={false}
                                dataType={'string'}/>
                        <Column
                            setCellValue={(newData, value, currentRowData)=>this.setStateValue(newData, value, currentRowData)}
                            dataField={'ValidMode'}
                            caption={Config.lang("CRM_Thiet_lap")}
                            width={300}>
                            <Lookup
                                dataSource={states} displayExpr={'Name'} valueExpr={'ID'}/>
                        </Column>

                    </GridContainer>
                </Col>
            </div>

        );
    }
}

FieldRequired.propTypes = {

    data10: PropTypes.array,

    updateField: PropTypes.func,
    itemSelectionChanged: PropTypes.func,
    totalItems: PropTypes.number,
    onChangeField: PropTypes.func,
    onChangePerField: PropTypes.func,
    itemPerField: PropTypes.number,
    onCheckUpdateField:PropTypes.func,
};


export default FieldRequired;