/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/03/06 12:39
 * @update 2019/03/06 12:39
 */

import React, {Component, useState} from 'react';
import PropTypes from 'prop-types';
import {Overlay, OverlayTrigger, Popover, Tooltip,} from 'react-bootstrap';
import ButtonBasic from "../button/button-basic";

class GridActionBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            placement: 'bottom',
            show: false,
        };
        this.hideHam = true; // ẩn popover btnhamburger
        this.init = false;
    }

    onView(classNameIs) {
        if (typeof classNameIs == 'undefined') {
            if (this.props.onDetailAction && this.props.isPer >= 1)
                this.props.onDetailAction();
        } else {
            if (this.props.onDetailAction && this.props.isPer >= 1 && classNameIs >= 4)
                this.props.onDetailAction();
        }
    }

    onEdit(classNameIs) {
        if (typeof classNameIs == 'undefined') {
            if (this.props.onEditAction && this.props.isPer >= 3)
                this.props.onEditAction();
        } else {
            if (this.props.onEditAction && this.props.isPer >= 3 && classNameIs >= 4)
                this.props.onEditAction();
        }
    }

    onDelete(classNameIs) {
        if (typeof classNameIs == 'undefined') {
            if (this.props.onDeleteAction && this.props.isPer >= 4)
                this.props.onDeleteAction();
        } else {
            if (this.props.onDeleteAction && this.props.isPer >= 4 && classNameIs >= 4)
                this.props.onDeleteAction();
        }
    }

    onHistory() {
        if (this.props.onHistoryAction && this.props.isPer >= 1 && this.state.classNameIs)
            this.props.onHistoryAction();
    }

    showMoreOptions(e) {
        let height = document.getElementsByClassName('paging-crm')[0];
        if (height) {
            height = height.getBoundingClientRect().top
        }
        let pos = e.target.getBoundingClientRect().top;
        if (height - pos < 180) {
            this.setState({
                placement: 'top'
            })
        } else {
            this.setState({
                placement: 'bottom'
            })
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.hidePop);
    }

    onEnteredHam = () => {
        document.body.addEventListener('click', this.hidePop);
        document.getElementById('hamburgerContent').addEventListener('click', () => {
            this.hideHam = false
        });
    };

    hidePop = () => {
        if (document.getElementsByClassName('popup-btn-close')[0]) {
            if (!this.hideHam) this.hideHam = true;
            return false;
        }
        if (this.hideHam) {
            this.setState({
                show: false
            })
        } else {
            this.hideHam = true
        }
    };

    toggleHam = () => {
        if (this.init) return;
        this.init = true;
        this.setState({
            show: !this.state.true
        })
    };

    onHideHam = () => {
        this.init = false;
    };

    onGetCloneProp = (e) => {
        const {component, type} = e;
        if (!component || !React.isValidElement(component) || type !== 'buttonExport') return null;
        return {
            onHidePop: () => {
                document.body.click();
                component.props.onHidePop && component.props.onHidePop();

            },
            onDownloadComplete: () => {
                document.body.click();
                component.props.onDownloadComplete && component.props.onDownloadComplete();
            },
            onShowPop: () => {
                document.getElementById('hamburgerContent').style.display = 'none';
                component.props.onShowPop && component.props.onShowPop();
            }
        }

    };

    render() {
        const {tooltipDetail, onDetailAction, tooltipEdit, onEditAction, tooltipDelete, onDeleteAction,
            onHistoryAction, tooltipHistory, tooltipDownload, onDownloadAction,
            isPer, className, classNameIs, btnHamburger} = this.props;
        const overlayDetail = <Tooltip id="ttDetail" className="tooltip">{tooltipDetail}</Tooltip>;
        const overlayEdit = <Tooltip id="ttEdit" className="tooltip">{tooltipEdit}</Tooltip>;
        const overlayDelete = <Tooltip id="ttDelete" className="tooltip">{tooltipDelete}</Tooltip>;
        const overlayHistory = <Tooltip id="ttDelete" className="tooltip">{tooltipHistory}</Tooltip>;
        const overlayDownload = <Tooltip id="ttDelete" className="tooltip">{tooltipDownload}</Tooltip>;

        return (
            <table style={{zIndex: 1000, width: '100%'}}>
                <tbody>
                <tr>
                    <td align={"center"} style={{border: 'none'}}>
                        {/* eslint-disable */}
                        {typeof onDetailAction === "function" &&
                        <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={overlayDetail}>
                            <a className="action-view-link" onClick={() => this.onView()}><i
                                className={`${isPer >= 1 ? '' : 'isDisabled'} ${className} action-button fas fa-eye text-orange`}/></a>
                        </OverlayTrigger>}

                        {typeof onEditAction === "function" &&
                        <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={overlayEdit}>
                            <a className="action-edit-link " onClick={() => this.onEdit(classNameIs)}><i
                                className={`${isPer >= 3 ? '' : 'isDisabled'} ${className} action-button fas fa-edit text-orange`}/></a>
                        </OverlayTrigger>}

                        {typeof onDeleteAction === "function" &&
                        <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={overlayDelete}>
                            <a className="action-del-link" onClick={() => this.onDelete(classNameIs)}><i
                                className={`${isPer >= 4 ? '' : 'isDisabled'} action-button fas fa-trash-alt text-red ${className}`}/></a>
                        </OverlayTrigger>}

                        {typeof onHistoryAction === "function" &&
                        <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={overlayHistory}>
                            <a className="action-his-link" onClick={() => this.onHistory()}><i
                                className={`${isPer >= 1 ? '' : 'isDisabled'} ${className} action-button fas fa-ellipsis-h text-black`}/></a>
                        </OverlayTrigger>}

                        {typeof onDownloadAction === "function" &&
                        <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={overlayDownload}>
                            <a className="action-del-link" onClick={() => onDownloadAction()}><i
                                className="action-button fas fa-download text-red"/></a>
                        </OverlayTrigger>}
                        {btnHamburger &&
                        <Hamburger dataSource={btnHamburger}
                                   show={this.state.show}
                                   placement={this.state.placement}
                                   onToggle={(e) => {
                                       this.toggleHam(e);
                                       this.showMoreOptions(e, classNameIs)
                                   }}
                                   onGetCloneProp={this.onGetCloneProp}
                                   onHide={this.onHideHam}
                                   onEntered={this.onEnteredHam}/>
                        }

                        {/* eslint-disable */}
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }
}

const Hamburger = React.memo((props) => {
    const [target, setTarget] = useState(null);
    const {dataSource, onEntered, onToggle, show, placement, onHide, onGetCloneProp} = props;
    const handleClick = event => {
        setTarget(event.target);
        onToggle && onToggle(event);
    };

    return (<span style={{cursor: 'pointer'}}>
            <i className="action-button fas fa-bars text-orange" onClick={handleClick}/>
            <Overlay
                rootClose
                onHide={onHide}
                target={target}
                onEntered={onEntered}
                show={show}
                placement={placement}>
                <Popover id="hamburgerContent">
                    {
                        dataSource && dataSource.map((value, index) => {
                            const cloneProp = onGetCloneProp && onGetCloneProp(value);
                            return (
                                <div className={'btnContainer'} style={{
                                    borderBottom: index % 2 && index !== dataSource.length - 1 && '1px dotted #c7d5e0',
                                    borderTop: index % 2 && '1px dotted #c7d5e0'
                                }} key={index}>
                                    {React.isValidElement(value.component) ? React.cloneElement(value.component, cloneProp) :
                                        <ButtonBasic onClick={value.onClick} disabled={value.disabled}
                                                     className={`${value.className && value.className} btn-crm`}>
                                            {value.icon &&
                                            <i className={value.icon && value.icon}> &nbsp; </i>}{value.name && value.name}
                                        </ButtonBasic>
                                    }
                                </div>)
                        })
                    }
                </Popover>
            </Overlay>
        </span>
    )
}, (prevProps, nextProps) => {
    /* Trả về true nếu nextProps bằng prevProps, ngược lại trả về false */
    return prevProps.show === nextProps.show;
});

GridActionBar.propTypes = {
    tooltipDetail: PropTypes.string,
    tooltipEdit: PropTypes.string,
    tooltipDelete: PropTypes.string,
    tooltipHistory: PropTypes.string,
    tooltipDownload: PropTypes.string,
    tooltipMore: PropTypes.string,
    isPer: PropTypes.number,
    classNameIs: PropTypes.number,
    className: PropTypes.string,

    onDetailAction: PropTypes.func,
    onEditAction: PropTypes.func,
    onDeleteAction: PropTypes.func,
    onHistoryAction: PropTypes.func,
    onDownloadAction: PropTypes.func,
    btnHamburger: PropTypes.array,
};

export default GridActionBar;
