import React, {memo} from 'react';
import {Col, ListGroup, Row, FormGroup} from "react-bootstrap";
import ButtonEdit from "../button/button-edit";
import ButtonDelete from "../button/button-delete";
import {TextBox, Validator} from 'devextreme-react';
import {
    RequiredRule,
} from 'devextreme-react/validator';
import Config from "../../../config";

export class Container extends React.Component {
    constructor(props) {
        super(props);
        this.timeout = null;
        this.prevScroll = 0;
    }

    refreshList = () => {
        let i = 0;
        let childHide = document.querySelector('#list-option-search').getElementsByClassName('list-group-item');

        for (i; i < childHide.length; i++) {
            if (childHide[i].classList.contains('nextItem'))
                if (childHide[i].getBoundingClientRect().top > 10) {
                    childHide[i].style.opacity = 1;
                    childHide[i].classList.remove('nextItem');
                }
        }
    };


    onFocusOut = (id) => {
        if (id !== 'new-list-group-item') {
            document.getElementById(id).style.boxShadow = '#959595 0px 2px 5px 0px';
            document.getElementById(id).style.borderBottom = '1px solid #d7c9c9';
        }
    };

    onInit = (e) => {
        setTimeout(function () {
            e.component.focus();
        }, 0);
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return JSON.stringify(nextProps) !== JSON.stringify(this.props);
    }

    onScroll = () => {
        const container = document.querySelector('#list-option-search');
        let child = document.querySelector('#list-option-search').getElementsByClassName('list-group-item');

        if (container.scrollTop > this.prevScroll) {
            let i = 0;
            let childLenght = child.length;
            for (i; i < childLenght; i++) {
                if (child[i].getBoundingClientRect().top < 10) {
                    if (child[i + 1].getAttribute('class') !== 'nextItem') {
                        child[i + 1].classList.add("nextItem");
                        child[i + 1].style.opacity = 0;
                    } else {
                    }
                }
            }
        } else if (container.scrollTop === this.prevScroll) {
        } else {
            let i = 0;
            let childHide = document.querySelector('#list-option-search').getElementsByClassName('list-group-item nextItem');
            for (i; i < childHide.length; i++) {
                if (child[i].getBoundingClientRect().bottom <= container.clientHeight && child[i].getBoundingClientRect().bottom > 0) {
                    if (childHide[i] && childHide[i].classList.contains('nextItem')) {
                        childHide[i].style.opacity = 1;
                        childHide[i].classList.remove('nextItem');
                        break;
                    }
                }
            }
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.refreshList();
            }, 1000)
        }
        this.prevScroll = container.scrollTop
    };

    render() {
        const {dataSource, onEdit, onSelect, onDelete, value, onGetRefTextBox, onAddListFilter} = this.props;

        return (
            <UI onEdit={onEdit}
                value={value}
                onGetRefTextBox={onGetRefTextBox}
                onSelect={onSelect}
                onInit={this.onInit}
                onDelete={onDelete}
                onAddListFilter={onAddListFilter}
                onFocusOut={this.onFocusOut}
                onFocusIn={this.onFocusIn}
                onScroll={this.onScroll}
                dataSource={dataSource}
            />
        )
    }

}


const UI = memo((props) => {
    //----------------------DECLARE----------------------
    const {
        onEdit, onFocusOut, onInit, onFocusIn, onScroll,
        onGetRefTextBox, onSelect, onDelete, value, onAddListFilter, dataSource
    } = props;

    const {filterID, filterName} = value;
    //----------------------ENDDECLARE----------------------

    const _onInit = (e) => {
        onInit && onInit(e);
    };

    const _onEdit = (e, evt) => {
        evt.stopPropagation();
        onEdit && onEdit(e);
    };

    const _onFocusOut = (id, el) => {
        onFocusOut && onFocusOut(id, el)
    };

    const _onFocusIn = (el) => {
        onFocusIn && onFocusIn(el);
    };

    const _onSelect = (e) => {
        onSelect && onSelect(e)
    };

    const _onDelete = (e, evt) => {
        evt.stopPropagation();
        onDelete && onDelete(e);
    };

    return (
        <React.Fragment>
            <FormGroup id={'list-filter-title'}>
                <Col lg={12}>
                    <div>
                        <div> {Config.lang('CRM_Danh_sach_bo_loc')}</div>
                    </div>
                </Col>
            </FormGroup>
            <Row>
                <Col lg={12}>
                    <ListGroup componentClass={"ul"}
                               id={'list-option-search'}
                               onScroll={onScroll}>
                        {/*<ListItem/>*/}
                        {
                            dataSource && dataSource.map((value, index) => {
                                let id = JSON.stringify(value) !== '{}' ? value.FilterID : 'new-list-group-item';
                                let isSelect = value.FilterID === filterID || JSON.stringify(value) === "{}" ? {
                                    boxShadow: 'rgba(53, 168, 236, 0.32) 0 0 4px 4px'
                                } : null;
                                return (
                                    <li className={"list-group-item"}
                                        onClick={() => _onSelect(value)}
                                        id={id}
                                        style={isSelect}
                                        key={`listGroup-${index}`}
                                    >
                                        <div style={{display: 'flex', alignItems: 'center', height: '50px'}}>
                                            {JSON.stringify(value) === "{}" ?
                                                <TextBox
                                                    onValueChanged={onAddListFilter}
                                                    onFocusIn={() => _onFocusIn(id)}
                                                    showClearButton={true}
                                                    value={filterName}
                                                    onInitialized={_onInit}
                                                    ref={onGetRefTextBox}
                                                    onFocusOut={(e) => _onFocusOut(id, e)}
                                                    className={'new-list-option'} width={'100%'}>
                                                    <Validator>
                                                        <RequiredRule
                                                            message={Config.lang("CRM_Ban_chua_nhap_gia_tri")}/>
                                                    </Validator>
                                                </TextBox> :
                                                <React.Fragment>
                                                    <div>{value.FilterName}</div>
                                                    {
                                                        <div style={{marginLeft: 'auto'}}>
                                                            <ButtonEdit className={'mgr5'}
                                                                        disabled={value.CreateUserID !== Config.profile.UserID}
                                                                        onClick={(el) => _onEdit(value, el)}/>
                                                            <ButtonDelete onClick={(el) => _onDelete(value, el)}
                                                                          disabled={value.CreateUserID !== Config.profile.UserID}
                                                            />
                                                        </div>}


                                                </React.Fragment>
                                            }
                                        </div>
                                    </li>
                                )

                            })
                        }
                    </ListGroup>
                </Col>
            </Row>
        </React.Fragment>
    )
});

export default Container
