/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/common/print/print.js
 */

import React from 'react';
import {Col, FormGroup,} from 'react-bootstrap';
import PopupView from "../../popup/popup-view";
import DropDownReportTemplate from "../../common/dropdown/template/dropdown-report-template";
import Config from "../../../config";
import PropTypes from 'prop-types';
import {TextBox} from "devextreme-react";
import Button from "../../common/button/button";


class Print extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reportID: '',
            reportName: '',
            url: '',
            report: null
        };
    }

    onHide() {
        const {onHide} = this.props;
        if (onHide) {
            onHide(this.isSaved && this.state);
        }
    }

    printReport(mode) {
        const param = [...this.props.param];
        const {report, url, reportID, reportName, reportTypeID} = this.state;
        param.push({type: "Varchar", id: "ReportID", value: reportID});
        const options = {
            reportURL: url,
            reportID: reportID,
            reportName: reportName,
            reportTypeID: reportTypeID,
            isPDF: mode === 1 ? 1 : 0,
            exportType: mode === 1 ? "pdf" : (report?.FileExt ? report?.FileExt.replace('.', '') : ""),
            strSQL: report?.StrSQL ? report?.StrSQL : "",
            fileExt: report.FileExt ? report.FileExt.replace(".", "") : ""
        };
        if (this.props.onExportExcel)
            this.props.onExportExcel(param, options);
    }

    onChangeReportTemplate(data) {
        this.setState({
            reportID: data.ReportID,
            reportName: data.Title,
            url: data.URL,
            report: data
        });
    }

    render() {
        const {reportTypeID, hideBtnExcel, hideBtnPdf, btnLoading} = this.props;
        const {report} = this.state;
        const disabled = !!Object.keys(btnLoading).find(l => btnLoading[l]);
        return (
            <PopupView
                title={Config.lang("CRM_Chon_mau_bao_cao")}
                show={true}
                animation={true}
                onHide={() => this.onHide()}>

                <div className="page-container" style={{marginRight: '0px!Important', marginLeft: '0px!Important'}}>
                    <FormGroup className={'mgt5'}>
                        <Col sm={12}>
                            <label className={'control-label'}>{Config.lang("CRM_Mau_bao_cao")}<span
                                style={{color: 'red'}}><b>*</b></span></label>
                        </Col>
                        <Col sm={4}>
                            <DropDownReportTemplate
                                required={true}
                                modePopUp={true}
                                reportTypeID={reportTypeID}
                                displayExpr={"ReportID"}
                                placeholder={""}
                                onChange={(data) => this.onChangeReportTemplate(data)}>
                            </DropDownReportTemplate>
                        </Col>
                        <Col sm={8}>
                            <TextBox name={"ReportName"}
                                     value={this.state.reportName}
                                     disabled={true}
                                     className="input-devextreme"
                            >
                            </TextBox>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={12} sm={12} xs={12} lg={12}>
                            {(report?.ReportType === "Word" || !report) &&
                            <Button isLoading={btnLoading?.word || false}
                                    onClick={() => {
                                        if (!btnLoading?.word) this.printReport(2)
                                    }}
                                    type={"word"}
                                    disabled={disabled || !report}
                                    className={`${!disabled ? '' : 'isDisabled'} mgr5 pull-right`}
                                    name={Config.lang("CRM_Xuat_word")}
                            />}
                            {((hideBtnExcel && report?.ReportType === "Excel") || !report) &&
                            <Button isLoading={btnLoading?.excel || false}
                                    onClick={() => {
                                        if (!btnLoading?.excel) this.printReport(0)
                                    }}
                                    type={"excel"}
                                    disabled={disabled || !report}
                                    className={`${!disabled ? '' : 'isDisabled'} mgr5 pull-right`}
                                    name={Config.lang("CRM_Xuat_Excel")}
                            />}
                            {((hideBtnPdf && report?.IsConvertPDF === 1) || !report) &&
                            <Button className={`${!disabled ? '' : 'isDisabled'} mgr5 pull-right`}
                                    onClick={() => {
                                        if (!btnLoading?.pdf) this.printReport(1)
                                    }}
                                    type={"pdf"}
                                    disabled={disabled || !report}
                                    isLoading={btnLoading?.pdf || false}
                                    name={Config.lang("CRM_Xuat_PDF")}
                            />}
                        </Col>
                    </FormGroup>
                </div>
            </PopupView>
        )
    }
}

Print.propTypes = {
    reportTypeID: PropTypes.string,
    param: PropTypes.array,
    hideBtnExcel: PropTypes.bool,
    hideBtnPdf: PropTypes.bool,
    isLoading: PropTypes.bool,
    isDisabledEXCEL: PropTypes.bool,
    isDisabledPDF: PropTypes.bool,
    onExportExcel: PropTypes.func
};
export default (Print);
