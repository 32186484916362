/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/07/15 3:23
 * @update 2019/07/15 3:23
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";

class ButtonExcel extends Component {

    onClick() {
        if (this.props.onClick) this.props.onClick();
    }

    render() {
        const {name, className, id, disabled} = this.props;
        return (
            <ButtonBasic id={id} className={`${className ? className : ''} btn-crm`}
                         disabled={disabled}
                         onClick={() => this.onClick()}>
                <div className={'display_row align-center justify-middle'}>
                    {this.props.isLoading ? <i className="fas fa-circle-notch fa-spin text-cornflowerblue"/> :
                        <i className="fas fa-file-pdf text-danger"/>}&nbsp;{name && ` ${name}`}
                </div>
            </ButtonBasic>
        );
    }
}

ButtonExcel.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,

};

export default ButtonExcel;