/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/11 16:37
 * @update 2019/01/11 16:37
 * @file src/components/dashboard/dashboard.js
 */

import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Loading} from "../common/loading/loading";
import Config from '../../config/index';
import {Button, Col, FormGroup, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';

import * as w18F1020Actions from "../../actions/w18F1020-actions";
import * as w18F1014Actions from "../../actions/w18F1014-actions";
import * as userActions from "../../actions/user-actions";
import ActionTab from "../W18/W18F1014";
import ItemTemplate from "../dashboard/ItemTemplate";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import DropDownSaleGroup from "../common/dropdown/dropdown-sale-group";
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import DropDownTaskType from "../common/dropdown/template/dropdown-task-type";
import ScheduleTemplate from "./scheduleTemplate";
import EventTracking from "../common/tracking/event";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import styled from "styled-components";

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

import moment from "moment";
import 'moment/locale/vi'
import {DateBox} from "devextreme-react";
import Combo from "../common/dropdown/combo";
import {Column} from 'devextreme-react/data-grid';
import {browserHistory} from "react-router";

moment.locale('vi');

const cssForm = {margin: '15px 10px 10px 10px', height: 'calc(100vh - 90px)'};

const TaskContainer = styled.div`
            display: flex;
            border-radius: 5px;
            margin-bottom: 5px;
            background-color:white;
            padding: 3px 5px;
            cursor:all-scroll !important;
            background-color: ${props => (props.isDragging ? 'rgba(211,211,211,0.4)' : 'white')}
            &:hover {
                background-color: rgba(211,211,211,0.4);
              }
        `;

class DashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchMode: 'contains',
            defaultVisible: false,
            showDetail: false,
            loading: true,
            typeTimeID: '',
            taskTypeID: '',
            groupSalesID: '',
            salesPersonID: '',
            userID: '',
            resetComboPS: false,
            resetComboGP: false,
            toggleTab: 0,
            dataList: null,
            dataDetail: {
                rowData: '',
                mode: ''
            },
            dataYear: null,
            loadGridW18F1020: [],
            loadSchedule: [],
            displayFilter: false,
            dateFrom: null,
            dateTo: null,
            searchtxt: '',
            iPermission: 0
        };
        this.companyID = '';
        this.timer = null;
        this.timerFocus = null;
        this.timerSearch = null;
        this.language = '';
        this.firstTime=true;
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
        if (this.timerFocus) clearTimeout(this.timerFocus);
        if (this.timerSearch) clearTimeout(this.timerSearch);
        // document.removeEventListener(this.toggleDisplayFilter);
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        const {customerNew, TaskID, mode} = this.getInfo();
        if(customerNew){
            const data = {
                rowData: {
                    CompanyName: customerNew ? customerNew.CompanyNameU : '',
                    CompanyID: customerNew ? customerNew.CompanyID : '',
                },
                mode: mode
            };
            if(mode === 'add') this.onAddNew(data);
            else if(mode === 'edit') this.loadDetail(TaskID);
        }
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1010", (iPer) => {
            this.setState({iPermission: iPer});
        });
    };

    onLoad(value) {
        this.setState({
                typeTimeID: value.ID,
                dateFrom: value.DateFrom,
                dateTo: value.DateTo
            },
            () => this.loadGrid()
        );
    }

    loadData = (cb) => {
        const {customerNew, mode} = this.getInfo();
        if (this.state.showDetail && this.state.showDetail === true) {
            let data = {rowData: [], mode: 'add'};
            if(customerNew && mode === 'add'){
                data = {
                    rowData: {
                        CompanyName: customerNew ? customerNew.CompanyNameU : '',
                        CompanyID: customerNew ? customerNew.CompanyID : '',
                    },
                    mode: 'add'
                };

                let stateTemp = this.props.location.state;
                if(stateTemp){
                    stateTemp.customerNew = null;
                    stateTemp.mode = null;
                }
                browserHistory.push({
                    // pathname: Config.env.url + '/dashboard',
                    pathname: Config.getRootPath() + 'dashboard',
                    state: stateTemp ? stateTemp : {}
                })
            }
            cb && cb(data);
            return;
        }
        const sLanguage = Config.language || '84';
        const param = {
            "sLanguage": sLanguage,
            "taskID": this.state.showDetail,
        };
        this.props.w18F1020Actions.loadDetailW18F1020(param, (error, data) => {
            if (error) {
                this.setState({showDetail: false});
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                cb && cb(null);
                return false;
            }
            if (data && data.length > 0) {
                const rowData = data[0];
                if(customerNew && mode === 'edit' && customerNew.CompanyNameU && customerNew.CompanyID){
                    rowData.CompanyName = customerNew.CompanyNameU;
                    rowData.CompanyID = customerNew.CompanyID;
                }
                this.setState({
                    dataDetail: {
                        rowData: rowData,
                        mode: 'edit'
                    }
                });
                let stateT = this.props.location.state;
                if(stateT){
                    stateT.customerNew =null;
                    stateT.mode = null;
                }
                browserHistory.push({
                    pathname: Config.getRootPath() + 'dashboard',
                    state:stateT?stateT:null
                });
                cb && cb({rowData: rowData, mode: 'edit'});
            }

        });

    };

    loadDetail = (taskID) => {
        // console.log("=============loadDetail=============",taskID);
        this.setState({showDetail: taskID},()=>{
            this.firstTime = false;
        });

    };

    onAddNew = () => {
        this.setState({
            dataDetail: {rowData: [],mode: 'add'},
            showDetail: true
        });
    };

    getInfo = () => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('TaskID')) {
            return {TaskID: url.get('TaskID'),customerNew: url.get('customerNew'),mode: url.get('mode')};
        } else if (location && location.state) {
            return {
                TaskID: location.state.TaskID,
                customerNew: location.state.customerNew,
                mode: location.state.mode,
            }
        } else {
            return {};
        }

    };

    loadGrid = () => {
        const sLanguage = Config.language || '84';

        this.setState({loading: true, dataList: [], displayFilter: 0});

        if (this.timer) clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.setState({loading: false});
        }, 30000);

        let param = {
            "sLanguage": sLanguage,
            "groupSalesID": this.state.groupSalesID,
            "salesPersonID": this.state.salesPersonID,
            "taskTypeID": this.state.taskTypeID,
        };
        param.StrSearch = this.state.searchtxt;
        if (this.state.dateFrom) {
            param.DateFrom = moment(this.state.dateFrom).format('YYYY-MM-DD');
        }
        if (this.state.dateTo) {
            param.DateTo = moment(this.state.dateTo).format('YYYY-MM-DD');
        }
        this.props.w18F1020Actions.loadGridW18F1020(param, (error, data) => {
            // console.log('======loadGridW18F1020=======', data);
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({loading: false, dataList: []});
                return false;
            } else if (data) {
                this.setState({
                    loading: false,
                    dataList: data,
                    dataYear: (this.state.toggleTab === 2 && data) ? data : [],
                    loadGridW18F1020: (this.state.toggleTab !== 2 && data) ? data : []
                });
                const {TaskID} = this.getInfo();
                if(TaskID && this.firstTime){
                    this.loadDetail(TaskID);
                }
            }
        });

    };

    loadMyWork() {
        this.setState({
            userID: '',
            salesPersonID: '',
            groupSalesID: '',
            resetComboPS: true,
            resetComboGP: true
        }, () => this.loadGrid());
    }

    onChangeComboGS(data) {
        if (data) {
            this.setState({
                userID: '',
                groupSalesID: data,
                salesPersonID: '',
                resetComboPS: true,
                resetComboGP: false
            }, () => {
                this.onChangeComboSP();
            })
        } else {
            this.setState({
                userID: '',
                groupSalesID: '',
                salesPersonID: '',
                resetComboPS: true,
                resetComboGP: false
            }, () => {
                this.onChangeComboSP();
            });
        }
    }

    onChangeComboTT(data) {
        if (data) {
            this.setState({
                dateFrom: data.DateFrom,
                dateTo: data.DateTo,
                typeTimeID: data.ID
            }, () => {
                if (this.isFirst) return;
                if (this.state.salesPersonID !== '' || this.state.userID === Config.profile.UserID || this.state.userID === '') {
                    this.isFirst = true;
                    this.loadGrid();
                }
            });
        } else {
            this.setState({
                typeTimeID: ''
            }, () => {
                if (this.isFirst) return;
                if (this.state.salesPersonID !== '' || this.state.userID === Config.profile.UserID || this.state.userID === '') {
                    this.isFirst = true;
                    this.loadGrid();
                }
            });
        }

    }

    onChangeComboTaskType(data) {
        if (data) {
            this.setState({
                    taskTypeID: data.ID
                },
                // () => this.loadGrid()
            );
        } else {
            this.setState({
                    taskTypeID: ''
                },
                // () => this.loadGrid()
            );
        }
    }

    onChangeComboSP(data) {
        if (data) {
            this.setState({
                    userID: data.UserID,
                    salesPersonID: data.SalesPersonID,
                    resetComboPS: false,
                },
                // () => this.loadGrid()
            );
        } else {
            this.setState({
                    userID: '',
                    salesPersonID: '',
                    resetComboPS: false,
                },
                // () => this.loadGrid()
            );
        }
    }

    closeActionPopup() {
        // this.loadGrid();
        this.setState({
            dataDetail: {
                rowData: '',
                mode: '',
            },
            showDetail: false
        });
    }

    loadSchedule = (cb) => {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const params = {
            "UserID": uName,
            "Language": uLanguage,
            "GroupSalesID": this.state.groupSalesID,
            "SalesPersonID": this.state.salesPersonID,
            "Type": 'LIST',
            "TaskType": this.state.taskTypeID,
        };
        if (this.state.dateFrom) {
            params.DateFrom = moment(this.state.dateFrom).format('YYYY-MM-DD');
        }
        if (this.state.dateTo) {
            params.DateTo = moment(this.state.dateTo).format('YYYY-MM-DD');
        }
        this.props.w18F1020Actions.SCHEDULE(params, (error, data) => {
            if (error) {
                let message = error.message || "Lỗi chưa xác định";
                Config.popup.show('INFO', message);
                return false;
            } else if (data) {
                this.setState({
                    loadSchedule: data
                })
            }
            cb && cb(error, data);

        })
    };

    loadGridShowDetail = () => {
        if (this.state.toggleTab === 0 || this.state.toggleTab === 2) {
            this.loadGrid();
        } else if (this.state.toggleTab === 1) {
            this.loadSchedule();
        }
    };

    editTask = (params, cb) => {
        this.props.w18F1014Actions.editTask(params, (error) => {
            cb && cb(error);
        });
    };

    toggleDisplayFilter = (number, e) => {
        this.setState({
            displayFilter: !this.state.displayFilter
        })
    };

    onChangeSearchInput = (e) => {
        this.setState({
            searchtxt: e.target.value
        });
        if (this.state.displayFilter) return;
        if (this.timerSearch) clearTimeout(this.timerSearch);
        this.timerSearch = setTimeout(() => {
            this.loadGrid();
        }, 1000)

    };

    onClearSearch = () => {
        this.setState({
            searchtxt: ''
        }, () => {
            this.loadGrid();
        })
    };

    onFocusxBlur = (isBlur, e) => {
        if (isBlur) {
            document.getElementById('container-button-option').style.borderTop = '1px solid #cccccc';
            document.getElementById('container-button-option').style.borderRight = '1px solid #cccccc';
            document.getElementById('container-button-option').style.borderBottom = '1px solid #cccccc';
            return
        }
        document.getElementById('container-button-option').style.borderTop = '1px solid #66afe9';
        document.getElementById('container-button-option').style.borderRight = '1px solid #66afe9';
        document.getElementById('container-button-option').style.borderBottom = '1px solid #66afe9';

    };

    render() {
        const {TaskID} = this.getInfo();
        const {dataDetail, dataList, dataYear, loadSchedule, iPermission} = this.state;
        let dataTemp = {
            tasks: {},
            columns: {
                'column-1': {
                    id: 'column-1',
                    title: Config.lang("CRM_Tre_han"),
                    taskIds: [],
                    backgroundColor: '#DA695B',
                    code: 'CL1',
                },
                'column-2': {
                    id: 'column-2',
                    title: Config.lang("CRM_Chua_thuc_hien"),
                    taskIds: [],
                    backgroundColor: '#437FB3',
                    code: 'CL2',
                },
                'column-3': {
                    id: 'column-3',
                    title: Config.lang("CRM_Dang_thuc_hien"),
                    taskIds: [],
                    backgroundColor: '#55AA68',
                    code: 'CL3',
                },
                'column-4': {
                    id: 'column-4',
                    title: Config.lang("CRM_Da_hoan_thanh"),
                    taskIds: [],
                    backgroundColor: '#FFC107',
                    code: 'CL4',
                }
            },
            columnOrder: ['column-1', 'column-2', 'column-3', 'column-4']
        };

        if (dataList) {
            dataList.forEach((key, idx) => {
                if (!dataTemp.tasks[key.TaskID]) {
                    dataTemp.tasks[key.TaskID] = key;
                    if (key.IsLate === 1) {
                        dataTemp.columns['column-1'].taskIds.push(key.TaskID);
                    } else {
                        if (key.TaskStatus === 'NEW') {
                            dataTemp.columns['column-2'].taskIds.push(key.TaskID);
                        } else if (key.TaskStatus === 'PROCESS') {
                            dataTemp.columns['column-3'].taskIds.push(key.TaskID);
                        } else {
                            dataTemp.columns['column-4'].taskIds.push(key.TaskID);
                        }
                    }
                }
            })
        }
        return (
            <div id={'dashboard'} className={'list-container'} style={cssForm}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"Dashboard"} label={Config.profile.UserID}/>}

                <FormGroup className='fixed-height'>
                    <div className={"display_row list_padding group-filter"}>
                        <div className={"display_row"} style={{pointerEvents: 'auto'}}>
                            <div style={{
                                height: 36, width: 250,
                                borderRadius: 4,
                                // border: '1px solid #ddd',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                // padding: 10,
                            }}>
                                {
                                    // this.state.displayFilter &&
                                    // <div style={{cursor: 'pointer'}} onClick={() => {
                                    //     this.toggleDisplayFilter(1);
                                    // }}>{Config.lang('CRM_Noi_dung_tim_kiem')}
                                    //     <i className={'fa fa-angle-down'}/>
                                    // </div>
                                }

                                {
                                    <React.Fragment>
                                        <input
                                            onFocus={this.onFocusxBlur.bind(this, false)}
                                            onBlur={this.onFocusxBlur.bind(this, true)}
                                            style={{
                                                width: this.state.searchtxt.length > 0 ? '78%' : '85%',
                                                zIndex: 3,
                                            }}
                                            id={'txtSearchInput'}
                                            value={this.state.searchtxt}
                                            onChange={this.onChangeSearchInput}
                                            placeholder={Config.lang('CRM_Noi_dung_tim_kiem')}
                                            className={'form-control'}/>
                                        <div id={'container-button-option'}
                                             style={{width: this.state.searchtxt.length > 0 ? '22%' : '15%',}}>
                                            {this.state.searchtxt.length > 0 &&
                                            <span className={'dx-icon dx-icon-clear dx-customCRM-icon-clear'}
                                                  onClick={this.onClearSearch}/>}
                                            <i className={'fa fa-angle-down'} onClick={this.toggleDisplayFilter}/>
                                        </div>
                                    </React.Fragment>
                                }

                                {!!this.state.displayFilter && <div style={{
                                    position: 'fixed',
                                    width: '100%',
                                    height: '100%',
                                    top: 0,
                                    left: 0,
                                    zIndex: 2
                                }} onClick={this.toggleDisplayFilter}/>}
                                <ul className={'dropdown-filter'}
                                    style={{height: this.state.displayFilter ? 'auto' : 0}}>
                                    <div className="dropdown-change">
                                        <div className={'mgb15'}>
                                            <DropDownSaleGroup showClearButton={true}
                                                               placeholder={Config.lang("CRM_Nhom_kinh_doanh")}
                                                               value={""}
                                                               onChange={(e) => {
                                                                   this.onChangeComboGS(e)
                                                               }}
                                            />
                                        </div>

                                        <div className={'mgb15'}>
                                            <DropDownSalePerson showClearButton={true}
                                                                groupSalesID={this.state.groupSalesID}
                                                                placeholder={Config.lang("CRM_Nhan_vien_kinh_doanh")}
                                                                value={""}
                                                                reset={this.state.resetComboPS} onChange={(e) => {
                                                this.onChangeComboSP(e)
                                            }}/>
                                        </div>

                                        <div className={'mgb10'}>
                                            <DropDownTaskType
                                                placeholder={Config.lang("CRM_Loai_hoat_dong")}
                                                itemType={"MT"}
                                                showClearButton={true}
                                                onChange={(data) => this.onChangeComboTaskType(data)}
                                            />
                                        </div>

                                        <div className={'mgb10 valign-top group-dropdown'}>
                                            <DropDownTypeTime
                                                listType={this.state.toggleTab !== 2 ? 'W17F0000_TypeTime' : 'W17F0000_TypeTime_Month'}
                                                placeholder={""}
                                                disabled={this.state.toggleTab === 2}
                                                onChange={(data) => this.onChangeComboTT(data)}
                                                onLoad={(value) => this.onLoad(value)}
                                            />
                                        </div>

                                        <div className={'mgb10 valign-top group-dropdown'}>
                                            <DateBox
                                                width={"100%"}
                                                dateSerializationFormat={'yyyy-MM-ddTHH:mm:ss'}
                                                placeholder={Config.lang("CRM_Ngay_tu")}
                                                disabled={this.state.toggleTab === 2}
                                                ref={(ref) => this.fromDate = ref}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                type={'date'}
                                                max={this.state.dateTo}
                                                value={this.state.dateFrom}
                                                pickerType={"calendar"}
                                                // showAnalogClock={false}
                                                onValueChanged={(vl) => {
                                                    this.setState({
                                                        dateFrom: vl.value
                                                    })
                                                }}
                                                displayFormat={'dd/MM/y'}
                                            />
                                        </div>

                                        <div className={'mgb10 valign-top group-dropdown'}>
                                            <DateBox
                                                width={"100%"}
                                                dateSerializationFormat={'yyyy-MM-ddTHH:mm:ss'}
                                                placeholder={Config.lang("CRM_Ngay_den")}
                                                disabled={this.state.toggleTab === 2}
                                                ref={(ref) => this.fromDate = ref}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                type={'date'}
                                                min={this.state.dateFrom}
                                                value={this.state.dateTo}
                                                pickerType={"calendar"}
                                                // showAnalogClock={false}
                                                onValueChanged={(vl) => {
                                                    this.setState({
                                                        dateTo: vl.value
                                                    })
                                                }}
                                                displayFormat={'dd/MM/y'}
                                            />
                                        </div>

                                        <Button onClick={this.loadGrid} style={{float: 'right'}}>
                                            {Config.lang('CRM_Tim_kiem')}
                                        </Button>
                                    </div>
                                </ul>
                            </div>
                            <Button onClick={() => this.onAddNew()} className={'mgl10 mgb15'}>
                                <i className="fas fa-plus color-add"/>
                            </Button>
                        </div>

                        <Col className={'mgb15 display_row align-right valign-bottom'}>
                            <div id={'dashboardButton'} className='display_row wrap align-center'>
                                <Combo
                                    showHeader={false}
                                    dataSource={[
                                        {
                                            id: 0,
                                            name: Config.lang("CRM_Trang_thai")
                                        },
                                        {
                                            id: 1,
                                            name: Config.lang("CRM_Danh_sach")
                                        },
                                        {
                                            id: 2,
                                            name: Config.lang("CRM_Thang")
                                        },
                                    ]}
                                    id={'cbbFlagView'}
                                    placeholder={''}
                                    value={0}
                                    hideSearch={true}
                                    valueExpr="id"
                                    displayExpr="name"
                                    onActionWhenSelectChange={(e) => {
                                        this.setState({toggleTab: e.id}, () => {
                                            if (e.id !== 1) this.loadGrid();
                                        })
                                    }}
                                >
                                    <Column dataField={'id'} caption="" width={'150px'} visible={false}/>
                                    <Column dataField={'name'} caption="" width={'100%'} dataType={'string'}/>
                                </Combo>
                                <OverlayTrigger placement="left" overlay={<Tooltip
                                    id="tooltip-disabled">{Config.lang("CRM_Hoat_dong_cua_toi")}</Tooltip>}>
                                    <i className="fas fa-user-circle user-contain mgl10" onClick={() => {
                                        this.loadMyWork()
                                    }}/>
                                </OverlayTrigger>
                            </div>
                        </Col>
                    </div>

                    {this.state.toggleTab === 0 &&
                    <Row className={"list_padding group-list-fixed wrap"}>
                        {dataList && <Drag editTask={this.editTask} data={dataTemp} loadDetail={this.loadDetail}/>}
                    </Row>
                    }

                    {this.state.toggleTab === 1 &&
                    <ScheduleTemplate loadSchedule={this.loadSchedule}
                                      dataGrid={loadSchedule}
                                      loadDetail={(taskID) => this.loadDetail(taskID)}
                                      data={{
                                          salesPersonID: this.state.salesPersonID,
                                          typeTimeID: this.state.typeTimeID,
                                          groupSalesID: this.state.groupSalesID,
                                          taskTypeID: this.state.taskTypeID
                                      }}
                    />
                    }
                    {this.state.toggleTab === 2 &&
                    <MonthView data={dataYear} loadDetail={(taskID) => this.loadDetail(taskID)}/>}

                </FormGroup>

                {this.state.showDetail && <ActionTab loadData={this.loadData}
                                                     data={dataDetail}
                                                     iPermission={iPermission}
                                                     formID={'dashboard'}
                                                     state={this.getInfo()}
                                                     onHide={() => this.closeActionPopup()}
                                                     showComment={this.firstTime && TaskID}
                                                     loadGrid={this.loadGridShowDetail}/>}

                {this.state.loading && <Loading/>}
            </div>
        )
    }
}

class Drag extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.props.data;

    };

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)) {
            this.setState(nextProps.data);
        }
    }

    onDragEnd = (result) => {
        //To do reorder our column

        const stateOld = {...this.state};
        if (this.props.updateTask) this.props.updateTask();
        const {tasks, columns} = this.state;
        const {destination, source, draggableId} = result;
        if (!destination) return;

        const colOld = source.droppableId;
        const colNew = destination.droppableId;
        const dataTask = tasks[columns[source.droppableId].taskIds[source.index]];

        let params = dataTask;
        if (colOld === 'column-2' && colNew === 'column-3') {
            params.TaskStatus = "PROCESS";
        } else if ((colOld === 'column-2' || colOld === 'column-3') && colNew === 'column-4') {
            params.FinishDate = moment().format('YYYY-MM-DD HH:mm:ss.SSS');
            params.TaskStatus = "COMPLETE";
        } else if ((colOld === 'column-3' || colOld === 'column-4') && colNew === 'column-2') {
            params.FinishDate = null;
            params.TaskStatus = "NEW";
        } else if (colOld === 'column-4' && colNew === 'column-3') {
            params.FinishDate = null;
            params.TaskStatus = "PROCESS";
        }

        if (!destination || (destination && destination.droppableId === source.droppableId && destination.index === source.index)) {
            return; //khong doi vi tri
        }

        let start = columns[source.droppableId];
        let finish = columns[destination.droppableId];

        if (start === finish) { // di chuyển trong cột

            let newTaskIds = Array.from(start.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                taskIds: newTaskIds
            };

            const newState = {
                ...this.state,
                columns: {
                    ...columns,
                    [start.id]: newColumn
                }
            };

            this.setState(newState);

            return;
        }

        //di chuyển qua cột khác

        const startTaskIds = Array.from(start.taskIds);
        startTaskIds.splice(source.index, 1);
        const newStart = {
            ...start,
            taskIds: startTaskIds
        };

        const finishTaskIds = Array.from(finish.taskIds);
        finishTaskIds.splice(destination.index, 0, draggableId);

        const newFinish = {
            ...finish,
            taskIds: finishTaskIds
        };

        const newState = {
            ...this.state,
            columns: {
                ...columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish
            }
        };

        this.setState(newState);


        if (colOld !== colNew) {
            if (this.props.editTask) {
                this.props.editTask(params, (error) => {
                    if (error) {
                        let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        this.setState(stateOld);
                        return false;
                    }
                });
            }
        }
    };

    onSave = () => {
        const {tasks, columns, columnOrder} = this.state;
        let dataNew = [];
        columnOrder.forEach((column, idxColumn) => {
            let columnItem = columns[column];
            columnItem.taskIds.forEach((task, idxTask) => {
                let item = tasks[task];
                item.Disabled = columnItem.code === 'CL1' ? 1 : 0;
                item.DisplayOrder = idxTask + (columnItem.code === 'CL1' ? columns['column-2'].taskIds.length : 0);
                dataNew.push(item);
            })
        });
    };

    loadDetail = (taskID) => {
        if (this.props.loadDetail) this.props.loadDetail(taskID);
    };

    render() {
        const {tasks, columns, columnOrder} = this.state;

        return (
            <DragDropContext
                onDragEnd={this.onDragEnd}
            >
                {columnOrder && columnOrder.map(columnId => {
                    const column = {...columns}[columnId];
                    if (!column || !column.taskIds) return null;
                    const tasksL = column.taskIds.map(taskId => tasks[taskId]);
                    return <ColumnItem key={column.id} column={column} tasks={tasksL} loadDetail={this.loadDetail}/>
                })}
            </DragDropContext>
        )
    }
}

class ColumnItem extends React.Component {

    loadDetail = (taskID) => {
        if (this.props.loadDetail) this.props.loadDetail(taskID);
    };

    render() {
        const {column, tasks} = this.props;

        return (
            <div className="list-column col-lg-3 col-md-3 col-sm-6 col-xs-12"
                 style={{marginBottom: 10, display: 'flex', flexDirection: 'column'}}
                 ref='list-column'>
                <div style={{backgroundColor: column.backgroundColor}} className="headerList">
                    <span>{column && column.title ? column.title : ''}</span>
                    <span>{tasks && tasks.length > 0 ? tasks.length : ''}</span>
                </div>
                <Droppable droppableId={column.id}
                           type={column.id === 'column-1' ? 'done' : 'active'}
                >
                    {(provided, snapshot) => {
                        return (
                            <div className="display_col list-custom"
                                 ref={provided.innerRef}
                                // isDraggingOver={snapshot.isDraggingOver}
                                 {...provided.droppableProps}
                            >
                                {tasks.map((task, index) => {
                                    return <Task key={task.TaskID} task={task} index={index}
                                                 loadDetail={this.loadDetail}/>
                                })}
                                {provided.placeholder}
                            </div>
                        )
                    }}
                </Droppable>
            </div>
        );
    }
}

class Task extends React.Component {

    loadDetail = (taskID) => {
        if (this.props.loadDetail) this.props.loadDetail(taskID);
    };

    render() {
        const {task, index} = this.props;
        return (
            <Draggable draggableId={task.TaskID} index={index}>
                {(provided, snapshot) => (
                    <TaskContainer {...provided.draggableProps}
                                   {...provided.dragHandleProps}
                                   className="display_col custom-list-item align-between"
                                   ref={provided.innerRef}
                                   isDragging={snapshot.isDragging}
                    >
                        <ItemTemplate data={task} onClick={() => this.loadDetail(task.TaskID)}/>
                    </TaskContainer>
                )}
            </Draggable>
        )
    }
}

export class MonthView extends Component {

    loadDetail = (info) => {
        if (info && info.event && info.event.id && this.props.loadDetail) {
            this.props.loadDetail(info.event.id);
        }
    };

    render() {
        const {data} = this.props;
        let arrEvent = [];
        const colorNEW = '#437FB3';
        const colorPROCESS = '#55AA68';
        const colorDONE = '#e6c146';
        if (data && data.length > 0) {
            data.forEach((task) => {
                if (!task || !task.TaskStatus) return;
                const Mot = task.TaskDateFrom;
                const Hai = task.FinishDate;
                const Ba = task.TaskDateTo;
                let item = {
                    id: task.TaskID,
                    title: task.TitleU,
                    description: task.TitleU,
                    start: Mot || Hai || Ba,
                    color: task.TaskStatus === 'NEW' ? colorNEW : (task.TaskStatus === 'PROCESS' ? colorPROCESS : colorDONE)
                };
                if (Mot && Hai) {
                    item.end = Hai
                }
                arrEvent.push(item);
            })
        }

        return (
            <div>
                <FullCalendar
                    eventLimit={true} // for all non-TimeGrid views
                    locale={Config.language === '84' ? 'vi' : 'en'}
                    defaultView="dayGridMonth"
                    customButtons={{
                        myCustomButton: {
                            text: 'custom!',
                            click: function () {
                                // alert('clicked the custom button!');
                            }
                        }
                    }}
                    header={{
                        left: 'title today prev,next',
                        center: '',
                        right: ''
                    }}
                    eventRender={(view) => {
                        // const title = view.title;
                        const html = '<span style="background-color: #437FB3;"> </span> Chưa thưc hiện' +
                            '<span style="background-color: #55AA68;"/> </span>Đang thưc hiện' +
                            '<span style="background-color: #e6c146;"/> </span>Đã hoàn thành';
                        document.querySelector(".fc-right").innerHTML = html;
                    }}
                    views={{
                        dayGridMonth: { // name of view
                            titleFormat: {year: 'numeric', month: 'numeric'},
                        }
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    events={data ? arrEvent : []}
                    eventClick={(info) => {
                        this.loadDetail(info)
                    }}
                />
                {!data && <Loading/>}
            </div>
        )
    }
}

export default connect(state => ({
        loadGridW18F1020: state.w18F1020.loadGridW18F1020,
        loadDetailW18F1020: state.w18F1020.loadDetailW18F1020,
        cboGroupSaleW18F1020: state.w18F1020.cboGroupSaleW18F1020,
        cboTypeTimeW18F1020: state.w18F1020.cboTypeTimeW18F1020,
        scheduleData: state.w18F1020.scheduleData
    }),
    (dispatch) => ({
        w18F1020Actions: bindActionCreators(w18F1020Actions, dispatch),
        w18F1014Actions: bindActionCreators(w18F1014Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(DashboardPage);

