/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/08 16:37
 * @file src/components/not-permission/not-permission.js
 */

import React, {Component} from 'react';
import {FormGroup, Col} from "reactstrap";
import PageContainer from "../common/page-container/page-container";
import Config from "../../config";

export default class ModuleNotFound extends Component {
    render() {

        return (
            <div className="login">
                <PageContainer>
                    <FormGroup row>
                        <Col sm={5}>
                            <h3 style={{marginTop:200}} className="text-orange">{Config.lang("CRM_403_loi")}</h3>
                        </Col>
                        <Col sm={7} className="text-center">
                            <img
                                src={require("../../images/permission.png")}
                                alt="" width={400}/>
                        </Col>
                    </FormGroup>
                </PageContainer>
            </div>
        )
    }
}