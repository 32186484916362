import React, { useEffect, useRef, useState } from "react";
import {
    TextField, Paper, Table, TableHead, TableRow, TableCell, TableBody, Radio,
} from "@material-ui/core";
import { useStyles } from "./styles";
import moment from "moment";
import DoneAllIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Config from "../../config";
import Attachments from "./attachments/attachments";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import Api from "./Api";
import { Row, Col, Button, Typography, Divider } from 'diginet-core-ui/components';

const TypographyNote = (props) => <Typography type={"h3"} format={["italic"]} {...props} />;

const getDate = (date) => {
    if (!date) return moment().format("YYYY-MM-DD");
    return moment(date).format("YYYY-MM-DD");
};

const checkAccess = (param, callBackDisabled) => {
    if (!param || !param.VoucherID || !param.DivisionID) {
        Config.popup.show('INFO', Config.lang("Thong_tin_cung_cap_khong_hop_le"));
        if (callBackDisabled) callBackDisabled(true);
        return false;
    }
    return true;
};

const FormReport = (props) => {
    const classes = useStyles();
    const attRef = useRef();
    const [listFiles, setListFiles] = useState([]);
    const [isSave, setIsSave] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [listDetail, setListDetail] = useState({});
    const [listDetailNote, setListDetailNote] = useState({});
    const [objectName, setObjectName] = useState("");
    const [contactPersonName, setContactPersonName] = useState("");
    const [position, setPosition] = useState("");
    const [respondedDate, setRespondedDate] = useState(moment());
    const [respondedNotes, setRespondedNotes] = useState("");
    const [totalPoint, setTotalPoint] = useState(0);

    const [detail, setDetail] = useState([]);

    const [companyName, setCompanyName] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [companyFax, setCompanyFax] = useState("");

    const textConven = [
        Config.lang("Chua_dap_ung"),
        Config.lang("Tam_dap_ung_Can_cai_tien_them"),
        Config.lang("Dap_ung"),
        Config.lang("Hai_long_can_cai_tien_them"),
        Config.lang("Hoan_toan_hai_long")
    ];

    const loadDataForm = () => {
        const {query} = props.location;
        if (!checkAccess(props.location.query, setIsSave)) return false;
        const param = {
            VoucherID: query.VoucherID ? query.VoucherID : "",
            DivisionID: query.DivisionID ? query.DivisionID : ""
        };

        Api.getFormInfo(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                switch (errorCode) {
                    case "F2500E001":
                        message = Config.lang("VoucherID_khong_duoc_de_trong");
                        break;
                    default:
                        message = error.message || Config.lang("Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                if (data.Status && data.Status === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    setIsSave(true);
                    return false;
                } else {
                    setDataForm(data);
                }
            }
        });
    };

    useEffect(() => {
        let total = 0;
        Object.keys(listDetail).forEach((e) => {
            total += Number(listDetail[e]);
        });
        setTotalPoint((total / Object.keys(listDetail).length).toFixed(2));
    }, [listDetail]);

    useEffect(() => {
        /* eslint-disable */
        loadDataForm();
    }, []);

    const setDataForm = (data) => {
        const {master, detail, footer} = data;
        if (master) {
            if (master.ObjectName) setObjectName(master.ObjectName);
            if (master.ContactPersonName) setContactPersonName(master.ContactPersonName);
            if (master.Position) setPosition(master.Position);
            if (master.RespondedDate) setRespondedDate(getDate(master.RespondedDate));
            if (master.RespondedNotes) setRespondedNotes(master.RespondedNotes);
            if (master.TotalPoint) setTotalPoint(master.TotalPoint);
        }
        if (detail) {
            let _listDetail = {};
            let _listDetailNote = {};
            detail.forEach((e) => {
                _listDetail[e.CriteriaID] = e.Point;
                _listDetailNote[e.CriteriaID] = e.DetailRespondedNotes;
            });
            setListDetail(_listDetail);
            setListDetailNote(_listDetailNote);
            setDetail(detail);
        }
        if (footer) {
            if (footer.CompanyName) setCompanyName(footer.CompanyName);
            if (footer.CompanyAddress) setCompanyAddress(footer.CompanyAddress);
            if (footer.CompanyPhone) setCompanyPhone(footer.CompanyPhone);
            if (footer.CompanyFax) setCompanyFax(footer.CompanyFax);
        }
    };


    const handleTextChange = (e, field) => {
        switch (field) {
            case "ContactPersonName":
                setContactPersonName(e.target.value);
                break;
            case "Position":
                setPosition(e.target.value);
                break;
            case "RespondedNotes":
                setRespondedNotes(e.target.value);
                break;
            case "TotalPoint":
                setRespondedNotes(e.target.value);
                break;
            default:
                break;
        }
    };

    const handleDateChange = (date) => {
        setRespondedDate(date);
    };

    const handleRadioChange = (e, criteriaID) => {
        setListDetail({
            ...listDetail,
            [criteriaID]: Number(e.target.value)
        });
    };

    const handleDetailNoteChange = (e, criteriaID) => {
        setListDetailNote({
            ...listDetailNote,
            [criteriaID]: e.target.value
        });
    };

    const onAttachment = () => {
        attRef.current.onAttachment();
    };

    const onUploading = (e) => {
        setIsUploading(e.uploading);
    };

    const onChangeUpload = (e) => {
        setListFiles(e.files);
    };

    const _saveCDN = async () => {
        const uploadFiles = await attRef.current._uploadFile(listFiles, 'multiple');
        //temp..
        if (uploadFiles && uploadFiles.code === 200 && uploadFiles.data) {
            const data = uploadFiles.data.paths;

            let listAttachments = [];
            data.forEach((att, i) => {
                listAttachments.push({
                    name: att.fileName,
                    size: att.fileSize,
                    ext: "." + Config.helpers.getExtFile(att.fileName),
                    url: att.url
                });
            });
            return await listAttachments;
        } else {
            return [];
        }
    };

    const _saveAttachments = async (afterSaveAttachment) => {
        const {query} = props.location;
        const listAttachments = await _saveCDN();

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.name ? att.name : "",
                FileSize: att.size ? att.size : "",
                FileExt: att.ext ? att.ext : ""
            });
        });
        let param = {
            params: JSON.stringify({
                TableName: "D17T2500_WEB",
                KeyID: query.VoucherID ? query.VoucherID : "",
                DivisionID: query.DivisionID ? query.DivisionID : "",
                arrAttachment: arrAttachment,
            })
        };

        Api.saveAttachment(param, (error) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                setIsSave(false);
                return false;
            }

            if (afterSaveAttachment) afterSaveAttachment();
        });
    };

    const _saveData = () => {
        let _listDetail = [];
        detail.forEach((d) => {
            const tmp = {
                CriteriaID: d.CriteriaID,
                Point: listDetail[d.CriteriaID],
                DetailRespondedNotes: listDetailNote[d.CriteriaID]
            };
            _listDetail.push(tmp);
        });
        const {query} = props.location;
        let paramSave = {
            data: JSON.stringify({
                VoucherID: query.VoucherID ? query.VoucherID : "",
                DivisionID: query.DivisionID ? query.DivisionID : "",
                master: {
                    ContactPersonName: contactPersonName,
                    Position: position,
                    RespondedDate: respondedDate ? moment(respondedDate).format("YYYY-MM-DD") : "",
                    TotalPoint: totalPoint ? totalPoint : 0,
                    RespondedNotes: respondedNotes
                },
                detail: _listDetail
            })
        };

        Api.saveVouchers(paramSave, (error) => {
            if (error) {
                let errorCode = error.code || null;
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                switch (errorCode) {
                    case "F2500E010":
                        message = Config.lang("Cac_tham_so_la_bat_buoc");
                        break;
                    case "F2500E009":
                        message = Config.lang("Tham_so_truyen_vao_khong_hop_le");
                        break;
                    case "F2500E001":
                        message = Config.lang("VoucherID_khong_duoc_de_trong");
                        break;
                    case "F2500E002":
                        message = Config.lang("Khoi_la_bat_buoc_nhap");
                        break;
                    case "F2500E011":
                        message = Config.lang("Cac_tieu_chi_la_bat_buoc_nhap");
                        break;
                    case "F2500E008":
                        message = Config.lang("Luu_thong_tin_chung_tu_khong_thanh_cong");
                        break;
                    default:
                        message = error.message || Config.lang("Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);
                setIsSave(false);
                return false;
            }

            setIsSave(true);
            Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
        });
    };

    const onSave = () => {
        if (!validateForm()) return false;
        setIsSave(true);
        if (listFiles && listFiles.length > 0) {
            _saveAttachments(_saveData);
        } else {
            _saveData();
        }
    };

    const validateForm = () => {
        if (isUploading) {
            Config.popup.show('INFO', Config.lang("Tap_tin_dinh_kem_dang_duoc_xu_ly"));
            return false;
        }
        if (!contactPersonName) {
            Config.popup.show('INFO', Config.lang("Nguoi_danh_gia_bat_buoc_nhap"));
            return false;
        }
        if (!position) {
            Config.popup.show('INFO', Config.lang("Chuc_vu_bat_buoc_nhap"));
            return false;
        }
        if (!respondedDate) {
            Config.popup.show('INFO', Config.lang("Ngay_danh_gia_bat_buoc_nhap"));
            return false;
        }
        return true;
    };

    return (
        <React.Fragment>
            <Row className={"pdb4x"}>
                <Col xs={12}>
                    <Typography type={"title4"} className={classes.titleContent}>
                        {Config.lang("Thong_tin_chinh")}
                    </Typography>
                </Col>
                <Col xs={12}>
                    <div className={"pdl6x"}>
                        <Row>
                            <Col xs={12}>
                                <TextField
                                    id="standard-full-width"
                                    label={Config.lang("Cong_trinh/Cong_ty")}
                                    value={objectName}
                                    fullWidth
                                    margin={"none"}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />{" "}
                            </Col>
                            <Col xs={12}>
                                <TextField
                                    id="standard-full-width"
                                    label={Config.lang("Nguoi_danh_gia")}
                                    value={contactPersonName}
                                    fullWidth
                                    margin={"none"}
                                    disabled={isSave}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={(e) => handleTextChange(e, "ContactPersonName")}
                                />{" "}
                            </Col>
                            <Col xs={12}>
                                <TextField
                                    id="standard-full-width"
                                    label={Config.lang("Chuc_vu")}
                                    value={position}
                                    fullWidth
                                    margin={"none"}
                                    disabled={isSave}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={(e) => handleTextChange(e, "Position")}
                                />
                            </Col>
                            <Col xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        id="date-picker-inline"
                                        label={Config.lang("Ngay_danh_gia")}
                                        disabled={isSave}
                                        TextFieldComponent={TextField}
                                        value={respondedDate ? respondedDate : moment()}
                                        onChange={handleDateChange}
                                        fullWidth={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={12}>
                    <Typography type={"title4"} className={classes.titleContent}>
                        {Config.lang("Noi_dung_khao_sat")}
                    </Typography>
                </Col>
                <Col xs={12}>
                    <div className={classes.wrapperDiv}>
                        <Row>
                            <Col xs={12}>
                                <div className={classes.boxSub} style={{ textAlign: 'center' }}>
                                    <DoneAllIcon fontSize={'small'} className={classes.iconSubTitle} />
                                    <TypographyNote type={"h4"} color={"secondary"}>
                                        {Config.lang("AC_vui_long_chon_y_kien_cua_minh")}
                                    </TypographyNote>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className={classes.boxSub}>
                                    <TypographyNote color={"secondary"}>
                                        {Config.lang("Quy_uoc_nhu_sau")}:
                                    </TypographyNote>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className={"wrap valign-middle"}>
                                    {textConven && textConven.map((e, i) => {
                                        return (
                                            <Typography type={"h3"} key={i} className={"mgr6x"} >{(i + 1) + ". " + e}</Typography>
                                        );
                                    })}
                                </div>
                            </Col>
                            <Col xs={12}>
                                <Paper className={"table-responsive"} style={{ maxHeight: 620, overflowY: "auto" }}>
                                    <div>
                                        <Table size="small" style={{ minWidth: 768 }}>
                                            <TableHead style={{ backgroundColor: '#C9F3FF' }}>
                                                <TableRow>
                                                    <TableCell style={{ width: 30 }} rowSpan={2}>STT</TableCell>
                                                    <TableCell size={"small"} align="center" rowSpan={2}>{Config.lang("Noi_dung")}</TableCell>
                                                    <TableCell size={"small"} align="center" colSpan={5} style={{ width: 100 }}>{Config.lang("Muc_do_dong_y")}</TableCell>
                                                    <TableCell size={"small"} align="center" rowSpan={3}>{Config.lang("Y_kien_dong_gop")}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell padding={'checkbox'} align="center" style={{width: 30}}>1</TableCell>
                                                    <TableCell padding={'checkbox'} align="center" style={{width: 30}}>2</TableCell>
                                                    <TableCell padding={'checkbox'} align="center" style={{width: 30}}>3</TableCell>
                                                    <TableCell padding={'checkbox'} align="center" style={{width: 30}}>4</TableCell>
                                                    <TableCell padding={'checkbox'} align="center" style={{width: 30}}>5</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {detail && detail.map((row, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            <TableCell align="center">{row.OrderNum}</TableCell>
                                                            <TableCell align="center">{row.Description}</TableCell>
                                                            <TableCell padding={'checkbox'} align="center">
                                                                <Radio
                                                                    checked={listDetail[row.CriteriaID] === 1}
                                                                    className={classes.radioButton}
                                                                    value={1}
                                                                    disabled={isSave}
                                                                    name="radiobutton1"
                                                                    onChange={(e) => handleRadioChange(e, row.CriteriaID)}
                                                                />
                                                            </TableCell>
                                                            <TableCell padding={'checkbox'} align="center">
                                                                <Radio
                                                                    checked={listDetail[row.CriteriaID] === 2}
                                                                    className={classes.radioButton}
                                                                    value={2}
                                                                    disabled={isSave}
                                                                    onChange={(e) => handleRadioChange(e, row.CriteriaID)}
                                                                />
                                                            </TableCell>
                                                            <TableCell padding={'checkbox'} align="center">
                                                                <Radio
                                                                    checked={listDetail[row.CriteriaID] === 3}
                                                                    className={classes.radioButton}
                                                                    value={3}
                                                                    disabled={isSave}
                                                                    onChange={(e) => handleRadioChange(e, row.CriteriaID)}
                                                                />
                                                            </TableCell>
                                                            <TableCell padding={'checkbox'} align="center">
                                                                <Radio
                                                                    checked={listDetail[row.CriteriaID] === 4}
                                                                    className={classes.radioButton}
                                                                    value={4}
                                                                    disabled={isSave}
                                                                    onChange={(e) => handleRadioChange(e, row.CriteriaID)}
                                                                />
                                                            </TableCell>
                                                            <TableCell padding={'checkbox'} align="center">
                                                                <Radio
                                                                    checked={listDetail[row.CriteriaID] === 5}
                                                                    className={classes.radioButton}
                                                                    value={5}
                                                                    disabled={isSave}
                                                                    onChange={(e) => handleRadioChange(e, row.CriteriaID)}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <TextField
                                                                    id="standard-full-width"
                                                                    defaultValue={row.DetailRespondedNotes}
                                                                    value={listDetailNote[row.CriteriaID]}
                                                                    fullWidth
                                                                    margin={"none"}
                                                                    disabled={isSave}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    onChange={(e) => handleDetailNoteChange(e, row.CriteriaID)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Paper>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className={"display_row align-center"}>
                                    <TypographyNote className={"pull-left mgr3x"}>
                                        {Config.lang("Tong_diem")}:
                                    </TypographyNote>
                                    <TextField
                                        className={"pull-left"}
                                        type={'number'}
                                        margin={"none"}
                                        variant={'standard'}
                                        style={{width: 300}}
                                        value={totalPoint}
                                        disabled={true}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TypographyNote color={"secondary"}>
                                    {Config.lang("Cac_gop_y_khac")}:
                                </TypographyNote>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    multiline
                                    rows={5}
                                    variant={"filled"}
                                    fullWidth
                                    disabled={isSave}
                                    value={respondedNotes}
                                    margin={"none"}
                                    onChange={(e) => handleTextChange(e, "RespondedNotes")}
                                />
                            </Col>
                            <Col xs={12}>
                                <div className={classes.boxSub} style={{ textAlign: 'center' }}>
                                    <TypographyNote type={"h4"} color={"secondary"}>
                                        {Config.lang("Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan")}
                                        <Typography type={"h1"} color={"secondary"} format={["italic"]} className={'mgv1x'}>❧</Typography>
                                    </TypographyNote>
                                </div>
                            </Col>
                            <Attachments
                                isSaveCDN
                                ref={attRef}
                                uploading={isUploading}
                                showButton={false}
                                disabled={isSave}
                                chooseWithoutSave={true}
                                onUploading={onUploading}
                                onChange={onChangeUpload}
                            />
                            <Col xs={12}>
                                <Button
                                    className={"pull-right"}
                                    color={"info"}
                                    size={'small'}
                                    viewType={'filled'}
                                    label={Config.lang("Gui")}
                                    disabled={isSave}
                                    onClick={onSave}
                                />
                                <Button
                                    className={"pull-right mgr1x"}
                                    size={'small'}
                                    label={Config.lang("Dinh_kem")}
                                    disabled={isSave}
                                    onClick={onAttachment}
                                />
                            </Col>
                            <div className={classes.clsfx} />
                        </Row>
                    </div>
                </Col>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <Typography type={"h3"} className={classes.titleInput + " pull-left"}>
                        {Config.lang("Cong_ty")}:
                    </Typography>
                    <TextField
                        className={"pull-left"}
                        variant={'standard'}
                        margin={'none'}
                        style={{width: '75%'}}
                        value={companyName}
                        disabled={true}
                    />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <Typography type={"h3"} className={classes.titleInput + " pull-left"}>
                        {Config.lang("So_Fax")}:
                    </Typography>
                    <TextField
                        className={"pull-left"}
                        variant={'standard'}
                        margin={'none'}
                        style={{width: '75%'}}
                        value={companyFax}
                        disabled={true}
                    />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <Typography type={"h3"} className={classes.titleInput + " pull-left"}>
                        {Config.lang("Dia_chi")}:
                    </Typography>
                    <TextField
                        className={"pull-left"}
                        variant={'standard'}
                        margin={'none'}
                        style={{width: '75%'}}
                        value={companyAddress}
                        disabled={true}
                    />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <Typography type={"h3"} className={classes.titleInput + " pull-left"}>
                        {Config.lang("Dien_thoai")}:
                    </Typography>
                    <TextField
                        className={"pull-left"}
                        variant={'standard'}
                        margin={'none'}
                        style={{width: '70%'}}
                        value={companyPhone}
                        disabled={true}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default FormReport;
