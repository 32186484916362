/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/02/26 19:08
 * @update 2019/02/26 19:08
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as templateDropdownActions from "../../../../actions/template-dropdown-actions";
import Combo from '../combo';
import {Column} from 'devextreme-react/data-grid';
import Config from "../../../../config";

class DropDownReportTemplate extends Component {
    constructor(props) {
        super(props);
        this.dataSource = [];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.reportTypeID !== prevProps.reportTypeID) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        const param = {
            "reportTypeID": this.props.reportTypeID
        };
        this.props.templateDropdownActions.cboReportTemplate(param, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    }

    onChange(data) {
        if (this.props.onChange)
            this.props.onChange(data);
    }

    render() {
        const {cboReportTemplate, reset, required, showClearButton, displayExpr, modePopUp} = this.props;
        this.dataSource = [];
        if (cboReportTemplate) {
            cboReportTemplate.map(o => {
                this.dataSource.push(o);
                return o;
            });
        } else {
            return null;
        }

        return (
            <div>
                <Combo
                    reset={reset}
                    showHeader={true}
                    showClearButton={showClearButton}
                    dataSource={this.dataSource}
                    placeholder={this.props.placeholder}
                    value={this.props.reportID}
                    valueExpr="ReportID"
                    displayExpr={displayExpr ? displayExpr : "Title"}
                    required={required}
                    onActionWhenSelectChange={(data) => {
                        this.onChange(data)
                    }}
                >
                    <Column dataField={'ReportID'} caption={Config.lang("CRM_Ma")}
                            width={modePopUp === true ? '80%' : '20%'} dataType={'string'}/>
                    <Column dataField={'Title'} caption={Config.lang("CRM_Ten")} width={'80%'}
                            visible={modePopUp !== true} dataType={'string'}/>
                    <Column dataField={'FileExt'} caption="Ext" width={'10%'} dataType={'string'}/>
                    <Column dataField={'FileName'} caption="" visible={false} dataType={'string'}/>
                    <Column dataField={'URL'} caption="" visible={false} dataType={'string'}/>
                </Combo>
            </div>
        );
    }
}

DropDownReportTemplate.propTypes = {
    showClearButton: PropTypes.bool,
    modePopUp: PropTypes.bool,
    displayExpr: PropTypes.string,
    placeholder: PropTypes.string,
    reportTypeID: PropTypes.string,
    value: PropTypes.string,
    reset: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
};

export default connect(state => ({
        cboReportTemplate: state.templateDropdown.cboReportTemplate
    }),
    (dispatch) => ({
        templateDropdownActions: bindActionCreators(templateDropdownActions, dispatch)
    })
)(DropDownReportTemplate);
