/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/16 3:23
 * @update 2019/01/16 3:23
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";
import {Glyphicon} from "react-bootstrap";

class ButtonCustom extends Component {

    onClick() {
        if(this.props.onClick) this.props.onClick();
    }

    render() {
        const {name, icon, color, isAwesome,className,id,disabled} = this.props;
        return (
            <ButtonBasic id={id} disabled={disabled} className={`${className?className:''} btn-crm`} onClick={()=>this.onClick()}>
                {!isAwesome &&  <div>{icon && <Glyphicon glyph={icon} className={color} />} {icon && name && '\u00A0'} {name}</div>}

                {isAwesome && icon && <div>{icon && <i className={icon +' '+color}/>} {icon && name && '\u00A0'} {name}</div>}
            </ButtonBasic>
        );
    }
}

ButtonCustom.propTypes = {
    name: PropTypes.string,
    icon:PropTypes.string,
    color:PropTypes.string,
    isAwesome:PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,


};

export default ButtonCustom;
