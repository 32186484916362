/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/02/26 19:08
 * @update 2019/02/26 19:08
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as templateDropdownActions from "../../../../actions/template-dropdown-actions";
import Combo from '../combo';
import Config from "../../../../config";
import {Column} from 'devextreme-react/data-grid';
import {Row, Col} from 'react-bootstrap';

class DropDownSalePerson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salesPersonID: '',
            groupSalesID: '',
            reset: this.props.reset,
            dataSource: []
        };
        this.dataSource = [];
    }

    static getDerivedStateFromProps(nextProps, state) {
        let newState = {};
        if (nextProps.value !== state.salesPersonID) {
            newState.salesPersonID = nextProps.value;
        }
        if (state.groupSalesID !== nextProps.groupSalesID) {
            newState.salesPersonID = '';
            newState.groupSalesID = nextProps.groupSalesID;
            newState.reset = nextProps.reset ? nextProps.reset : true
        } else {
            newState.reset = nextProps.reset !== state.reset ? nextProps.reset : false
        }
        if (Object.keys(newState).length) {
            return newState;
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.groupSalesID !== this.props.groupSalesID) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = (groupSalesID) => {
        const sLanguage = Config.language || '84';
        const param = {
            "sLanguage": sLanguage,
            "groupSalesID": groupSalesID ? groupSalesID : this.state.groupSalesID,
        };
        this.props.templateDropdownActions.cboSalePerson(param, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
        this.setState({
            reset: false
        });
    };


    onChange = (data) => {
        if (this.props.onChange)
            this.props.onChange(data);
    };

    // renderImg(e) {
    //      if (!e) return null;
    //      // console.log("==============renderImg=================",e.row.data.Image);
    //      if (e.row.data.Image)
    //         return <img className="image-sale-person"  alt={""} src={e.row.data.Image} />;
    //      else
    //         return <img className="image-sale-person" alt={""} src={require('../../../../images/icon-user-default.png')} />;
    // }

    renderName(e) {
        if (!e) return null;
        const imageData = e.row.data.Image;

        return (
            <Row className={'mg0'}>
                <Col sm={12} lg={12} className={'pd0'}>
                    <div className='display_row staff-dropdown align-center'>
                        <img className="image-sale-person"
                             alt={""}
                             src={imageData ? imageData : require('../../../../images/icon-user-default.png')}
                        />
                        <div className='display_col staff-info'>
                            <span>{e.row.data.SalesPersonNameU}</span>
                            <span>{e.row.data.SalesPersonID}</span>
                            <span>{e.row.data.JobPosition}</span>
                        </div>
                    </div>
                </Col>
            </Row>
        );

    }


    render() {
        const {cboSalePerson, placeholder, showClearButton, disabled, width} = this.props;
        this.dataSource = [];
        if (!cboSalePerson) {
            return null;
        }
        if (cboSalePerson) {
            cboSalePerson.map(o => {
                const item = {
                    SalesPersonID: o.SalesPersonID
                    , SalesPersonNameU: o.SalesPersonNameU
                    , JobPosition: o.JobPositionU
                    , Image: o.Image
                };
                this.dataSource.push(item);
                return o;
            });
        }

        return (
            <React.Fragment>
                <Combo
                    reset={this.state.reset}
                    width={width}
                    showClearButton={showClearButton}
                    showHeader={false}
                    dataSource={this.dataSource}
                    placeholder={placeholder}
                    value={this.state.salesPersonID}
                    valueExpr="SalesPersonID"
                    displayExpr="SalesPersonNameU"
                    disabled={disabled}
                    onActionWhenSelectChange={(data) => {
                        this.onChange(data)
                    }}
                >
                    <Column dataField={'SalesPersonID'}
                            caption=""
                            minWidth={'150px'}
                            visible={false}
                            dataType={'string'}/>
                    <Column dataField={'UserID'} c
                            aption=""
                            minWidth={'150px'}
                            visible={false}
                            dataType={'string'}/>
                    <Column cellRender={(data) => this.renderName(data)}
                            dataField={'SalesPersonNameU'}
                            caption=""
                            minWidth={'150px'}
                            dataType={'string'}/>
                </Combo>
            </React.Fragment>
        );
    }
}

DropDownSalePerson.propTypes = {
    showClearButton: PropTypes.bool,
    placeholder: PropTypes.string,
    groupSalesID: PropTypes.string,
    value: PropTypes.string,
    reset: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    width: PropTypes.any,
};

export default connect(state => ({
        cboSalePerson: state.templateDropdown.cboSalePerson
    }),
    (dispatch) => ({
        templateDropdownActions: bindActionCreators(templateDropdownActions, dispatch)
    }))(DropDownSalePerson);