/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/19 22:46
 * @update 2019/01/20 03:46
 */

import React, { Component } from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types';
import {browserHistory} from "react-router";
import Config from '../../config';
import * as userActions from "../../actions/user-actions";
import Link from "react-router/es/Link";

export class MenuItem extends Component {

    closeMenu() {
        const menus = document.getElementsByClassName("sub-menu");
        for (let i=0; i<menus.length; i++){
            menus[i].style.display='none';
        }
        setTimeout(() => {
            for (let i=0; i<menus.length; i++){
                menus[i].style.removeProperty('display');
            }
        }, 500);

    }

    onMenuClick = (e, url) => {
        if (!url || url === '' || url === '#') return false;
        // console.log("=================onMenuClick============",url);
        e.stopPropagation();
        this.closeMenu();
        // console.log("=================onMenuClick============",Config.env.url + '/' + url);
        if(this.props.resetPermission) this.props.resetPermission();
        browserHistory.push(Config.env.url + '/' + url);
    };

    render() {
        const { data, sub, className } = this.props;
        const subMN = sub ? "sub-menu" : "nav";

        /* eslint-disable */
        return (
            <ul className={subMN + (className ? (' '+className) : '')}>
                {data.map((m, i) => {
                    return (
                        <li key={i} onClick={(e)=>this.onMenuClick (e, m.FormID)}>
                            <Link to={m.FormID}><i className={m.MenuIcon} /> {m.MenuName}</Link>
                            {m.children && <i className="ico-right fas fa-caret-right" />}
                            {m.children &&
                                <MenuItem data={m.children} sub={"sub-menu"}
                                          resetPermission={()=>this.props.resetPermission()}
                            />}
                        </li>
                    );
                })}
            </ul>
        );
        /* eslint-enable */
    }
}

MenuItem.propTypes = {
    data: PropTypes.array
};

class MenuBar extends Component {
    render() {
        let {data,className} = this.props;
        return (
            <div className="menu">
                <MenuItem className={className ? className : ''}
                          data={data}
                          resetPermission={()=>this.props.userActions.resetPermission()}
                />
            </div>
        );
    }
}

MenuBar.propTypes = {
    data: PropTypes.array
};

export default connect(state => ({
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
    })
)(MenuBar);