/**
 * @copyright 2018 @ C.O.I
 * @author rocachien
 * @create 2017/06/30 15:34
 * @update 2017/06/30 15:34
 * @file src/components/common/course/course.js
 */

import React, {memo, PureComponent} from 'react';
import PropTypes from 'prop-types';

import {bindActionCreators} from 'redux'
import Combo from "../combo";
import {connect} from 'react-redux'
import * as action from "../../../../actions/template-dropdown-actions";
import {getCboDivision} from "../../../../actions/template-dropdown-actions";
import {Column} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from 'devextreme/data/data_source';
import _ from "lodash";

const dataSource = new DataSource(
    {
        store: new CustomStore({
            key: "DivisionID",
            load: async (loadOptions) => {
                const param = {
                    "skip": loadOptions.skip,
                    "limit": loadOptions.take,
                };
                if (loadOptions.filter) param.search = loadOptions.filter.filterValue;
                const data = await getCboDivision(param);
                return {
                    'data': data.rows,
                    'totalCount': data.total
                };
            },
            byKey: async (key) => {
                const data = await getCboDivision({search: key});
                return data.rows && data.rows.find((value) =>
                    value.DivisionID === key
                );
            }
        }),
    }
);

export class Container extends PureComponent {


    componentDidMount() {
        this.props.action.getCboArea()

    }

    render() {
        const {onChange, showClearButton,showIDName = false} = this.props;
        return <Ui onChange={onChange}
                   showClearButton={showClearButton}
                   showIDName={showIDName}/>
    }
}

const Ui = memo((props) => {
    const {onChange, showClearButton, showIDName} = props;
    return (
        <Combo
            showClearButton={showClearButton}
            onActionWhenSelectChange={onChange}
            virtual={true}
            valueExpr={'DivisionID'}
            displayExpr={'DivisionName'}
            dataSource={dataSource}>
             <Column
                dataField={'DivisionName'}
                dataType={'string'}
                {...(showIDName ? {
                    cellRender: (e) => {
                        const line = !_.isEmpty(e.row.data.DivisionName) ? '-' : '';
                        return `${e.row.data.DivisionID} ${line} ${e.row.data.DivisionName}`
                    }
                } : {})}
            />
        </Combo>
    )
});


Container.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    data: PropTypes.array,
    showIDName: PropTypes.bool,
    showClearButton: PropTypes.bool,
    onChange: PropTypes.func
};

export default connect(
    state => ({dataSource: state.templateDropdown.cboArea}),
    dispatch => ({
        action: bindActionCreators(action, dispatch)
    }), null, {withRef: true})(Container);