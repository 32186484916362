/**
 * @copyright 2018 @ C.O.I
 * @author rocachien
 * @create 2017/06/30 15:34
 * @update 2017/06/30 15:34
 * @file src/components/common/course/course.js
 */

import React, {memo, PureComponent} from 'react';
import PropTypes from 'prop-types';

import {bindActionCreators} from 'redux'
import Combo from "../combo";
import {connect} from 'react-redux'
import * as action from "../../../../actions/template-dropdown-actions";
import {Column} from "devextreme-react/data-grid";


export class Container extends PureComponent {


    componentDidMount() {
        this.props.action.getCboArea()

    }

    render() {
        const {dataSource, onChange, showClearButton, value} = this.props;
        return <Ui dataSource={dataSource}
                   onChange={onChange}
                   value={value}
                   showClearButton={showClearButton}/>
    }
}

const Ui = memo((props) => {
    const {dataSource, onChange, showClearButton, value} = props;

    return (
        <Combo
            value={value}
            valueExpr={'SalesOfficeID'}
            displayExpr={'SalesOfficeName'}
            onActionWhenSelectChange={onChange}
            showClearButton={showClearButton}
            dataSource={dataSource}>
            <Column dataField={'SalesOfficeName'} dataType={'string'}/>
        </Combo>
    )
});


Container.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    data: PropTypes.array,
    showClearButton: PropTypes.bool,
    onChange: PropTypes.func
};

export default connect(
    state => ({dataSource: state.templateDropdown.cboArea}),
    dispatch => ({
        action: bindActionCreators(action, dispatch)
    }), null, {withRef: true})(Container);