import {makeStyles} from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
    root: {
        fontSize: 16,
        flexGrow: 1,
        height: "100%",
        background: "darkgray",
        display: "flex",
        flexDirection: "column",
        padding: 15,
        "& .MuiInputLabel-shrink": {
            transform: "scale(1)"
        },
        "& .MuiFormLabel-root": {
            fontSize: "12px"
        }
    },
    wrapperDiv: {
        paddingLeft: 24
    },
    mainTitle: {
      fontWeight: 800,
      letterSpacing: 2.4
    },
    boxSub: {
      display: "flex",
      alignItems: "center"
    },
    subTitle: {
      fontSize: "1rem",
      fontWeight: "bold",
      fontStyle: "italic",
      padding: "9px 0",
    color: "rgba(107,107,107,0.85)",
    },
    titleContent: {
      borderLeft: "8px solid #FF0000",
      padding: 8,
      marginTop: "8px"
    },
    iconSubTitle: {
      color: "#0723bd8c",
      fontSize: "2.5rem",
      paddingRight: 12
    },
    titleInput: {
        fontSize: "1rem",
        fontWeight: "bold",
        fontStyle: "italic",
        color: "#383838",
        marginRight: 12,
        padding: "9px 0"
    },
    paper: {
      margin: "16px 0",
      boxShadow: "0px 2px 12px 0px rgba(0,0,0,0.5)",
      borderRadius: 10,
      height: 80,
      alignItems: "center"
    },
    conven: {
      lineHeight: "30px",
      textAlign: "center",
      fontWeight: 800
    },
    radioButton: {
        padding: 0
    },
    clsfx: {
        clear: 'both'
    }
  }));
