/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/15/2019
 * @Example
 */

import React from "react";
import PropTypes from "prop-types";
import Config from "../../../config";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import FileUploadSync from "../../../actions/file-upload-async";
import { uploadCDN_sync as FileUploadCDNSync, getTokenCDN as getTokenCDNAction } from "../../../actions/file-upload-cdn-async";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from 'diginet-core-ui/components';
const styles = {
    paper: {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        padding: '10px 10px 0 10px',
        backgroundColor: 'aliceblue',
        position: 'relative',
        minHeight: 53
    },
    button: {
        position: 'absolute',
        top: 10,
        right: 10
    },
    itemContainer: {
        width: 'calc(100% - 127px)'
    }
};
class Attachments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: !!props.open,
            isUploading: false,
            fileNames: []
        };
        this.inputFiles = null;
        this.itemFiles = [];
        this.eFileNames = [];
        this.files = [];
        this.tokenCDN = "";
        this.numFileUploaded = 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open) {
            this.inputFiles.click();
        }
    }

    getTokenCDN = async () => {
        const dataCDN = await getTokenCDNAction();
        if (dataCDN?.token) this.tokenCDN = dataCDN.token;
    };

    componentDidMount() {
        if (this.props?.isSaveCDN) this.getTokenCDN();
    }

    checkFileType = (filename) => {
        const extension = filename.substr(filename.lastIndexOf('.')).toLowerCase();
        const allowedExtensions = ['.txt', '.jpg', '.png','.xls','.xlsx', '.doc', '.docx', '.pdf'];
        if (extension.length > 0) {
            if (allowedExtensions.indexOf(extension) === -1) {
                return false;
            }
        }
        return true;
    };

    onSelectFile = async () => {
        const {onChange, chooseWithoutSave, onFileSubmited} = this.props;
        const {fileNames} = this.state;
        const x = this.inputFiles;
        const arrFile = x.files;
        const sizeLimit = 5000000;
        let _fileNames = fileNames;
        let fileIndx = fileNames.length;
        for (let i = 0; i < arrFile.length; i++) {
            if ((arrFile[i].size / 1024) > Number(sizeLimit)) {
                Config.popup.show("INFO", Config.lang("Dung_luong_File_khong_duoc_lon_hon") + " " + sizeLimit + ' KB');
            } else if (this.checkFileType(arrFile[i].name) === false) {
                Config.popup.show("INFO", Config.lang('Dinh_dang_file_khong_hop_le'));
            } else {
                _fileNames.push(arrFile[i].name);
                this.itemFiles[fileIndx] = arrFile[i];
                fileIndx++;
            }
        }
        this.setState({fileNames: _fileNames});

        if (onChange) onChange({files: this.itemFiles});

        if (onFileSubmited) {
            if (chooseWithoutSave) {
                this.onSubmited(this.itemFiles);
            } else {
                this.onUploading(true);
                const upload = await this._uploadFile(this.itemFiles, 'multiple');
                this.onSubmited(upload);
            }
        }
    };

    _uploadFile = async (files, mode) => {
        return this.props.isSaveCDN ? await FileUploadCDNSync(files, mode, this.tokenCDN) : await FileUploadSync(files, mode, this.tokenCDN);
    };

    onUploading = (state) => {
        const {onUploading} = this.props;
        if (onUploading) onUploading({uploading: state});
        this.setState({isUploading: state});
    };

    onSubmited = (files) => {
        const {onFileSubmited} = this.props;
        if (onFileSubmited) onFileSubmited({files: files});
    };

    onAttachment = () => {
        this.inputFiles.click();
    };

    onRemoveFile = (key) => {
        const {onChange} = this.props;
        const {fileNames} = this.state;
        fileNames.splice(key, 1);
        this.setState({
            fileNames: fileNames
        });

        this.itemFiles.splice(key, 1);
        if (onChange) onChange({files: this.itemFiles});
    };

    render() {
        const {disabled, uploading, showButton} = this.props;
        const {isUploading, fileNames} = this.state;
        return (
            <div className={"full_w"}>
                <input ref={ref => this.inputFiles = ref} type="file" id="file" name="files[]" className="hide"
                    multiple
                    onChange={() => this.onSelectFile()}/>
                <Paper style={{...styles.paper, display: showButton || fileNames.length > 0 ? 'flex' : 'none'}}>
                    {(uploading || isUploading) && <div className="display_row align-center valign-middle loading">
                        <CircularProgress />
                    </div>}
                    <div style={showButton ? styles.itemContainer : null}>
                        {fileNames && fileNames.map((fileName, i) => {
                            return (
                                <Chip
                                    // icon={icon}
                                    key={i}
                                    disabled={disabled}
                                    ref={ref => this.eFileNames[i] = ref}
                                    label={fileName}
                                    onDelete={() => this.onRemoveFile(i)}
                                    style={styles.items}
                                />
                            );
                        })}
                    </div>
                    {showButton && <div style={styles.button}>
                        <Button
                            size={'small'}
                            viewType={'outlined'}
                            startIcon={"Attachment"}
                            className={'pull-right'}
                            label={Config.lang("Dinh_kem")}
                            loading={uploading}
                            disabled={disabled}
                            onClick={this.onAttachment}
                        />
                    </div>}
                </Paper>
            </div>
        );
    }
}

Attachments.defaultProps = {
    isSaveCDN: false
};

Attachments.propTypes = {
    uploading: PropTypes.bool,
    mode: PropTypes.string, //'single', 'multiple'
    showButton: PropTypes.bool,
    chooseWithoutSave: PropTypes.bool,
    disabled: PropTypes.bool,
    isSaveCDN: PropTypes.bool,

    onUploading: PropTypes.func,
    onChange: PropTypes.func,
    onFileSubmited: PropTypes.func,
};

export default Attachments;
