/**
 * @copyright 2019 @ DigiNet
 * @author thanhtram
 * @create 2019/01/30 11:00
 * @update 2019/01/20 03:46
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {browserHistory} from "react-router";
import Config from "../../../config";

class UserContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onUserClick(m) {
        if (!m || !m.SalesPersonID) return false;
        const SalesPersonID = m && m.SalesPersonID ? m.SalesPersonID : '';
        const disabled = m && m.Disabled ? m.Disabled : 0;
        const UserID = m.UserID;
        if(!UserID){
            Config.popup.show('INFO', Config.lang('CRM_Khong_tim_thay_du_lieu'));
            return;
        }
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1031',
            state: {mode:"edit",UserID:UserID,SalesPersonID:SalesPersonID,Disabled:disabled}
        });
    }

    onDelete(m) {
         if(this.props.onDelete){
             this.props.onDelete(m);
         }
    }

    render() {
        const {data, iPermission} = this.props;
        return (
                <div className='display_row form-group user-container'>
                    {data && data.map((value, index) => {
                        return (
                            <div
                                key={index} className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                                style={{marginBottom: '10px'}}>
                                <div className='user-hover-employee display_row form-group'>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12" id="user-employee-left">
                                        <img onClick={() => {this.onUserClick(value)}}
                                             alt={'avatar'} className="user-image-employee"
                                             src={value && value.URL ? value.URL : require('../../../images/avatar-staff-default.png')}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12" id="user-employee-right" style={{opacity: value.Disabled === 1 ? 0.7 : ''}}>
                                        {iPermission >= 4 && <div className="content_delete">
                                            <i className="fas fa-trash text-red" onClick={() => {this.onDelete(value)}}/>
                                        </div>}
                                        <div className="user-right-content"  onClick={() => {this.onUserClick(value)}}>
                                            {iPermission >= 4 && <span className="content-delete-bor"/>}
                                            <div style={{width: '100%'}}>
                                                <div>
                                                    <div className="text-blue fts16">
                                                        {value.SalesPersonNameU}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="content_user_right">
                                                <div>
                                                    {value.UserID}
                                                </div>
                                                <div>
                                                    {value.GroupSalesNameU}
                                                </div>
                                                <div>
                                                    {value.JobPositionU}
                                                </div>
                                                <div className="text-overflow fts14">
                                                    <i className="fas fa-envelope success user_mail_content pdr3"/>
                                                      {value.Email}
                                                </div>
                                                <div className=" pdb5 text-overflow fts14">
                                                    <i className="fas fa-phone primary pdr3"/>
                                                     {value.OfficeTelNo}
                                                </div>
                                                {value.Disabled === 1 && <span className='block-users-employee'>{Config.lang("CRM_Da_nghi_viec")}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
        );
    }
}

UserContainer.propTypes = {
    SalesPersonNameU: PropTypes.string,
    UserID: PropTypes.string,
    GroupSalesID: PropTypes.string,
    JobPositionU: PropTypes.string,
    Email2: PropTypes.string,

    onDelete: PropTypes.func,
    onSearch: PropTypes.func,
    onChangePage: PropTypes.func,
    onChangePerPage: PropTypes.func,
    customizeColumns: PropTypes.func,
    iPermission: PropTypes.number
};


export default UserContainer;
