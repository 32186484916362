import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";
import Config from "../../../config";

const styles = {
    root: {
        // opacity: 0
        display: "block"
    },
};

class GridActionBarHover extends React.Component {
    constructor(props) {
        super(props);
        this.actionbar = null;
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.actionbar && !Config.isMobile) {
                this.actionbar.classList.remove("grid-action-opacity");
                const container = this.actionbar.closest(".dx-datagrid-content");
                // let height = this.actionbar.offsetHeight + "px";
                if (container && container.classList.contains("dx-datagrid-content-fixed")) {
                    const td = this.actionbar.closest("td");
                    const width = this.actionbar.offsetWidth + 30 + "px";
                    // height = td.offsetHeight + "px";
                    td.style.width = width;
                    td.style.minWidth = width;
                    td.style.maxWidth = width;
                    td.classList.add("grid-action-hover");

                    const colFixed = container.getElementsByClassName("dx-col-fixed");
                    if (colFixed && colFixed.length > 0 && colFixed[0].style.width === "auto") {
                        colFixed[0].style.width = width;
                    }
                    container.querySelectorAll(".dx-freespace-row td:last-child").forEach(t => {
                        if (!t.classList.contains("grid-action-hover")) t.classList.add("grid-action-hover");
                    });
                } else {
                    const td = this.actionbar.closest("td");
                    // height = td.offsetHeight + "px";
                    td.classList.remove("dx-hidden-cell");
                    this.actionbar.style.display = "none";
                }
                // container.querySelectorAll("td").forEach(t => {
                //     t.style.height = height;
                // });
                const containerHeader = document.querySelectorAll(".dx-datagrid-headers .dx-datagrid-content");
                if (containerHeader && containerHeader.length > 0) {
                    containerHeader.forEach(cont => {
                        if (cont.classList.contains("dx-datagrid-content-fixed")) {
                            cont.querySelectorAll("table tbody tr td:last-child").forEach(td => td.classList.add("grid-action-hover"));
                        } else {
                            cont.querySelectorAll("table tbody tr td:last-child").forEach(td => td.classList.remove("dx-hidden-cell"));
                        }
                    });
                }
            }
        }, 600);
        window.addEventListener("resize", this.resizeWindow);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWindow);
    }

    resizeWindow = () => {
        if (this.actionbar && !Config.isMobile) {
            this.actionbar.classList.remove("grid-action-opacity");
            let width = this.actionbar.offsetWidth + 30 + "px";
            const container = this.actionbar.closest(".dx-datagrid-content");
            if (container.classList.contains("dx-datagrid-content-fixed")) {
                const td = this.actionbar.closest("td");
                td.classList.remove("grid-action-hover");
                width = this.actionbar.offsetWidth + 30 + "px";
                td.style.width = width;
                td.style.minWidth = width;
                td.style.maxWidth = width;
                td.classList.add("grid-action-hover");

                setTimeout(() => {
                    const colFixed = container.getElementsByClassName("dx-col-fixed");
                    const commandSelect = container.getElementsByClassName("dx-command-select"); // Kiểm tra nếu có select thì bỏ thì select cũng có class "dx-col-fixed"
                    if (colFixed && colFixed.length > 0) {
                        const indx = commandSelect.length > 0 ? 1 : 0;
                        colFixed[indx].style.width = width;
                    }
                }, 600);
            } else {
                const td = this.actionbar.closest("td");
                this.actionbar.style.display = "inline-block";
                width = this.actionbar.offsetWidth + 30 + "px";
                td.classList.remove("dx-hidden-cell");
                this.actionbar.style.display = "none";

                const cols = container.querySelector("colgroup col:not(.dx-col-fixed):last-child");
                if (cols) cols.style.width = width;
            }
            const containerHeader = document.querySelectorAll(".dx-datagrid-headers .dx-datagrid-content colgroup col:not(.dx-col-fixed):last-child");
            if (containerHeader && containerHeader.length > 0) {
                containerHeader.forEach(col => {
                    if (col) col.style.width = width;
                });
            }
        }
    };

    render() {
        const { classes, style, className, children } = this.props;
        const _className = !Config.isMobile ? "grid-action-opacity " + className : className;

        return (
            <div ref={ref => this.actionbar = ref} className={"fal-grid-actionbar " + classes.root + " " + _className}
                style={style}
            >
                {children}
            </div>
        );
    }
}

GridActionBarHover.propTypes = {
    style: PropTypes.any,
    className: PropTypes.string,

};

export default compose(withStyles(styles))(GridActionBarHover)