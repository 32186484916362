/**
 * @copyright 2019 @ DigiNet
 * @author KIMLONG
 * @create 2019/01/16 13:49
 * @update 2019/01/16 13:49
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/lib/Creatable';
import './input-email.scss';

const components = {
    DropdownIndicator: null,
};
class InputEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            value: props.value || []
        };
        this.isUpdate=false;
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
            this.setState({value: this.props.value});
        }
    }

    handleChange = (data,event) => {
        if (this.isUpdate===true){
            let str = '';
            let arrStr = [];
            let newValue =data.filter(word => word.__isNew__===true);
            let oldValue =data.filter(word => word.__isNew__!==true);
            if(newValue.length>0){
                let value = newValue[0].value;
                if (value.indexOf(',')!==-1 || value.indexOf(';')!==-1 || value.indexOf(' ')!==-1){
                    str =  value.replace(/,/gi,' ');
                    str =  str.replace(/;/gi,' ');
                    arrStr = str.split(' ');
                }else{
                    arrStr=[value];
                }
                let result =[];
                if (oldValue.length>0)
                    result=oldValue;
                for(let i=0;i< arrStr.length;i++){
                    let sValue = arrStr[i].trim();
                    if (sValue!==''){
                        const isExist = oldValue.filter(word => word.value===sValue).length>0;
                        if (isExist!==true){
                            if (this.validateEmail(sValue)===true) {
                                result.push({"label":sValue,"value":sValue});
                            }
                        }

                    }
                }

                this.setState({value: result});
                if(this.props.handleChange)
                    this.props.handleChange(result);
                this.isUpdate=false;
            }
            else{
                //Khi lost focus input dia chi mail xong va focus lai va thuc hien  xoa dia chi mail
                const value=[...this.state.value];
                let difference = value.filter(x => data.includes(x));
                    this.setState({
                        value:difference
                    })
            }

        }else{
            if (this.validateEmail(data)===true || event.action === "remove-value" || event.action === "clear") {

                this.setState({value: data});
                if(this.props.handleChange)
                    this.props.handleChange(data);

            }
        }
    };
    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    };

    validateEmail(email) {
        const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    handleKeyDown = (e) => {
        switch (e.key) {
            case 'Enter':
            case 'Tab':
                this.isUpdate=true;
                break;
            default:
                break;
        }
    };

    handleBlur =(e)=>{
        this.isUpdate=true;
        const input = e.currentTarget;
        const ke = new KeyboardEvent("keydown", {
            bubbles: true, cancelable: true, keyCode: 13
        });
        input.dispatchEvent(ke);
    };

    render(){
        const { inputValue, value } = this.state;
        const colourOptions = [];
        return (
                <CreatableSelect
                    components={components}
                    value={value}
                    className={this.props.className}
                    menuIsOpen={true}
                    noOptionsMessage = { () => null }

                    inputValue={inputValue}
                    isMulti
                    isClearable
                    onChange={this.handleChange}
                    onInputChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    onBlur={this.handleBlur}
                    placeholder={this.props.placeholder}
                    options={colourOptions}
                />
            );

    }
}

InputEmail.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    className: PropTypes.string,
    placeholder:PropTypes.string,
    handleChange: PropTypes.func,
};

export default InputEmail;
