/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/16 13:49
 * @update 2019/01/16 13:49
 */

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class Container extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isToggle: false,
        }
    }

    onClick() {
        if (this.props.onClick) this.props.onClick();
    }

    onSelect = () => {
        const {onValueChange} = this.props;
        this.setState({
            isToggle: !this.state.isToggle
        });
        onValueChange && onValueChange(!this.state.isToggle);

    };

    render() {
        const {dataSource, switchedOnText, switchedOffText, style, value} = this.props;
        const {isToggle} = this.state;
        return <Ui dataSource={dataSource}
                   isToggle={value !== undefined ? value : isToggle}
                   style={style}
                   switchedOnText={switchedOnText}
                   switchedOffText={switchedOffText}
                   onSelect={this.onSelect}/>
    }
}

const Ui = (props) => {
    const {
        onSelect,
        switchedOnText,
        switchedOffText,
        isToggle,
        style
    } = props;
    const positionSelect = {
        transform: `translate(${isToggle ? 100 : 0}%, 0)`,
        boxShadow: isToggle ? '#959595 2px 2px 4px 0' : '#959595 -2px 2px 4px 0'
    };

    return (
        <div id={'Switch-Button'} style={style}>
            <div id={'switch-left-panel'}
                 className={`${isToggle ? 'unselected-switch' : ''} switch-panel`}
                 onClick={onSelect}
            >
                {switchedOnText}
            </div>
            <div id={'switch-right-panel'}
                 className={`${!isToggle ? 'unselected-switch' : ''} switch-panel`}
                 onClick={onSelect}
            >
                {switchedOffText}
            </div>
            <div id={'selected-switch'} style={positionSelect}>
                {!isToggle ? switchedOnText : switchedOffText}
            </div>
        </div>
    )
};

Container.propTypes = {
    name: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func,
    dataSource: PropTypes.array,
    switchedOnText: PropTypes.string,
    switchedOffText: PropTypes.string,
    onValueChange: PropTypes.func,

};

export default Container;