/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/30 11:00
 * @update 2019/01/20 03:46
 * @example Form W17F1000
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {FormGroup, Col, InputGroup,} from 'react-bootstrap'
import InputGroupAddon from "react-bootstrap/es/InputGroupAddon";
import {browserHistory} from "react-router";
import Config from "../../../config";

class UserProfile extends Component {

    onSearch = (e) => {
        this.props.setSearch(e.target.value);
    };

    render() {
        const {dataSource} = this.props;
        if (!dataSource) {
            return null;
        }
        return (
            <div id={'user-profile-component'}>
                <FormGroup id={'user-profile-title'}>
                    <Col xs={12} md={12} lg={12} sm={12}>
                        <label className="grid-title">{Config.lang("CRM_Nhan_vien_kinh_doanh")}</label>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col xs={8} md={8} lg={8} sm={8}>
                        <InputGroup style={{width: '100%'}}>
                            <input id={'search-user-profile'} placeholder={Config.lang("CRM_Nhap_noi_dung_tim_kiem")}
                                   onKeyUp={this.onSearch} type="text" className="form-control"/>
                            <InputGroupAddon id={'search-button-user-profile'}>
                                <i className="fas fa-search"/>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>

                </FormGroup>
                <div style={{overflowY: 'scroll', height: '70vh', overflowX: 'hidden'}}>
                    {
                        dataSource.map((value, index) => {
                            return (
                                <FormGroup key={index} className={'user-profile-content '} onClick={() => {
                                    browserHistory.push({
                                        pathname: Config.getRootPath() + 'W18F1031',
                                        state: {mode:"edit",UserID:encodeURIComponent(value.UserID),SalesPersonID:encodeURIComponent(value.SalesPersonID),Disabled:encodeURIComponent(value.Disabled)}
                                    });
                                }}>
                                    <Col className={'user-profile-detail'} xs={12} sm={12} lg={12} md={12}>

                                        <div className={'user-profile-image'}>
                                            <img src={value.Image ? value.Image : ''}
                                                 alt={""} />
                                        </div>
                                        <div className={'user-profile-image'}>
                                            <div><span className={'user-profile-name'}>{value.SalesPersonNameU}</span></div>

                                            {value.JobPositionU && <span className={'user-profile-extinfo'}>{value.JobPositionU}&nbsp;&nbsp; &nbsp;&nbsp;</span>}

                                            <div className={'user-profile-extinfo'}>
                                                {value.Email &&
                                                    <span>
                                                        <i className="fas fa-envelope"/> {value.Email}&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                }
                                                {value.MobileNo &&
                                                    <span> <i className="fas fa-phone"/> {value.MobileNo}</span>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </FormGroup>
                            )
                        })
                    }
                </div>

            </div>
        );
    }
}

UserProfile.propTypes = {

    dataSource: PropTypes.array, //Nguon

};


export default UserProfile;