/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/08/12 10:37
 * @update 2019/08/12 10:37
 * @file src/components/common/sort.js
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {DropDownBox} from 'devextreme-react';
import TreeList, { Column,Selection} from 'devextreme-react/tree-list';

class ComboTree  extends Component {
    constructor(props) {
        super(props);
        this.treeList = null;
        this.state = {
            gridBoxValue: this.props.value,
            gridSelectedRowKeys: [this.props.value]
        };

        this.treeView_itemSelectionChanged = this.treeView_itemSelectionChanged.bind(this);
        this.syncTreeViewSelection = this.syncTreeViewSelection.bind(this);
        this.treeViewRender = this.treeViewRender.bind(this);
        this.treeView_onContentReady = this.treeView_onContentReady.bind(this);
        this.clear_Value = this.clear_Value.bind(this);
    }

    render() {
        const {dataSource, valueExpr,value,placeholder,displayExpr,reset} = this.props;
        if (reset){
            this.clear_Value();
        }
        return (
            <div>
                        <DropDownBox
                            ref={(ref) => this.treeList = ref}
                            // className="input-devextreme"
                            value={!this.state.gridBoxValue ? value : this.state.gridBoxValue}
                            valueExpr={valueExpr}
                            displayExpr={displayExpr}
                            placeholder={placeholder}
                            showClearButton={false}
                            dataSource={dataSource}
                            onValueChanged={this.syncTreeViewSelection}
                            contentRender={this.treeViewRender}
                        >
            </DropDownBox>
            </div>
        );
    }

    treeViewRender() {
        const {dataSource,valueExpr, parentIdExpr,displayExpr,isMultiple} = this.props;
        let mode ='single';
        if(isMultiple===true)
            mode ='multiple';
        return (

            <TreeList
                id={'dataSource'}
                width={500}
                height={500}
                dataSource={dataSource}
                showRowLines={true}
                showBorders={true}
                columnAutoWidth={true}
                autoExpandAll={true}
                keyExpr={valueExpr}
                parentIdExpr={parentIdExpr}
                showColumnHeaders={false}
                selectedRowKeys={this.state.gridSelectedRowKeys}
                 onSelectionChanged={this.treeView_itemSelectionChanged}
            >
                <Selection mode={mode} />
                <Column
                    dataField={displayExpr} />
            </TreeList>
        )
    }



    syncTreeViewSelection(e) {
        // console.log('========syncDataGridSelection=======',e);
        this.setState({
            gridBoxValue: e.value,
            gridSelectedRowKeys: !e.value ? [] : [e.value]
        });
    }

    treeView_itemSelectionChanged(e) {
        const newValue = e.selectedRowKeys.length > 0 ? e.selectedRowKeys: null;
        if (newValue){
                this.setState({
                    gridBoxValue: newValue,
                    gridSelectedRowKeys: !newValue ? [] : newValue
                });

            // this.treeList.instance.close();
            // console.log("========treeView_itemSelectionChanged========",newValue);
            if (this.props.isMultiple===false)
            {

                if (this.props.onActionWhenSelectChange)
                    this.props.onActionWhenSelectChange(newValue[0]);
                this.treeList.instance.close();
            }else {
                if (this.props.onActionWhenSelectChange)
                    this.props.onActionWhenSelectChange(newValue);
            }
        }else {
            this.setState({
                gridBoxValue: '',
                gridSelectedRowKeys: []
            });
        }


    }

    treeView_onContentReady(e) {
        e.component.selectItem(this.state.gridBoxValue);
    }

    clear_Value(){
        if (this.treeList.instance)
            this.treeList.instance.reset();
    }

}

ComboTree.propTypes = {
    placeholder:PropTypes.string,
    value:PropTypes.string,
    isMultiple:PropTypes.bool,
    dataSource: PropTypes.any,
    parentIdExpr: PropTypes.string,
    displayExpr: PropTypes.string,
    valueExpr:PropTypes.string,
    reset:PropTypes.bool,
    onActionWhenSelectChange: PropTypes.func,
};

export default ComboTree;