/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as templateDropdownActions from "../../../actions/template-dropdown-actions";
import Combo from "./combo";
import {Column} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";

export class Container extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reset: false
        };
        this.dataGridRef = null;
        this.dataSource =
            new DataSource({
                key: "CompanyID",
                load: async (loadOptions) => {
                    const param = {
                        skip: loadOptions.skip,
                        limit: loadOptions.take,
                        SalesPersonID: this.props.salesPersonID,
                        GroupSalesID: this.props.groupSalesID
                    };
                    if (loadOptions.filter) {
                        param.search = loadOptions.filter.filterValue
                    }
                    const {rows, total} = await templateDropdownActions.getcboCustomer(param);
                    return {
                        data: rows ? rows : [],
                        totalCount: total ? total : 0
                    };
                },
                byKey: async (key) => {
                    const param = {
                        GroupSalesID: this.props.groupSalesID,
                        SalesPersonID: this.props.salesPersonID,
                        search: key
                    };
                    const {rows} = await templateDropdownActions.getcboCustomer(param);
                    return rows.find((value) => value.CompanyID === key)
                }
            });
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.salesPersonID !== this.props.salesPersonID || prevProps.groupSalesID !== this.props.groupSalesID) {
            this.setState({
                reset: true
            });
            this.dataGridRef && this.dataGridRef.instance.refresh();
        }
    };

    onChangeCustomer = (e) => {
        const {onChangeCustomer} = this.props;
        this.setState({
            reset: false
        });
        onChangeCustomer && onChangeCustomer(e);
    };

    render() {
        const {value, placeHolder} = this.props;
        const {reset} = this.state;

        return (
            <UI dataSource={this.dataSource}
                value={value}
                onChangeCustomer={this.onChangeCustomer}
                getDataGridRef={e => this.dataGridRef = e}
                placeHolder={placeHolder}
                reset={reset}
            />
        )
    }
}


const UI = React.memo((props) => {
    const {value, onChangeCustomer, dataSource, placeHolder, getDataGridRef, reset} = props;

    const _onValueChanged = (e) => {
        onChangeCustomer && onChangeCustomer(e)
    };

    return (
        <Combo
            showHeader={false}
            getDataGridRef={getDataGridRef}
            showClearButton={true}
            virtual={true}
            value={value}
            reset={reset}
            dataSource={dataSource}
            placeholder={placeHolder}
            valueExpr="CompanyID"
            displayExpr="CompanyNameU"
            onActionWhenSelectChange={_onValueChanged}
        >
            <Column dataField={'CompanyNameU'} dataType={'string'}/>
        </Combo>
    )
});

Container.propTypes = {
    salesPersonID: PropTypes.string,
    value: PropTypes.string,
    groupSalesID: PropTypes.string,
    placeHolder: PropTypes.string,
    showClearButton: PropTypes.bool,

    onChangeCustomer: PropTypes.func,
    onReachBottom: PropTypes.func,
};

export default connect(null,
    (dispatch) => ({
        action: bindActionCreators(templateDropdownActions, dispatch)
    })
)(Container);
