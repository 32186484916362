/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/02/26 19:08
 * @update 2019/02/26 19:08
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as templateDropdownActions from "../../../../actions/template-dropdown-actions";
import Combo from '../combo';
import {Column} from 'devextreme-react/data-grid';
import Config from "../../../../config";

import moment from "moment";
import 'moment/locale/vi';
import _ from 'lodash';
import { Object } from 'core-js';

moment.locale('vi', {
    week: {
        dow: 1
    }
});

class DropDownTypeTime extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // listType: '',
            ID: '',
            placeholder: '',

        };
        this.mode = 'init';
        this.dateFrom = '';
        this.dateTo = '';
        this.dataSource = [];
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.value && (nextProps.value !== state.ID)) {
            return {ID: nextProps.value}
        }
        return null;
    }

    // componentWillUpdate(nextProps, nextState) {
    //     if (this.props.listType !== nextProps.listType) {
    //         this.setState({
    //             listType: nextProps.listType
    //         },()=>{ this.loadData();});
    //
    //     }
    // }

    componentDidMount() {
        const { dataSource } = this.props;
        if (_.isEmpty(dataSource)) {
            this.loadData();
        } else{
            this.handleData(dataSource);
        }
    }

    loadData() {
        const sLanguage = Config.language || '84';
        const param = {
            "sLanguage": sLanguage,
            "listType": this.props.listType,
        };
        this.props.templateDropdownActions.cboTypeTime(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && data.length > 0) {
                this.handleData(data);
            }
        });
    }

    handleData = (data) => {
        let defaultTimeType = 0;
        const { isDefaultValue, handleDataSource, valueFromTo } = this.props;
        // Set dataSource
        this.dataSource = data.map(o => {
            return {
                ID: o.ID,
                Name: o.Name,
                IsDefault: o.IsDefault,
                DateFrom: Date.now(),
                DateTo: Date.now()
            };
        });
        if (handleDataSource) handleDataSource(this.dataSource);
        const defaultValue = data.filter((row) => {
            return row.IsDefault === 1;
        });
        if (defaultValue.length > 0 && isDefaultValue) {
            // const defaultID = ID !== undefined ? ID : defaultValue[0].ID;
            this.setState({
                ID: defaultValue[0].ID
            });
            defaultTimeType = defaultValue[0].ID;
        }
        this.onLoad(defaultTimeType);
        if(!_.isEmpty(valueFromTo)) {
            const status = Object.values(valueFromTo).some(item => Config.isEmpty(item, true));
            if(status && defaultValue) this.onChange(defaultValue[0]);
        }
    };

    getDateFromTo(ID) {
        switch (ID) {
            case 'TODAY':
                this.dateFrom = moment().startOf('day').toDate();
                this.dateTo = moment().endOf('day').toDate();
                break;
            case 'YESTERDAY':
                this.dateFrom = moment().add(-1, 'day').startOf('day').toDate();
                this.dateTo = moment().add(-1, 'day').endOf('day').toDate();
                break;
            case 'THISWEEK':
                this.dateFrom = moment().startOf('week').toDate();
                this.dateTo = moment().endOf('week').toDate();
                break;
            case 'LASTWEEK':
                this.dateFrom = moment().add(-1, 'week').startOf('week').toDate();
                this.dateTo = moment().add(-1, 'week').endOf('week').toDate();
                break;
            case 'THISMONTH':
                this.dateFrom = moment().startOf('month').toDate();
                this.dateTo = moment().endOf('month').toDate();
                break;
            case 'LASTMONTH':
                this.dateFrom = moment().add(-1, 'month').startOf('month').toDate();
                this.dateTo = moment().add(-1, 'month').endOf('month').toDate();
                break;
            case 'THISQUARTER':
                this.dateFrom = moment().quarter(moment().quarter()).startOf('quarter').toDate();
                this.dateTo = moment().quarter(moment().quarter()).endOf('quarter').toDate();
                break;
            case 'LASTQUARTER':
                this.dateFrom = moment().quarter(moment().quarter() - 1).startOf('quarter').toDate();
                this.dateTo = moment().quarter(moment().quarter() - 1).endOf('quarter').toDate();
                break;
            case 'THISYEAR':
                this.dateFrom = moment().startOf('year').toDate();
                this.dateTo = moment().endOf('year').toDate();
                break;
            case 'LASTYEAR':
                this.dateFrom = moment().add(-1, 'year').startOf('year').toDate();
                this.dateTo = moment().add(-1, 'year').endOf('year').toDate();
                break;
            case 'FIRSTSIXMONTHS':
                this.dateFrom = moment([moment().year(), 1 - 1, 1]).toDate();
                this.dateTo = moment(moment([moment().year(), 6 - 1, 1]).endOf('month')).toDate();
                break;
            case 'LASTSIXMONTHS':
                this.dateFrom = moment([moment().year(), 7 - 1, 1]).toDate();
                this.dateTo = moment(moment([moment().year(), 12 - 1, 1]).endOf('month')).toDate();
                break;
            default:
                this.dateFrom = moment().startOf('day').toDate();
                this.dateTo = moment().endOf('day').toDate();
                break;
        }
    }

    onLoad(ID) {
        if (ID) {
            this.getDateFromTo(ID);
            let data = {};
            data.DateFrom = this.dateFrom;
            data.DateTo = this.dateTo;
            data.ID = ID;

            if (ID === 0) {
                data.DateFrom = new Date(new Date().getFullYear(), 0, 1);
                data.DateTo = new Date(new Date().getFullYear(), 11, 31);
            }

            if (this.props.onLoad) {
                this.props.onLoad(data);
            }
        }
    }


    onChange(data) {
        this.getDateFromTo(data.ID);
        data.DateFrom = this.dateFrom;
        data.DateTo = this.dateTo;
        if (this.props.onChange)
            this.props.onChange(data, this.mode);
        if (this.mode === 'init') this.mode = 'change';
        this.setState({
            ID: data.ID
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.listType !== this.props.listType) this.loadData();
    }

    render() {
        const {showClearButton, disabled, placeholder} = this.props;

        return (
            <React.Fragment>
                <Combo
                    showHeader={false}
                    disabled={disabled}
                    showClearButton={showClearButton}
                    dataSource={this.dataSource}
                    placeholder={placeholder}
                    value={this.state.ID}
                    valueExpr="ID"
                    displayExpr="Name"
                    onActionWhenSelectChange={(data) => {
                        this.onChange(data)
                    }}
                >
                    <Column dataField={'ID'} caption="" width={'100px'} visible={false} dataType={'string'}/>
                    <Column dataField={'IsDefault'} caption="" visible={false} dataType={'string'}/>
                    <Column dataField={'Name'} caption="" width={'100%'} dataType={'string'}/>
                </Combo>
            </React.Fragment>
        );
    }
}

DropDownTypeTime.defaultProps = {
    isDefaultValue: true
};

DropDownTypeTime.propTypes = {
    showClearButton: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    listType: PropTypes.string,

    onChange: PropTypes.func,
    onLoad: PropTypes.func,
    isDefaultValue : PropTypes.bool,
    valueFromTo: PropTypes.object,
    dataSource: PropTypes.array,
    handleDataSource: PropTypes.func,
};

export default connect(state => ({
        cboTypeTime: state.templateDropdown.cboTypeTime
    }),
    (dispatch) => ({
        templateDropdownActions: bindActionCreators(templateDropdownActions, dispatch)
    })
)(DropDownTypeTime);