/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/04/15 21:10
 * @update 2019/04/15 21:10
 */
import checkStoreAction from "../actions/async/check-store-async";
import {loadMessages, locale} from 'devextreme/localization';
import * as Helpers from '../components/common/helpers';
import * as CryptoJS from "crypto-js";
import 'devextreme-intl';
import {browserHistory} from "react-router";
import _ from "lodash";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { DataSource } from "devextreme/data/data_source/data_source";

const env = require('./environment');

class Config {

    static env = env;
    static isMobile = isMobile;
    static token = {};
    static profile = {};
    static setting = [];
    static getToken = false;
    static popup = null;
    static debugger = null;
    static viewType = 0;
    static language = null;
    static perPage = 20;
    static database = '';
	static helpers = Helpers;
	static popupTransition = Helpers.popupTransitions.Slide;
    static permission = {
        'USER': 1,
        'ADMIN': 2,
        'SUPPORTER': 3,
        'BOT': 4
    };
    static localization = null;
    static notifyError = null;
    static encryptKey = "CRM@012345678#keyEncrypt";
    static deployer = process?.env?.REACT_APP_PASSWORD_DEPLOYER || '@12345@';
    static cdn = {
        URL: env.cdn,
        secret: env.secretCDN,
        token: {}
    };

    //////////////////////////
    //Use localStorage..
    static getKeyEnv = (key = "") => {
        const prefixLang = Config?.env?.prefixLang || "";
        let keyEnv = Config.env.keyEnv && Config.env.keyEnv !== "NO" ? Config.env.keyEnv : "";
        keyEnv = keyEnv.trim() + prefixLang.trim();
        return keyEnv.trim() + key;
    };
    static setLocalStorage = (key, value) => {
        if (!key) return false;
        key = Config?.getKeyEnv(key);
        try {
            localStorage.setItem(key, value);
        } catch (e) {
            return false;
        }
    };
    static getLocalStorage = (key, isJSONParse = false, isNewKey = false) => {
        if (!key) return null;
        const newKey = Config?.getKeyEnv(key);
        try {
            let item = localStorage.getItem(newKey);
            if (!isNewKey && !item) {
                item = localStorage.getItem(key);
            }
            if (this.isJson(item) && isJSONParse) item = JSON.parse(item);
            return item;
        } catch (e) {
            return null;
        }
    };
    static removeLocalStorage = (keys) => {
        if (!keys || keys.length <= 0) return false;
        const keyEnv = Config?.getKeyEnv();
        try {
            if (Array.isArray(keys)) {
                for (let key of keys) {
                    const k = keyEnv ? keyEnv.trim() + key : key;
                    localStorage.removeItem(k);
                }
            } else {
                keys = keyEnv ? keyEnv.trim() + keys : keys;
                localStorage.removeItem(keys);
            }
            return true;
        } catch (e) {
            return false;
        }
    };
    //Use localStorage..
    ////////////////////////////////////////

    /**
     * Get system setting
     * Example:  getSetting('DB_CRM_NAME') => 'DRD02V41'
     * @param name
     * @returns {*}
     */
    static getSetting = (name) => {
        if (Config.setting) {
            let setting = Config.setting.filter(s => s.name === name);

            if (setting && setting[0] && setting[0].name) {
                switch (setting[0].type) {
                    case "NUMBER":
                        return parseFloat(setting[0].value);
                    case "JSON":
                        return JSON.parse(setting[0].value);
                    default:
                        return setting[0].value;
                }
            }
        }

        return null;
    };

    static logout = () => {
        Config.removeLocalStorage('TOKEN');
        Config.removeLocalStorage('PROFILE');
        Config.removeLocalStorage('ADMIN');
        Config.removeLocalStorage('SETTING');
        Config.removeLocalStorage('AUTHPRODUCT');

        window.location=Config.getRootPath();
        setTimeout(()=>{
            window.location.reload();
        },200)
    };

    /**
     * Replace special symbol,using for control as textbox Code ,ID .v.v.
     * Ex: replaceSymbol('*Khoái') => 'Khoai'
     * @param value
     * @returns {string}
     */
    static replaceSymbol = (value) => {
        // let str = str.replace(/[|?~=",{}[\];^%']/gi, '');
        let str = value.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        str = str.replace(/\s/g, '');
        str = str.replace(/[^0-9a-z_\-#*/(\\)]/gi, '');

        return str.toUpperCase();
    };

    /**
     * Replace Vietnamese .v.v.
     * Ex: replaceSymbol('*Khoái') => 'Khoai'
     * @param value
     * @returns {string}
     */
    static replaceES = (value) => {
        // let str = str.replace(/[|?~=",{}[\];^%']/gi, '');
        let str = value.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        str = str.replace(/[^0-9a-z_\-\s#*./(\\)]/gi, '');

        return str;
    };

    /**
     * Replace Vietnamese for Telephone .v.v.
     * Ex: replaceSymbol('0123456789 (Mr Hiệp)') => '0123456789 (Mr Hiep)'
     * @param value
     * @returns {string}
     */
    static replaceSymbolTE = (value) => {
        // let str = str.replace(/[|?~=",{}[\];^%']/gi, '');
        let str = value.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        // str = str.replace(/[^0-9a-z_\-#*./(\\)]/gi, '');

        return str;
    };

    /**
     * Replace Vietnamese for Telephone .v.v.
     * Ex: replaceSymbol('0123456789 (Mr Hiệp)') => '0123456789 (Mr Hiep)'
     * @param value
     * @returns {string}
     */
    static replaceSymbolTEPhone = (value) => {
        // let str = str.replace(/[|?~=",{}[\];^%']/gi, '');
        let str = value.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        str = str.replace(/[^0-9a-z_\-+/(\\)/ /]/gi, '');

        return str;
    };

    /**
     * Convert date by special format
     * @param inputFormat
     * @param time
     * @returns {string}
     */
    static convertDate = (inputFormat, time) => {
        function pad(s) {
            return (s < 10) ? '0' + s : s;
        }

        let d = new Date(inputFormat);
        let date = [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
        if (time === true) {
            date += " " + [pad(d.getHours()), pad(d.getMinutes())].join(':');
        }
        return date;
    };

    /**
     * Parse date with moment timezone..
     * Parse date to timezone guess..
     * @param value
     * @param defaultValue
     * @param format
     */
    static parseDate = (value, defaultValue = null, format = "", isUTC = true, inputFormat) => {
        if(!value){
            return defaultValue ?? null;
        }
        else if (!moment(value, inputFormat).isValid()) {
            Config.popup.show("ERROR", `Invalid date is Format: ${value}`)
            return defaultValue ?? null;
        }
        if (isUTC) {
            if (format) return moment.utc(value, inputFormat).format(format);
            else return moment(value, inputFormat).toISOString();
        } else {
            return moment(value, inputFormat).format(format);
        }
        // if (!value || !moment(value).isValid()) return defaultValue;
        // const defaultTimeZone = "Asia/Ho_Chi_Minh";
        // let date = moment.tz(value, defaultTimeZone).format("YYYY-MM-DD HH:mm:ss");
        // date = moment.tz(date, moment.tz.guess()).utc().format(format);
        // return date || defaultValue;
    };

    /**
     * Using translate resource of project (caption,title,description,label,text.v.v.)
     * Example : lang(Dang_nhap) => vi:Đăng nhập  || en :Log In
     * @param text
     * @returns {*}
     */
    static getKeyLocalize = (text) => {
        if (!Config.localization || !text) return "";
        const prefix = this.env.prefixLang || "CRM_";
        if (text && text.includes(prefix)) text = text.replace(prefix, '');
        return Object.keys(Config.localization).find(l => {
            if (l && l.includes(prefix)) l = l.replace(prefix, '');
            return l.toLowerCase() === text.toLowerCase();
        });
    };
    static lang = (text, mode) => {
        let str;
        if (Config.localization !== null) {
            let keyTmp = text;
            if (text && text.match(/%(.*?)%/g)) {
                keyTmp = text.replace(/%(.*?)%/g, "%p"); //define key param, require is %p%.
            }
            let key = this.getKeyLocalize(keyTmp);
            if(mode){
                const lc =Config.getLocalStorage('LOCALIZE');
                const lang = JSON.parse(lc);
                str = lang[mode][key];

            } else {
                // str = Config.localization[text] ? Config.localization[text] : Config.localization[text1];
                str = Config.localization[key];
            }
            if (typeof str !== "undefined") {
                const match = text.match(/%(.*?)%/i);
                const match2 = text.match(/%(.*?)%/g);
                if (match) {
                    match2.forEach(m => {
                        m = m.slice(1, -1);
                        str = str.replace("%p", m);
                    });
                }
                return str;
            }
        }
        return text;
    };

    static CompanyID = () => {
        return Config.getSetting('DB_CRM_NAME');
    };

    static randomSRC (width, height){
        // let linkImage = (Math.random()*1000) + 1;
        const widthImage = width ? width : 320;
        const heightImage = height ? height : 320;
        // return "https://picsum.photos/id/"+Math.floor(linkImage)+"/"+widthImage+"/"+heightImage;
        return "https://loremflickr.com/"+widthImage+"/"+heightImage;
    };

    /**
     * Get result from store procedure and info to user via 'Status',
     * Status =0 : allow
     * Status =1 : deny => (MsgAsk =0 : info error  , MsgAsk =1 : info confirm(Y/N))
     * @param store
     * @param cb
     * @returns {Promise.<void>}
     */
    static async checkStore(store, cb) {
        const result = await checkStoreAction(store);
        let message = "";
        if (result !== 0 && result.length > 0) {
            const data = result[0];
            if (data !== null) {
                let status = data.Status;
                let msgAsk = data.MsgAsk;
                message = data.Message;
                if (status === 0) {
                    cb && cb();
                } else {
                    if (msgAsk === 0) {
                        Config.popup.show('INFO', message);
                    } else {
                        Config.popup.show("YES_NO", message,
                            () => {
                                cb && cb();
                            }
                        );
                    }
                }
            } else {
                Config.popup.show('INFO', Config.lang("CRM_Store_kiem_tra_bi_loi"));
            }

        } else {
            message = Config.lang("CRM_Loi_chua_xac_dinh");
            Config.popup.show('INFO', message);
        }
    }

    static isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    /**
     * Set language devextreme component
     * @returns {void}
     */
    static setLangDevextreme() {
        const lang = Config.getLocalStorage('langCRM');
        let dataLang = Config.getLocalStorage('LOADDEVEXTREME');
        if(dataLang && this.isJson(dataLang)){
            dataLang = JSON.parse(dataLang);
            loadMessages(dataLang);
            locale(lang);
        }
        if (lang === 'vi' && window.dxMessage) {
            Config.setLocalStorage('LOADDEVEXTREME', JSON.stringify(window.dxMessage));
            loadMessages(window.dxMessage);
            locale('vi');
        }
        else if(lang === 'en'){
            locale('en');
        }
    }

    static getUser = (params) => {
        // paramsDef = {
        //     UserID: "14124",
        //     SalesPersonNameU: "Bích Khanh",
        //     SalesPersonID: "#",
        // };
        let item = null;
        let list = Config.decryptData(Config.getLocalStorage('DATAUSERLIST'));
        list =  list && Config.isJson(list) ? JSON.parse(list) : null;
        if(list && list.length > 0){
            list.forEach(i=>{
                let flag = true;
                Object.keys(params).forEach((key)=>{
                    if(i[key] !== params[key]) flag = false;
                });
                if(flag){
                    item = flag ? i : item;
                    return true;
                }

            });
        }
        return item;
    };

    static getRootPath() {
        return (process && process.env && process.env.REACT_APP_ROOT) ? process.env.REACT_APP_ROOT : '/';
    }

    /**
     * Save state to location with browserHistory.
     * @param component
     * @param param = {}
     */
    static setStoreState(component, param) {
        // console.log('component', component);
        const route = component.props.route.path;

        browserHistory.push({
            pathname: Config.getRootPath() + route,
            state: param
        });
    }

    /**
     * Get state from location and set state of component with browserHistory.
     * @param component
     * @param param = {}
     * @returns null
     */
    static getStoreState(component, cb) {
        const route = component.props.route.path;
        const data = component.props.location && component.props.location.state ? component.props.location.state : null;
        const state = {...data, storeFilter: true};

        if (data) {
            component.setState(state, () => {
                cb && cb();
            });
            browserHistory.push({
                pathname: Config.getRootPath() + route,
            });
        }
        return data;
    }

    /**
     * Trigger form control with rules,
     * validation_rules = [
 *          {
                name: 'txtCaseName',
                title: Config.lang("CRM_Ten_tinh_huong"),
                rules: 'required'
            }
     * ]
     * @param component (this)
     * @param validation_rules
     * @returns bool (trigger focus form control)
     */
    static checkValidationWithRefs(component, validation_rules) {
        const _ = component;
        let isFail = false;
        for (const value of validation_rules) {
            const name = value.name;
            const title = value.title;
            const rules = value.rules.split(';');
            for (const rule of rules) {
                const param = rule.split(':');
                switch (param[0]) {
                    case "required":
                        if (_.refs[name].value.trim() === '') {
                            const message = '"' + title + '" ' + Config.lang("CRM_Bat_buoc_nhap");
                            Config.popup.show('INFO', message, null, null, () => {
                                setTimeout(() => {
                                    _.refs[name].focus();
                                }, 300);
                            });
                            isFail = true;
                            return false;
                        }
                        break;
                    case "length":
                        if (param[1] && Number.isInteger(Number(param[1]))) {
                            if (_.refs[name].props.value.length > param[1]) {
                                const message = '"' + title + '" ' + Config.lang("CRM_Kich_thuoc_vuot_qua_gioi_han_cho_phep");
                                Config.popup.show('INFO', message, null, null, () => {
                                    setTimeout(() => {
                                        _.refs[name].focus();
                                    }, 300);
                                });
                                isFail = true;
                                return false;
                            }
                        }
                        break;
                    default:

                        break;
                }
            }
            if (isFail) return false;
        }
        if (isFail) return false;
        return true;
    }

    static encryptData = (data, encryptKey) => {
        if (!data || typeof data !== "string") return false;
        try {
            encryptKey = encryptKey ? encryptKey : this.encryptKey;
            return CryptoJS.AES.encrypt(data, encryptKey).toString();
        } catch (e) {
            console.log("encrypt error", e);
            return false;
        }
    };

    static decryptData = (data, encryptKey, stringDecode = CryptoJS.enc.Utf8) => {
        if (!data) return null;
        try {
            encryptKey = encryptKey ? encryptKey : this.encryptKey;
            return CryptoJS.AES.decrypt(data, encryptKey).toString(stringDecode);
        } catch (e) {
            console.log("decrypt error", e);
            return null;
        }
    };

    static isValidDateTime = (dateValue) => {
        let result = false;
        if (_.isString(dateValue) && !_.isEmpty(dateValue)) {
            let regexDate = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
            //regexDate Bao gồm các ngày như sau https://regexr.com/39tr1
            let utcRegex = /^[0-9]{4}-((0[1-9]|1[0-2])){1}-(0[1-9]|1[0-9])T(23:59:60|(([01]([0-9])|(2[0-3])){1}:?([0-5][0-9])){1}(:?([0-5][0-9])(.([0-9]+))?))?(Z|(\+|-)([01]([0-9])|(2[0-4])){1}((:)?([0-5][0-9]){1})?){1}$/;
            //utcRegex Bao gồm các ngày như sau https://www.regextester.com/95191
            if (regexDate.test(dateValue)) {
                result = regexDate.test(dateValue.trim());
            }
            if (utcRegex.test(dateValue)) {
                result = utcRegex.test(dateValue.trim());
            }
            if (!result && (dateValue.split("/").length === 3 || dateValue.split("-").length === 3)) {
                //Các trường hợp còn lại
                const value = dateValue.length > 10 ? dateValue.slice(0, 10).trim() : dateValue;
                switch (true) {
                    case moment(value, 'YYYY-MM-DD', true).isValid():
                    case moment(value, 'YYYY-DD-MM', true).isValid():
                    case moment(value, 'YYYY/DD/MM', true).isValid():
                    case moment(value, 'YYYY/MM/DD', true).isValid():
                    case moment(value, 'MM/DD/YYYY', true).isValid():
                    case moment(value, 'MM-DD-YYYY', true).isValid():
                    case moment(value, moment.ISO_8601, true).isValid():
                        {
                            result = true;
                            break;
                        }
                    default:
                        break;
                }
            }
        }
        return result;
    }

    static isEmpty(value, escapeZero = false) {
        switch (typeof value) {
            case "object": {
                if (Array.isArray(value))
                    return value.length <= 0;
                else
                    return (value && Object.keys(value).length <= 0) || !value;
            }
            case "string": {
                return !value;
            }
            case "number": {
                if (!escapeZero)
                    return !value;
                else
                    return value === 0 ? false : !value;
            }
            default: {
                return !value;
            }
        }
    }

    static storeDataSoureDevExtreme(dataSource, limit) {
        if (dataSource && dataSource.rows) dataSource = dataSource.rows;
        if (!dataSource || dataSource.length <= 0) return [];
        return new DataSource({
            store: dataSource,
            paginate: true,
            pageSize: limit ? limit : 10
        });
    }

    static getHeightGrid = (parentHeight) => {
        const _header = document.querySelector(".header-container");
        const _gridTitle = document.querySelector(".grid-title");
        const _toolbars = document.querySelectorAll(".toolbar-container");
        const _paging = document.querySelector(".paging-crm");
        const _pHeight = parentHeight || window.innerHeight;
        let height = 20; //margin
        height += _header?.offsetHeight || 0;
        height += _gridTitle?.offsetHeight || 0;
        height += _paging?.offsetHeight || 0;
        for (let el of _toolbars) {
            height += el.offsetHeight || 0;
        }

        return _pHeight - height;
    };

    static getCDNPath(args) {
        //case 1: get CDN path with slash
        if (typeof args === "boolean") return this.cdn.URL + (args ? "/" : "");

        //case 2: get URL with CDN
        if (args && typeof args === "string") {
            if (args.includes("://")) return args;
            if (args.startsWith("/")) args = args.substring(1);
            return `${this.cdn.URL}/${args}`;
        }
        return `${this.cdn.URL}/`;
    };

}

export default Config;
