/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/15 10:18
 * @update 2019/02/15 10:18
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";

class ButtonFilter extends Component {

    onClick() {
        if(this.props.onClick) this.props.onClick();
    }

    render() {
        const {name} = this.props;
        return (
            <ButtonBasic onClick={()=>this.onClick()}>
                {name} {name&&'\u00A0'} <i className="fas fa-caret-down"/>
            </ButtonBasic>
        );
    }
}

ButtonFilter.propTypes = {
    name: PropTypes.string,

    onClick: PropTypes.func,
};

export default ButtonFilter;
