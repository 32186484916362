/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/23 10:37
 * @update 2019/01/23 10:37
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TitleGrid extends Component {
    render() {
        let {label} = this.props;

        if (!label) return;

        return (
            <div className="grid-title">
                {label}
            </div>
        );
    }
}

TitleGrid.propTypes = {
    label: PropTypes.string
};

export default TitleGrid;