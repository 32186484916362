/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/30 11:00
 * @update 2019/01/20 03:46
 * @example Form W17F1000
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {Col, FormGroup} from 'react-bootstrap'
import {NumberBox} from "devextreme-react";

import Config from "../../../config";
import ButtonSave from "../button/button-save";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as w17f0010Actions from "../../../actions/w17f0010-actions";
import notify from "devextreme/ui/notify";

class CustomerSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numDateChange: 0,
            condition: ''
        };
    }

    onSave() {
        const paramCon = {
            TransTypeID: 'WarningSaleFail',
            TransFieldID: 'ConditionID',
            StringValue: this.state.condition,
        }
        this.props.w17f0010Actions.saveCustomerSetting(paramCon, (error, data) => {
            if (error) {
                Config.popup.show('INFO', `${Config.lang("CRM_Co_loi_khi_luu")}`);
                return false;
            }
            const paramNum = {
                TransTypeID: 'WarningSaleFail',
                TransFieldID: 'NumDateChange',
                NumValue: this.state.numDateChange,
            }
            this.props.w17f0010Actions.saveCustomerSetting(paramNum, (error, data) => {
                if (error) {
                    Config.popup.show('INFO', `${Config.lang("CRM_Co_loi_khi_luu")}`);
                    return false;
                }
                notify(`${Config.lang("CRM_Luu_thanh_cong")}`, "success", 2000);

            })
        })
    }

    loadCboCondition() {
        const param = {
            select: 'ConditionID,ConditionName'
        }
        this.props.w17f0010Actions.dataCondition(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    case "T1009E004":
                        //Du lieu rong
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                return false;
            }
            if(data){
                if(data.length>0){
                    this.loadCondition();

                }
                this.loadNumDateChange()
            }



        })
    }

    loadCondition() {
        const param = {
            TransFieldID: 'ConditionID',
            TransTypeID: 'WarningSaleFail',

        }
        this.props.w17f0010Actions.loadCustomerSetting(param, 'condition', (error, data) => {
            //console.log("loadFieldRequiredW17F0010", data);
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    case "T1009E004":
                        //Du lieu rong
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }

            if (data) {
                this.setState({
                    condition: data.StringValue
                })
            }

        });
    }

    loadNumDateChange() {
        const param = {
            TransFieldID: 'NumDateChange',
            TransTypeID: 'WarningSaleFail'
        }
        this.props.w17f0010Actions.loadCustomerSetting(param, 'numDateChange', (error, data) => {
            //console.log("loadFieldRequiredW17F0010", data);
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    case "T1009E004":
                        //Du lieu rong
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
            if (data) {
                this.setState({
                    numDateChange: data.NumValue
                })
            }
        });
    }


    componentDidMount() {
        this.loadCboCondition();

    }


    render() {
        const {iPer,dataCondition} = this.props;
        return (
            <div className={'page-requiredField'}>
                <FormGroup>
                    <div className="grid-title title-field"><label>{Config.lang("CRM_Thiet_lap_khach_hang_chung")}</label>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                        <label className={'control-label'}>{Config.lang("CRM_Dieu_kien")}</label>
                    </Col>
                    <Col xs={10} md={10} lg={10} sm={10}>
                        <select value={this.state.condition} onChange={(e) => {
                            this.setState({
                                condition: e.target.value
                            })
                        }} className={'form-control'}>
                            <option value={''}/>
                            {dataCondition&&dataCondition.map((value,index)=>{
                                return (
                                    <option key={index} value={value.ConditionID}>{value.ConditionName}</option>
                            
                                )
                            })}

                        </select>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                        <label className={'control-label'}>{Config.lang("CRM_Sau_so_ngay")}</label>
                    </Col>
                    <Col xs={4} md={4} lg={4} sm={4}>
                        <NumberBox
                            value={this.state.numDateChange}
                            mode={"number"}
                            onValueChanged={(e) => {
                                this.setState({
                                    numDateChange: !e.value?0:e.value
                                })
                            }}
                        />
                    </Col>
                </FormGroup>

                <FormGroup>
                    <Col xs={12} md={12} lg={12} sm={12}>
                        <ButtonSave disabled={!iPer || iPer < 3} onClick={() => this.onSave()} className={"pull-right"}
                                    name={Config.lang("CRM_Luu")}/>
                    </Col>
                </FormGroup>
            </div>

        );
    }
}

CustomerSetting.propTypes = {
    iPer: PropTypes.number,
};

export default connect(state => ({
        numDateChange: state.w17f0010.numDateChange,
        condition: state.w17f0010.condition,
        dataCondition: state.w17f0010.dataCondition,
    }),
    (dispatch) => ({
        w17f0010Actions: bindActionCreators(w17f0010Actions, dispatch)
    })
)(CustomerSetting);
