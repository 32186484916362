/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 20/0/2019
 */

import React from 'react'
import PropTypes from 'prop-types';
import EventTracking from "../tracking/event";

export class PageContainer extends React.PureComponent {
    hidePopover = () => {
        const event = ['resize'];
        const popOver = document.getElementsByClassName('popover');

        event.forEach(function (e) {
            window.addEventListener(e, () => {
                if (popOver.length > 0) {
                    document.body.click()
                }
            });
        });
    };

    componentDidMount() {
        this.hidePopover();
    }

    componentWillUnmount() {
        // window.removeEventListener('resize');
    }

    render() {
        const {tracking, id, title, children, ...props} = this.props;
        return (
            <div {...props} id={id} className="page-container">
                {title && <div className="grid-title">{title}</div>}
                <EventTracking action={"FORM"} label={tracking}/>
                {children}
            </div>
        )
    }
}

PageContainer.propTypes = {
    tracking: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
}
export default PageContainer