/**
 * @copyright 2018 @ C.O.I
 * @author rocachien
 * @create 2017/06/30 15:34
 * @update 2017/06/30 15:34
 * @file src/components/common/course/course.js
 */

import React, {PureComponent, memo} from 'react';
import PropTypes from 'prop-types';

import {bindActionCreators} from 'redux'
import Combo from "../combo";
import {connect} from 'react-redux'
import * as action from "../../../../actions/template-dropdown-actions";
import {Column} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";

const dataSource = {
    store: new CustomStore({
        key: "ObjectID",
        load: async (loadOptions) => {
            const param = {
                "skip": loadOptions.skip,
                "limit": loadOptions.take,
            };
            if (loadOptions.filter) {
                // param.where = "Disabled=0 And CurrencyID LIKE '%" + loadOptions.filter.filterValue + "%'";
                param.where = JSON.stringify(
                    {
                        fieldName: 'ObjectName',
                        operator: 'LIKE',
                        value: `${loadOptions.filter.filterValue}`
                    },
                );
            }
            const data = await action.getCboObject(param)

            const result = {
                'data': data.rows,
                'totalCount': data.total
            };
            return result;
        }
        ,
        byKey: async (key) => {
            // const param = {
            //     "where": "Disabled=0 And CurrencyID='" + key + "'"
            // };
            const param = {
                where: JSON.stringify(
                    {
                        fieldName: 'ObjectID',
                        operator: 'LIKE',
                        value: key
                    },
                )
            };
            const data = await action.getCboObject(param)
            let result;
            if (data.rows.length > 0) result = data.rows[0];
            return result;
        },
    })
};

export class Container extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            value: null,
        }
    }

    // getDataSource = () => {
    //     const param = {
    //         SalesOfficeID: this.props.salesOfficeID
    //     };
    //     this.props.action.getCboDepartment(param, (error, data) => {
    //         this.setState({
    //             dataSource: data
    //         })
    //     })
    // };

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (prevProps.salesOfficeID !== this.props.salesOfficeID) this.getDataSource();
    // }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value && nextProps.value !== prevState.value) {
            return {
                value: ''
            }
        }
        return null
    }

    _onChange = (selectedData, e) => {
        const {onChange, value} = this.props;
        onChange && onChange(selectedData, e)
        if (!value) {
            this.setState({
                value: selectedData
            })
        }
    };

    render() {
        const {showClearButton} = this.props;
        const value = this.state.value ? this.state.value : this.props.value;

        return <Ui onChange={this._onChange}
                   value={value}
                   showClearButton={showClearButton}/>
    }
}

const Ui = memo((props) => {
    const {onChange, showClearButton, value} = props;

    const _onChange = (selectedData, e) => {
        onChange(selectedData, e)
    };
    return (
        <Combo
            valueExpr={'ObjectID'}
            value={value}
            virtual={true}
            displayExpr={'ObjectName'}
            onActionWhenSelectChange={_onChange}
            showClearButton={showClearButton}
            dataSource={dataSource}>
            <Column dataField={'ObjectName'} dataType={'string'}/>
        </Combo>
    )
});


Container.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    data: PropTypes.array,
    showClearButton: PropTypes.bool,
    onChange: PropTypes.func,
    salesOfficeID: PropTypes.string
};

export default connect(
    state => ({dataSource: state.templateDropdown.cboArea}),
    dispatch => ({
        action: bindActionCreators(action, dispatch)
    }))(Container);