import React, {memo} from 'react'
import PopupView from "../../popup/popup-view";
import {Col, Form, FormGroup} from "react-bootstrap";
import PageContainer from "../page-container/page-container";
import ListOption from './list-option-search'
import DetailSearch from './detail-search'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as action from "../../../actions/advanced-search";
import Config from "../../../config";
import notify from "devextreme/ui/notify";

class Container extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isToggle: false,
            listFilterDetail: null, // chứa datasoure danh sách bộ lọc
            listFilter: null, // danh sách bộ lọc
            dynamicState: {},// chứa value của các combo danh sách bộ lọc
            switchButton: false,
            disabledForm: false,
            selectedListFilter: null,
            isPublic: false,
            filter: {
                filterID: '',
                filterName: ''// chỉ sử dụng khi thêm mới
            }
        };
        this.isValid = {}; //
        this.refTextBox = null;
        this.refDetail = null;
        this.mode = 'add';
    }

    //----------------------METHOD----------------------
    getParamData = () => {
        // Trả về mảng Object FieldID, ValueFrom, ValueTo của combo động không bao gồm ispublic
        const entries = Object.entries(this.state.dynamicState);
        const dataParam = [];

        for (let i = 0; i < entries.length; i++) {
            if (entries[i][0].search('From') === -1 && entries[i][0].search('To') === -1) {
                let data = {
                    FieldID: entries[i][0],
                    ValueFrom: entries[i][1],
                    ValueTo: ''
                };
                dataParam.push(data)
            } else {
                if (entries[i][0].search('From') > 0) {
                    let data = {
                        FieldID: entries[i][0].split('From')[0],
                        ValueFrom: entries[i][1]
                    };
                    dataParam.push(data)
                } else {
                    dataParam[i - 1].ValueTo = entries[i][1];
                }
            }
        }
        return dataParam;
    };

    getDefaultFilter = () => {
        this.props.action.getDefaultFilter((error, result) => {
            const {data} = result;
            const dynamicState = {};
            data && data.forEach((value) => {
                dynamicState[value.FieldID] = '';
            });
            this.setState({
                listFilterDetail: result.data,
                dynamicState: dynamicState
            })
        })
    };

    onSearch = () => {
        const dataParam = this.getParamData();
        const {formID} = this.props;
        const param = {
            data: JSON.stringify(dataParam),
            FormID: formID
        };
        this.mode = 'search';
        this.dataProcess(param);
    };

    hidePop = () => {
        const {onHide} = this.props;
        onHide && onHide();
    };

    onToggle = () => {
        this.setState({
            isToggle: !this.state.isToggle
        })
    };

    clearDynamicState = () => {
        //return dynamicState value =''
        const entries = Object.entries(this.state.dynamicState);
        const dynamicState = {};
        entries.forEach((value) => {
            dynamicState[value[0]] = "";
        });
        return dynamicState;
    };

    onAdd = () => {
        const {listFilter} = this.state;
        const arr = [{}, ...listFilter];
        const param = {
            listFilter: arr,
            filter: {
                filterID: '',
                filterName: ''
            },
            switchButton: true,
            disabledForm: false,
            isToggle: true,
        };
        if (this.mode !== 'add') {
            const dynamicState = this.clearDynamicState();
            if (Object.keys(dynamicState).length) {
                param.dynamicState = dynamicState;
            }

        }
        if (this.mode === 'view') this.getDefaultFilter();
        this.mode = 'add';
        this.setState(param, () => {
            // const list = document.getElementsByClassName('list-group-item');
            document.getElementById('list-option-search').scrollTop = 0
        })
    };

    onChangeDynamic = (e) => {
        this.setState({
            dynamicState: {
                ...this.state.dynamicState, ...e
            }
        })
    };

    setDataDetail = (e, mode) => {
        if ((this.mode === 'edit' && this.state.switchButton) || this.haveUnSaveData()) return;
        const state = {
            filter: {
                filterID: e.FilterID,
                filterName: e.FilterName
            },
            disabledForm: mode !== 'edit',
            switchButton: mode === 'edit',
        };
        this.mode = mode ? mode : 'view';
        action.getFilterDetail({FilterID: e.FilterID}, (error, data) => {
            if (error) return false;
            state.dynamicState = {};
            state.IsPublic = data.IsPublic;
            data.rows && data.rows.forEach((value) => {
                if (value.ControlType === 1) {
                    state.dynamicState[`${value.FieldID}From`] = value.ValueFrom;
                    state.dynamicState[`${value.FieldID}To`] = value.ValueTo
                } else {
                    state.dynamicState[`${value.FieldID}`] = value.ValueFrom;
                }
            });
            state.listFilterDetail = data.rows;
            this.setState(state)
        })
    };

    onGetRefTextBox = (e) => {
        this.refTextBox = e;
    };

    onGetRefDetail = (e) => {
        this.refDetail = e;
    };

    onAddListFilter = (e) => {
        this.isValid.title = !!e.value;
        this.setState({
            filter: {
                filterID: '',
                filterName: e.value ? e.value : ''
            }
        })
    };


    getFilter = () => {
        const param = {
            FormID: this.props.formID
        };

        this.props.action.getFilter(param, (error, result) => {
            if (error) {
                Config.popup.show('INFO', Config.lang('CRM_Co_loi_trong_qua_trinh_xu_ly_du_lieu'));
                return false;
            }
            const {data} = result;
            this.setState({
                listFilter: data
            })
        })
    };

    onSave = () => {
        const {filter, isToggle} = this.state;
        if (this.mode === 'add' && !filter.filterName) {
            if (!isToggle) {
                this.setState({
                    isToggle: true
                }, () => {
                    this.onCheckValid();
                });
            } else {
                this.onCheckValid();
            }
        }
        const entries = Object.values(this.isValid);
        const {formID} = this.props;
        let i = 0;
        for (i; i < entries.length; i++) {
            if (!entries[i]) return;
        }
        const dataParam = this.getParamData();
        const param = {
            data: JSON.stringify(dataParam),
            IsPublic: this.state.isPublic ? 1 : 0,
            FormID: formID
        };
        param.data = JSON.stringify(dataParam);
        param.FilterName = filter.filterName;
        if (this.mode !== 'add') param.FilterID = filter.filterID;
        this.dataProcess(param)
    };

    dataProcess = (param, cb) => {
        const {mode} = this;
        const {onSearch} = this.props;
        action.dataProcess(param, mode, (error, result) => {
            if (error) {
                if (this.mode !== 'del') {
                    Config.popup.show("INFO", Config.lang('CRM_Co_loi_khi_luu'));
                    if (this.mode === 'search') onSearch && onSearch(false);
                    return false;
                }
                Config.popup.show("INFO", Config.lang('CRM_Co_loi_khi_xoa'));
            }
            const {data} = result;
            if (mode !== 'search') {
                this.getFilter();
                const state = {
                    switchButton: false,
                    disabledForm: mode !== 'del',

                };
                //Khi add thì filter ID bằng api mới sinh ra delete thì xóa
                if (this.mode === 'delete' || this.mode === 'add') {
                    state.filter = {
                        filterID: mode === 'add' ? data.FilterID : '',
                        filterName: '',
                    }
                }
                this.setState(state);
                if (this.mode === 'del') {
                    this.getDefaultFilter();
                    this.mode = 'add'; // sau khi xóa bật về mode add
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                } else {
                    this.mode = 'view';// sau khi edit hoặc thêm mới về mode view
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                }
                document.getElementById('list-option-search').scrollTop = 0
            } else {
                onSearch && onSearch(true);
            }
            cb && cb();
        })
    };

    onCheckValid = () => {
        const {instance} = this.refTextBox;
        const {filter} = this.state;
        if (!filter.filterName) {
            instance.option('isValid', false);
            instance.option('value', null);
        } else {
            instance.option('isValid', true);
        }
        if (!instance.option('isValid')) {
            instance.focus();
        }
    };

    onValidateDate = (e, compareValue, mode, name) => {
        e.rule.message = '';
        if (!e.value && !compareValue) {
            this.isValid[name] = true;
            return true
        } else {
            this.isValid[name] = true;
            if (!e.value) {
                e.rule.message = Config.lang('CRM_Khong_duoc_de_trong');
                this.isValid[name] = false;
                return false;
            }
            if (mode === 1) {
                e.rule.message = Config.lang('CRM_Khong_duoc_lon_hon_ngay_den');
                this.isValid[name] = e.value <= compareValue;
                return e.value <= compareValue;
            } else {
                e.rule.message = Config.lang('CRM_Khong_duoc_nho_hon_ngay_tu');
                this.isValid[name] = e.value >= compareValue;
                return e.value >= compareValue;
            }
        }
    };

    onDeleteDetail = (e) => {
        const listFilterDetail = this.state.listFilterDetail.filter((value) => value.FieldID !== e.FieldID);
        const dynamicState = {...this.state.dynamicState};
        Object.keys((dynamicState)).forEach((value) => {
            if (`${e.FieldID}From` === value || `${e.FieldID}To` === value) {
                delete dynamicState[value]
            }
            if (e.FieldID === value) delete dynamicState[value]
        });
        this.setState({
            listFilterDetail: listFilterDetail,
            dynamicState: dynamicState
        });
        delete this.isValid[e];
    };

    onNotSave = () => {
        if (this.mode === 'edit') {
            this.mode = 'view';
            this.setDataDetail({FilterID: this.state.filter.filterID});
            return false;
        }
        this.getFilter();
        const param = {
            switchButton: false,
            disabledForm: false,
            filter: {
                filterID: '',
                filterName: ''
            },
            isPublic: false,
            // listDefaultFilter: this.props.listDefaultFilter,// sửa thành reducer và mở rem
        };
        const dynamicState = this.clearDynamicState();
        if (Object.keys(dynamicState).length) {
            param.dynamicState = dynamicState;
        }

        this.mode = 'add';
        this.setState(param, () => {
            this.getDefaultFilter();// sửa lại props
        })
    };

    haveUnSaveData = () => {
        // Xóa dòng mới tao mà không được lưu đồng thời disabled form
        const {listFilter} = this.state;
        if (listFilter) {
            if (JSON.stringify(listFilter[0]) === '{}') {
                return true
            }
        }
    };


    onDeleteList = (e) => {
        const param = {
            FilterID: e.FilterID
        };
        this.mode = 'del';
        this.dataProcess(param);
    };

    onAddItem = (data) => {
        let dynamicState;
        if (data.ControlType === 1) {
            dynamicState = {
                [`${data.FieldID}From`]: '',
                [`${data.FieldID}To`]: '',
            }
        } else {
            dynamicState = {
                [data.FieldID]: '',
            }
        }
        this.setState({
            listFilterDetail: [...this.state.listFilterDetail, data],
            dynamicState: {...this.state.dynamicState, ...dynamicState}
        })
    };

    onSetDefault = () => {
        this.getDefaultFilter()
    };

    onChangePublic = (e) => {
        this.setState({
            isPublic: e.value
        })
    };

    //----------------------ENDMETHOD----------------------

    componentDidMount() {
        this.getFilter();
        this.getDefaultFilter();
    }

    render() {
        const {isToggle, listFilterDetail, switchButton, disabledForm, filter, dynamicState, isPublic, listFilter} = this.state;
        const {formID, visible} = this.props;

        return <Ui isToggle={isToggle}
                   listFilterDetail={listFilterDetail}
                   listFilter={listFilter}
                   switchButton={switchButton}
                   disabledForm={disabledForm}
                   filter={filter}
                   visible={visible}
                   dynamicState={dynamicState}
                   isPublic={isPublic}
                   formID={formID}

                   onDeleteList={this.onDeleteList}
                   onAddListFilter={this.onAddListFilter}
                   onChangePublic={this.onChangePublic}
                   onChangeDynamic={this.onChangeDynamic}
                   onGetRefDetail={this.onGetRefDetail}
                   onSearch={this.onSearch}
                   onNotSave={this.onNotSave}
                   onGetRefTextBox={this.onGetRefTextBox}
                   onCheckValid={this.onCheckValid}
                   onDeleteDetail={this.onDeleteDetail}
                   onValidateDate={this.onValidateDate}
                   onEdit={(e) => this.setDataDetail(e, 'edit')}
                   onSelect={this.setDataDetail}
                   onToggle={this.onToggle}
                   onHide={this.hidePop}
                   onAddItem={this.onAddItem}
                   onSetDefault={this.onSetDefault}
                   onAdd={this.onAdd}
                   onSave={this.onSave}
        />
    }
}

const Ui = memo((props) => {
    const {
        onHide, isToggle, onEdit, onToggle, listFilterDetail, onAdd,
        listFilter, onSave, switchButton, onValidateDate,
        onDeleteDetail, onNotSave, onGetRefTextBox, disabledForm, onSelect, onDeleteList, isPublic, onChangePublic,
        onSearch, onGetRefDetail, filter, onAddItem, onSetDefault, dynamicState, onChangeDynamic, formID, onAddListFilter
    } = props;

    return (
        <PopupView
            styleView={{overflow: 'auto'}}
            className={'popup-size-90 advanced-search-pop'}
            show={true}
            animation={true}
            onHide={onHide}>
            <PageContainer id={'advance-search'}>
                <Form horizontal={true}>
                    <FormGroup className={'mgb0'}>
                        {isToggle && <Col lg={4} md={4} style={{borderRight: '1px solid #c1c1c1', padding: 0}}>
                            <ListOption onEdit={onEdit}
                                        onAddListFilter={onAddListFilter}
                                        dataSource={listFilter}
                                        onDelete={onDeleteList}
                                        onSelect={onSelect}
                                        value={filter}
                                        onGetRefTextBox={onGetRefTextBox}
                            />
                        </Col>}
                        <Col lg={isToggle ? 8 : 12} md={isToggle ? 8 : 12}>
                            <DetailSearch toggleFilter={onToggle}
                                          formID={formID}
                                          disabledForm={disabledForm}
                                          dynamicState={dynamicState}
                                          isPublic={isPublic}
                                          ref={onGetRefDetail}
                                          switchButton={switchButton}
                                          dataSource={listFilterDetail}

                                          onChangePublic={onChangePublic}
                                          onAdd={onAdd}
                                          onChangeDynamic={onChangeDynamic}
                                          onSearch={onSearch}
                                          onSetDefault={onSetDefault}
                                          onNotSave={onNotSave}
                                          onDelete={onDeleteDetail}
                                          onValidateDate={onValidateDate}
                                          onAddItem={onAddItem}
                                          onSave={onSave}
                            />
                        </Col>
                    </FormGroup>
                </Form>
            </PageContainer>
        </PopupView>
    )
}, (prevProps, nextProps) => {
    /* Trả về true nếu nextProps bằng prevProps, ngược lại trả về false
    *  Đối với prop truyền trực tiếp mảng object thì sẽ luôn render lại
    *  Không compare dc mảng object nên JSON
    * // Không deep compare = JSON.stringnify ảnh hưởng performace - chưa fix
    * */
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
});

export default connect(null, (dispatch) => ({action: bindActionCreators(action, dispatch)}))(Container)