/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/08/09 22:59
 * @update 2019/08/09 22:59
 * @file src/components/common/items/staff-item.js
 */

import React, {Component, Fragment, memo} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import Config from "../../../config";
import AdvanceSearch from "../../common/advanced-search/advanced-search";


class SearchToolBar extends Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.timeDeplay = 3000;
        this.state = {
            showPopUpAS: false,
            isAS: false,
        }

    }

    onSearch = () => {
        const {onSearch} = this.props;
        onSearch && onSearch(this.vlFilter);
    };

    onKeyUp = (e) => {
        this.vlFilter = e ? e.target.value : null;

        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            // console.log('============= this.vlFilter: ', this.vlFilter);
            if ((this.vlFilter === null || this.vlFilter.length === 0) && this.props.onReset) {
                this.props.onReset();
            } else {
                this.onSearch();
            }
        }, this.timeDeplay / 3);
    };

    onChange = (vl) => {
        this.vlFilter = vl.target.value
    };

    onTogglePopupAS = () => {
        this.setState({
            showPopUpAS: !this.state.showPopUpAS
        })
    };

    onHideAdvance = (e) => {
        const {onAdvancedSearch} = this.props;
        const param = {
            showPopUpAS: false,
        };
        if (e) param.isAS = e;
        this.setState(param, () => {
            if (e) onAdvancedSearch && onAdvancedSearch()
        })
    };

    _onAdvancedSearch = (e) => {
        if (!e) {
            Config.popup.show('INFO', Config.lang('CRM_Co_loi_khi_tim_kiem'));
            return false;
        }
        this.onHideAdvance(e)
    };

    onClearFilter = () => {
        const {onClearAdvanced} = this.props;

        this.setState({isAS: false});
        onClearAdvanced && onClearAdvanced();
    };

    render() {
        const {allowSearch, valueSearch, showAdvancedSearch, formID} = this.props;
        const {showPopUpAS, isAS} = this.state;

        return (
            <div className={allowSearch ? allowSearch : ''} style={{
                display: 'flex',
                alignItems: showAdvancedSearch ? 'center' : 'unset',
                borderRadius: 5,
                border: '1px solid rgb(206, 212, 218)',
                height: 28
            }}>
                {
                    showPopUpAS && createPortal(<AdvanceSearch formID={formID}
                                                               onSearch={this._onAdvancedSearch}
                                                               onHide={this.onHideAdvance}/>, document.getElementById('root'))
                }

                {showAdvancedSearch ? <AdvancedSearchInput
                        onChange={this.onChange}
                        onKeyUp={this.onKeyUp}
                        onSearch={this.onSearch}
                        valueSearch={valueSearch}
                        onClearFilter={this.onClearFilter}
                        isUse={isAS}
                        onToggle={this.onTogglePopupAS}/> :
                    <NormalSearchInput onChange={this.onChange}
                                       onKeyUp={this.onKeyUp}
                                       onSearch={this.onSearch}
                                       valueSearch={valueSearch}/>}


            </div>
        );
    }
}

const NormalSearchInput = memo((props) => {
    const {valueSearch, onChange, onKeyUp, onSearch} = props;
    return (
        <Fragment>
            <input type="text"
                   size={60}
                   style={{
                       border: 'none',
                       width: '100%',
                       outline: 'none',
                       borderTopLeftRadius: 5,
                       borderBottomLeftRadius: 5,
                       paddingLeft: 5
                   }}
                   defaultValue={valueSearch}
                   placeholder={Config.lang("CRM_Noi_dung_can_tim")}
                   onChange={onChange}
                   onKeyUp={onKeyUp}/>

            <span style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 50,
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 1,
                color: "#2a76bb",
                cursor: 'pointer',
                backgroundColor: "#eeeded",
                borderRadius: "0 5px 5px 0",
                textAlign: "center"
            }}
                  onClick={onSearch}>
                        <i className="fa fa-search"/>
                </span>
        </Fragment>

    )
});

const AdvancedSearchInput = (props) => {
    const {onToggle, onKeyUp, onChange, valueSearch, onClearFilter, isUse} = props;

    return (
        <Fragment>
            {!isUse ? <input type="text"
                             size={60}
                             style={{
                                 border: 'none',
                                 width: '100%',
                                 outline: 'none',
                                 borderTopLeftRadius: 5,
                                 borderBottomLeftRadius: 5,
                                 paddingLeft: 5
                             }}
                             defaultValue={valueSearch}
                             placeholder={Config.lang("CRM_Noi_dung_can_tim")}
                             onChange={onChange}
                             onKeyUp={onKeyUp}/> :
                <div style={{
                    border: 'none',
                    background: 'rgb(223, 225, 230)',
                    width: '100%',
                    outline: 'none',
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                    padding: '2px 4px'
                }}>
                    <span style={{color: '#42526e', marginRight: '5px'}}>{Config.lang("CRM_Tim_kiem_nang_cao")}</span>
                    <i onClick={onClearFilter} className="fas fa-times cursor-pointer advanced-search-clear-button"/>
                </div>
            }
            <span onClick={onToggle}
                  style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 50,
                      height: 25,
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: 1,
                      color: "#2a76bb",
                      cursor: 'pointer',
                      backgroundColor: "#eeeded",
                      borderRadius: "0 5px 5px 0",
                      textAlign: "center"
                  }}
            >
                        <i className="fa fa-search"/>
                </span>
        </Fragment>
    )
};

SearchToolBar.propTypes = {
    onSearch: PropTypes.func,
    onReset: PropTypes.func,
    className: PropTypes.string,
};

export default SearchToolBar;
