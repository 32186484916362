/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/container/app.js
 */

import React, { Component } from 'react';

import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import {logger} from 'redux-logger';
import * as reducers from '../reducers';
import ThemeWrapper from "./themewrapper";
import Index        from './index';
import moment from "moment-timezone";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
let createStoreWithMiddleware;

if (process.env.REACT_APP_LOGGER === 'YES') {
    createStoreWithMiddleware = applyMiddleware(thunk,logger)(createStore);
} else {
    createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
}

const reducer = combineReducers(reducers);
const store = createStoreWithMiddleware(reducer);

class App extends Component {
    render() {
        const {children} = this.props;
        return (
            <Provider store={store}>
                <ThemeWrapper>
                    <Index>
                        {children}
                    </Index>
                </ThemeWrapper>
            </Provider>
        );
    }
}

export default App;
