/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/03/04 10:37
 * @update 2021/01/26 02:11
 * @file src/components/info/info.js
 */

import React from 'react';
import { Image, Modal } from 'react-bootstrap';
import './popup.scss';
class PopupScroll extends React.PureComponent {

    onHide() {
        if (this.props.onHide) this.props.onHide();
    }

    onEntered = (e) => {
        setTimeout(() => {
            if (e) {
                e.removeAttribute("tabindex");
            }
        }, 400);
    };
    render() {
        const { show, title, children, animation, className, button } = this.props;
        let strClass = 'popup';
        if (className && className !== '') {
            strClass += ' ' + className;
        }
        // console.log("=============PopupView=============",strClass);
        return (
            <Modal onEntered={this.onEntered} className={strClass} bsSize="large" show={show ? true : false} dialogClassName="custom-modal " animation={animation ? true : false}>

                <div className="table-info display_col">
                    <div className="title-bar display_row align-between popup-header">
                        <div className="pu-title">{title || "View detail"}
                            <span>{button}</span>
                        </div>

                        <Image className="btn-close popup-btn-close" onClick={() => this.onHide()} src={require('../../images/icon-close2.png')} />
                    </div>

                    {children}
                </div>
            </Modal>
        );
    }
}

export default PopupScroll;