/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/02/26 19:08
 * @update 2019/02/26 19:08
 */

import React, {memo, PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as templateDropdownActions from "../../../../actions/template-dropdown-actions";
import Combo from '../combo';
import Config from "../../../../config";
import {Column} from "devextreme-react/data-grid";

class Container extends PureComponent {

    getDataSource = () => {
        this.props.action.getCustomerLevel((error) => {
            if (error) Config.popup.show('INFO', "CRM_Co_loi_trong_qua_trinh_xu_ly_du_lieu");
        })
    };

    componentDidMount() {
        this.getDataSource();
    }


    render() {
        const {dataSource, placeholder, onChange, showClearButton} = this.props;
        return <UI dataSource={dataSource}
                   placeholder={placeholder}
                   onChange={onChange}
                   showClearButton={showClearButton}/>
    }

}

const UI = memo((props) => (

    <Combo
        value={props.value}
        showClearButton={props.showClearButton}
        onActionWhenSelectChange={props.onChange}
        placeholder={props.placeholder}
        valueExpr={'CustomerLevelID'}
        displayExpr={'CustomerLevelName'}
        dataSource={props.dataSource}
    >
        <Column dataField={'CustomerLevelName'} dataType={'string'}/>
    </Combo>
));

Container.propTypes = {
    showClearButton: PropTypes.bool,
    modePopUp: PropTypes.bool,
    displayExpr: PropTypes.string,
    placeholder: PropTypes.string,
    reportTypeID: PropTypes.string,
    value: PropTypes.string,
    reset: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
};

export default connect(state => ({
        dataSource: state.templateDropdown.cboCustomerLevel
    }),
    (dispatch) => ({
        action: bindActionCreators(templateDropdownActions, dispatch)
    })
)(Container);