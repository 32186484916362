import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ButtonBasic from "./button-basic";

class ButtonExportReport extends Component {

    onClick = (e) => {
        const { onClick } = this.props;
        onClick && onClick(e)
    };

    render() {
        const { name, className, disabled, id } = this.props;

        return (

            <ButtonBasic id={id}
                disabled={disabled}
                className={`${className ? className : ''} btn-crm`}
                onClick={this.onClick}>
                <i className="fas fa-share-square text-orange" /> {name && '\u00A0'} {name}
            </ButtonBasic>
        );
    }
}

ButtonExportReport.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,

};

export default ButtonExportReport;