/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/11/2021
 * @Example
 */
import Api       from '../../services/api';
import Config    from "../../config";

export async function getFormInfo(params, cb) {
    try {
        let URL = Config.getSetting('REPORT_API_URL');
        URL = URL ? URL + '/w17f2500/get-form-info' : '/w17f2500/get-form-info';
        const res = await Api.put(URL, params);
        if (res && res.data) {
            cb && cb(null, res.data);
        } else {
            cb && cb(res, null);
        }
    } catch (e) {
        console.log('get form info error');
    }
}

export async function saveAttachment(params, cb) {
    try {
        let URL = Config.getSetting('REPORT_API_URL');
        URL = URL ? URL + '/w17f2500/save-attachment' : '/w17f2500/save-attachment';
        const res = await Api.put(URL, params);
        if (res && res.data) {
            cb && cb(null, res.data);
        } else {
            cb && cb(res, null);
        }
    }
    catch (e) {
        console.log('save attachment error');
    }
}

export async function saveVouchers(params, cb) {
    try {
        let URL = Config.getSetting('REPORT_API_URL');
        URL = URL ? URL + '/w17f2500/save-voucher-info' : '/w17f2500/save-voucher-info';
        const res = await Api.put(URL, params);
        if (res && res.data) {
            cb && cb(null, res.data);
        } else {
            cb && cb(res, null);
        }
    }
    catch (e) {
        console.log('save vouchers error');
    }
}

export async function fileUpload(params, cb) {
    try {
        let URL = Config.getSetting('REPORT_API_URL');
        URL = URL ? URL + '/file/upload' : '/file/upload';

        let body = new FormData();
        params = Array.isArray(params) ? params : [params];
        for(let i = 0; i < params.length; i++)
            body.append('files', params[i]);
        return await Api.upload(URL, body);
    }
    catch (e) {
        console.log('fileUpload error');
    }
}

export default {
    getFormInfo: getFormInfo,
    saveAttachment: saveAttachment,
    saveVouchers: saveVouchers,
    fileUpload: fileUpload,
};


