/**
 * @copyright 2018 @ C.O.I
 * @author rocachien
 * @create 2017/06/30 15:34
 * @update 2017/06/30 15:34
 * @file src/components/common/course/course.js
 */

import React, {PureComponent, memo} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux'
import Combo from "../combo";
import {connect} from 'react-redux'
import * as action from "../../../../actions/template-dropdown-actions";
import {Column} from "devextreme-react/data-grid";


export class Container extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            reset: false
        }
    }

    getDataSource = (reset = false) => {
        const param = {
            SalesOfficeID: this.props.salesOfficeID
        };

        this.props.action.getCboDepartment(param, (error, data) => {
            this.setState({
                dataSource: data,
                reset: reset
            })
        })
    };

    componentDidMount() {
        this.getDataSource();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.salesOfficeID !== this.props.salesOfficeID) {

            this.getDataSource(true);
        }
    }

    _onChange = (e) => {
        const {onChange} = this.props;
        this.setState({
            reset: false
        });
        onChange && onChange(e);
    };

    render() {
        const {showClearButton, value} = this.props;
        const {dataSource, reset} = this.state;

        return <Ui dataSource={dataSource}
                   onChange={this._onChange}
                   reset={reset}
                   value={value}
                   showClearButton={showClearButton}/>
    }
}

const Ui = memo((props) => {
    const {dataSource, onChange, showClearButton, value, reset} = props;

    return (
        <Combo
            reset={reset}
            valueExpr={'SalesGroupID'}
            value={value}
            displayExpr={'SalesGroupName'}
            onActionWhenSelectChange={onChange}
            showClearButton={showClearButton}
            dataSource={dataSource}>
            <Column dataField={'SalesGroupName'} dataType={'string'}/>
        </Combo>
    )
});


Container.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    data: PropTypes.array,
    showClearButton: PropTypes.bool,
    onChange: PropTypes.func,
    salesOfficeID: PropTypes.string,
};

export default connect(
    state => ({dataSource: state.templateDropdown.cboArea}),
    dispatch => ({
        action: bindActionCreators(action, dispatch)
    }))(Container);