/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 12/8/2019
 * @Example W19F1031
 */
/**
 *@Type
 * image
 * text
 * highlight tô màu và đóng khung
 */

import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import * as W19F1031Actions from '../../../actions/w19/w19F1031/w19f1031-actions'
import {bindActionCreators} from "redux";
import moment from 'moment';
import ReactLoading from "react-loading";
import Config from "../../../config";
import DOMPurify from 'dompurify';
import {Col, Image} from "react-bootstrap";

export class SquareInfo extends Component {

    render() {
        return (
            <div className={'Square-Info'}
                 style={{background: this.props.color ? this.props.color : 'rgb(222, 235, 255)'}}>
                {this.props.children}
            </div>
        )
    }
}

export class ActivityIfo extends Component {

    renderDetail = (mode) => {
        //mode 1 = giá trị cũ, mode 2 = giá trị mới
        const {imageDetailFrom, contentDetailFrom, type, contentDetailTo, imageDetailTo} = this.props;
        let image = mode === 1 ? imageDetailFrom : imageDetailTo;
        let content = mode === 1 ? contentDetailFrom : contentDetailTo;

        if (moment(content, 'YYYY-MM-DD', true).isValid() || moment(content, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
            const dateF = moment(content);
            if (dateF.hours() === 0 && dateF.minutes() === 0 && dateF.seconds() === 0) {
                content = dateF.format('DD/MM/YYYY');
            } else {
                content = dateF.format('DD/MM/YYYY, h:mm:ss a');
            }
        } else {
            content = content ? <div
                className={'activity-content'}
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content)}}/> : null;
        }
        switch (type) {
            case 'image':
                const index = image.indexOf('/file/get-image-by-user-id');
                image = Config.env.api + image.slice(index);
                return (content && <React.Fragment>
                    <img className={'activity-user-image'} alt={''}
                         onError={(e) => this.onErrorImg(e)}
                         src={image ? image : require('./../../../images/icon-user-default.png')}
                    />&nbsp;
                    <div>{content}</div>
                </React.Fragment>);
            case 'highlight':
                return content ?
                    <SquareInfo color={mode === 2 ? 'rgb(223, 225, 230)' : ''}>{content}</SquareInfo> : null;
            default:
                return content;
        }
    };

    componentDidMount() {
        setTimeout(() => {
            const fromWidth = this.refs['detailFrom'].clientWidth + 25;
            const parentWidth = this.refs['detailFrom'].parentElement.clientWidth;
            const toWidth = 100 - (fromWidth * 100 / parentWidth);
            this.refs['detailTo'].style.maxWidth = Number.parseInt(toWidth) + '%';
        }, 200);
    }

    onErrorImg(e) {
        e.target.src = require('./../../../images/icon-user-default.png');
    }

    render() {
        const {action, contentDetailFrom, contentDetailTo} = this.props;
        const showArrow = (contentDetailTo || contentDetailFrom) && action === 1;
        return (
            <React.Fragment>
                <div className={'Activity-Content-DetailFrom'} ref={'detailFrom'}>
                    <div className={'Activity-Content-Info'}>
                        {this.renderDetail(1)}
                    </div>
                </div>
                {showArrow &&
                <i className="far fa-long-arrow-alt-right Activity-Arrow"/>
                }
                <div className={'Activity-Content-DetailTo'} ref={'detailTo'}>
                    <div className={'Activity-Content-Info'}>
                        {this.renderDetail(2)}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export class ActivityInfo extends Component {
    renderAction = (data) => {
        let action;
        switch (data) {
            case 0:
                action = Config.lang('CRM_Them');
                return action;
            case 1:
                action = Config.lang('CRM_Dieu_chinh');
                return action;
            case 2:
                action = Config.lang('CRM_Huy_bo');
                return action;
            default:
                return action;
        }
    };

    render() {

        const {contentDetailFrom, contentDetailTo, contentName, action, userID, date, type, imageDetailFrom, imageDetailTo} = this.props;
        let userName, userImage;
        if(userID) {
            const user = Config.getUser({UserID: userID});
            if(user){
                userName = user.SalesPersonNameU;
                userImage = user.URL;
            }
        }
        return (
            <div className={'Activity-Info'}>
                <img alt={'not found'} className={'Activity-User-Image'}
                     src={userImage ? userImage : require('./../../../images/icon-user-default.png')}
                />
                <div className={'Activity-User-Content'}>
                    <div>
                        <span style={{fontSize: '12px'}}
                              className={'Activity-User-Name'}>{userName ? `${userName} ` : ''}</span>
                        <span style={{fontSize: '12px'}}>{`${this.renderAction(action)} `}</span>
                        <span style={{fontSize: '12px'}} className={'Activity-User-Content-Name'}>{contentName}</span>
                        <span className={'text-muted'}
                              style={{fontSize: '12px'}}>{` ${moment(date).format('DD/MM/YYYY HH:mm')}`}</span>
                    </div>
                    <div className={'Activity-Content-Detail'}>
                        <ActivityIfo
                            type={type}
                            contentDetailFrom={contentDetailFrom}
                            contentDetailTo={contentDetailTo}
                            imageDetailFrom={imageDetailFrom}
                            imageDetailTo={imageDetailTo}
                            action={action}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export class ActivityComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isShow: false,
            dataGrid: [],
        };
        this.filter = {
            limit: 20,
            skip: 0,
            sort: JSON.stringify([{
                createdAt: 'DESC'
            }]),
        };
        this.allowLoad = true;
    }

    resetHistory = () => {
        document.getElementById('Activity-Container').scrollTo(0, 0);
        this.filter.skip = 0;
        this.allowLoad = true;
        this.setState({
            dataGrid: []
        }, () => {
            this.loadGrid();
        });
    };

    loadGrid() {
        this.allowLoad = false;
        const {dataGrid} = this.state;
        const {condition} = this.props;
        if (condition) this.filter.condition = JSON.stringify(condition);
        this.setState({loading: true});
        document.getElementById('Activity-Container').style.filter = 'blur(1px)';
        this.props.W19F1031Actions.loadHistory(this.filter, (error, result) => {
            document.getElementById('Activity-Container').style.filter = '';
            if (error) return false;
            if (result && result.data) {
                const {data} = result;
                if (data.rows && data.rows.length > 0) {
                    const newData = [...dataGrid, ...data.rows];
                    if (newData.length !== data.total) {
                        this.allowLoad = true;
                    }
                    this.setState({
                        dataGrid: newData
                    })
                }
            }
            this.setState({loading: false})
        })
    }

    componentDidMount() {
        this.loadGrid();
        setTimeout(() => {
            this.setState({
                isShow: true
            });
        }, 30);
    }

    loadMoreData() {
        if (!this.allowLoad) return false;
        const el = document.getElementById('Activity-Container');
        if (el.scrollHeight - el.scrollTop - 10 <= el.clientHeight) {
            this.loadGrid();
            this.filter.skip = this.filter.skip + 20;
        }
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        const {dataGrid, title, loading, isShow} = this.state;
        const {xs, sm, md, lg, xl} = this.props;
        const historyClassName = window.innerWidth < 768 ? `popup-history ${!isShow ? 'history-hidden' : ''}` : '';
        return (
            <React.Fragment>
                <Col xl={xl && xl} lg={lg ? lg : 4} md={md ? md : 5} sm={sm ? sm : 5} xs={xs && xs}
                     className={`${historyClassName} activity-component`} style={{transition: 'width 0.2s'}}>
                    <div id={'Activity-Component'}>
                        <Image id={'close-activity'} className="btn-close popup-btn-close"
                               onClick={() => this.onClose()} src={require('../../../images/icon-close2.png')}/>
                        {title && <div>{title}</div>}
                        {loading && <div id={'loading-activity'}>
                            <ReactLoading type={"spinningBubbles"}
                                          color={'cornflowerblue'}
                                          height={60}
                                          width={60}
                            />
                        </div>}
                        <div id={'Activity-Container'}
                             onScroll={() => this.loadMoreData()}>
                            {dataGrid && dataGrid.map((value, index) => {
                                return (
                                    <ActivityInfo
                                        key={`ActivityInfo${index}`}
                                        type={value.type}
                                        date={value.createdAt}
                                        userID={value.userID}
                                        action={value.action}
                                        contentName={value.description84}
                                        contentDetailFrom={value.oldNameU ? value.oldNameU : value.oldValue}
                                        imageDetailFrom={value.urlOldValue}
                                        imageDetailTo={value.urlNewValue}
                                        contentDetailTo={value.newNameU ? value.newNameU : value.newValue}
                                        userImage={value.urlUserID}
                                    />)
                            })}
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        )
    }
}

ActivityInfo.propTypes = {
    userName: PropTypes.string,
    action: PropTypes.number,
    contentName: PropTypes.string,
    contentDetailFrom: PropTypes.string,
    contentDetailTo: PropTypes.string,
    date: PropTypes.string,
    userImage: PropTypes.string,
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
    onClose: PropTypes.func
};

ActivityComponent.propTypes = {
    condition: PropTypes.object,// Tham khảo api history điều kiện lọc
};
export default connect(() => ({}), (dispatch) => ({
    W19F1031Actions: bindActionCreators(W19F1031Actions, dispatch)
}), null, {withRef: true})(ActivityComponent)
