/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 12/8/2019
 * @Example W19F1031
 */
/**
 *@Type
 * image
 * text
 * highlight tô màu và đóng khung
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {browserHistory, Link} from "react-router";

class LinkComponent extends Component {

    render() {
        const {getData, storeState, children} = this.props;
        const data = getData ? getData() :null;
        return (
            <div onClick={()=>{
                if(!data || !data.pathname){
                    alert('Url not found');
                    return;
                }
                if (storeState) storeState();
                browserHistory.push(data);
            }}
            >
                {data &&
                    <Link className={'common-link'}
                          to={{
                              pathname:data.pathname,
                              query: data.state
                          }}
                          onClick={(e) => {
                              e.preventDefault();
                          }}
                     onlyActiveOnIndex>{children}</Link>
                }
                {!data && children}
            </div>
        )
    }
}

LinkComponent.propTypes = {
    getData: PropTypes.func,
    storeState: PropTypes.func
};

export default LinkComponent
