/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/25/2019
 * @Example
 */

import React from "react";
import {Collapse, Fade, Grow, Slide} from "@material-ui/core";

export const popupTransitions = {
    Slide: React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    }),
    Grow: React.forwardRef(function Transition(props, ref) {
        return <Grow ref={ref} {...props} />;
    }),
    Fade: React.forwardRef(function Transition(props, ref) {
        return <Fade ref={ref} {...props} />;
    }),
    Collapse: React.forwardRef(function Transition(props, ref) {
        return <Collapse ref={ref} {...props} />;
    })
};

export function getExtFile(fileName) {
    if (!fileName) return "";
    const arrNames = fileName.split('.');
    return arrNames.length > 1 ? arrNames[arrNames.length - 1] : "";
}