/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {FormGroup, Col, Row} from 'react-bootstrap';
import notify from 'devextreme/ui/notify';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ButtonSave from "../button/button-save";
import ButtonNotSave from "../button/button-notsave";
import ButtonBasic from "../button/button-basic";
import * as w17Actions from "../../../actions/w17-actions";
import * as fileActions from "../../../actions/file-actions";
import PropTypes from 'prop-types';
import Config from '../../../config/index';
import {
    TextArea,
    TextBox,
    Button
} from 'devextreme-react';
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';

const cssForm = {margin: '15px 15px 15px 15px'};
class Acttachment extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            attachmentID: this.props.attachmentID,
            id: this.props.id,
            tableName: this.props.tableName,
            caption: this.props.caption,
            value: this.props.value,
            notes: this.props.data.notes,
            description: this.props.data.description,
            fileName: this.props.data.fileName,
            fileSize: this.props.data.fileSize,
            url: this.props.data.url,
            createDate: this.props.data.createDate,
            isUploaded: false,
            isSubmit:false
        };
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 3000;
    };

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }


    checkFileType(filename) {
        const extension = filename.substr(filename.lastIndexOf('.')).toLowerCase();
        const allowedExtensions = ['.txt', '.jpg', '.png','.xls','.xlsx', '.doc', '.docx', '.pdf'];
        if (extension.length > 0) {
            if (allowedExtensions.indexOf(extension) === -1) {
                return false;
            }
        }
        return true;
    }

    onSelectFile(e) {
        const x = document.getElementById("file");
        const arrFile = x.files;
        // console.log(arrFile);
        const sizeLimit = 50000000;
        for (let i = 0; i < arrFile.length; i++) {
            if ((arrFile[i].size / 1024) > Number(sizeLimit)) {
                Config.popup.show("INFO", Config.lang("CRM_Dung_luong_File_khong_duoc_lon_hon")+ " " + sizeLimit + ' KB');
            } else if (this.checkFileType(arrFile[i].name) === false) {
                Config.popup.show("INFO", Config.lang('CRM_Dinh_dang_file_khong_hop_le'));
            }
            else { //truong hop hop le
                const item = document.getElementById("btnItem");
                item.classList.remove("hide");
                this.setState({fileName: arrFile[i].name});
            }
        }
    }

    removeFile() {
        const item = document.getElementById("btnItem");
        item.classList.add("hide");
        this.setState({fileName: ''});
    }

    onChangeInput() {
        const x = document.getElementById("file");
        const event = new MouseEvent('click');
        x.dispatchEvent(event);
    }

    onEventSave() {
        this.onFormSubmit();
    }

    onFormSubmit(e) {
        // e.preventDefault();
         // console.log('=======onFormSubmit=======', e);
            let attachmentID = '';
            const keyID = this.state.id;
            const description = this.state.description.trim();
            const tableName = this.state.tableName;
            const notes = this.state.notes;
            const fileName = Config.replaceES(this.state.fileName);
            const url = this.state.url;
            const fileSize = this.state.fileSize;
            const fileExp = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();
            if(description === "" || description === undefined){
                this.setState({ description: " " }); // Fix bug validate
                Config.popup.show("INFO",`${Config.lang("CRM_Tieu_de_khong_duoc_de_trong")}`);
                return false;
            }else{
                if (this.state.attachmentID !== '') {
                    attachmentID = this.state.attachmentID;
                    let param = {
                        'KeyID': keyID,
                        'AttachmentID': attachmentID,
                        'DescriptionU': description,
                        'NotesU': notes,
                        'URL': url,
                        'FileName': fileName,
                        'FileSize': fileSize,
                        'FileExt': fileExp,
                        'TableName': tableName
                    };
                    // console.log("============onFormSubmit=============",param);
                    this.props.w17Actions.editW17F0001(param, (error, data) => {
                        if (error) {
                            let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                            Config.popup.show('INFO', message);
                            return false;
                        }
                        if (data && data.code === 200) {
                            // Show message success and close popup
                            notify(Config.lang('CRM_Luu_thanh_cong'),"success",2000);
                            this.props.onReloadGrid();
                        }
                    });
                } else {
                    this.setState({isSubmit:true});
                    const x = document.getElementById("file");
                    const file = x.files[0];
                    this.props.fileActions.upload(file,'single', (error, res) => {
                        if (error) {
                            let message = error.message ||  Config.lang("CRM_Loi_chua_xac_dinh");
                            Config.popup.show('INFO', message);
                            return false;
                        }
                        // console.log('===========fileActions=============', res);
                        if (res.code === 200) {
                            const data = res.data;
                            let param = {
                                'KeyID': keyID,
                                'AttachmentID': attachmentID,
                                'DescriptionU': description,
                                'NotesU': notes,
                                'URL': data && data.length > 0 ? data[0].path : '',
                                'FileName': fileName,
                                'FileSize': file.size,
                                'FileExt': fileExp,
                                'TableName': tableName
                            };
    
                            this.props.w17Actions.addW17F0001(param, (error, res2) => {
                                if (error) {
                                    let message = error.message ||  Config.lang("CRM_Loi_chua_xac_dinh");
                                    Config.popup.show('INFO', message);
                                    return false;
                                }
                                if (res2 && res2.code === 200) {
                                    // Show message success and close popup
                                    notify(Config.lang('CRM_Luu_thanh_cong'),"success",2000);
                                    this.props.onReloadGrid();
                                }
                            });
                        }
                    });
                }
            }
    }

    render() {

        return (
            <form  style={cssForm} onSubmit={(e) => this.onFormSubmit(e)} >
                <FormGroup >
                    <Row>
                        <Col sm={2} className="lb-nomal">
                            {this.state.caption}
                            </Col>
                        <Col sm={10} className="lb-nomal">{this.state.value}</Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={2} className="lb-nomal">{Config.lang("CRM_Tieu_de")}<i className="error">*</i></Col>
                        <Col sm={10}>
                            <TextBox name={"DescriptionU"}
                                     value={this.state.description}
                                     onChange={(e) => this.onTextValueChanged(e)}
                                     className="input-devextreme"
                            >
                                <Validator >
                                    <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                                </Validator>
                            </TextBox>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup
                    controlId="title"
                >
                    <Row>
                        <Col sm={2} className="lb-nomal">{Config.lang("CRM_Mo_ta")}</Col>
                        <Col sm={10}>
                         <TextArea name={"Notes"}
                                   height={90}
                                   value={this.state.notes}
                                   text={this.state.notes}
                                   onChange={(e) => this.onTextValueChanged(e)}
                                   className="input-devextreme"
                         >
                         </TextArea>

                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col sm={2} className="lb-nomal">
                            {Config.lang("CRM_Tai_len")}
                        </Col>
                        <Col sm={3}>
                            <FormGroup controlId="formBasicText">
                                <ButtonBasic onClick={() => this.onChangeInput()}
                                             disabled={this.state.attachmentID !== '' || this.state.isUploaded === true}>
                                    <i className="fas fa-paperclip text-blue"/> &nbsp; {Config.lang("CRM_Chon_tap_tin")}
                                </ButtonBasic>
                                <input type="file" id="file" name="files[]" className="hide"
                                       onChange={() => this.onSelectFile()}/>
                            </FormGroup>
                        </Col>
                        <Col sm={7} name="FileName">
                            <FormGroup controlId="formBasicText">
                                {this.state.fileName} &nbsp;
                                <button id="btnItem" className="btn hide" type="button"
                                        disabled={this.state.attachmentID !== '' || this.state.isUploaded === true}
                                        onClick={() => this.removeFile()}><i
                                    className="fas fa-trash-alt text-red"/>
                                </button>
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"jus-end"}>
                    <ButtonSave disabled={this.state.isUploaded === true || this.state.fileName === ''|| this.state.isSubmit === true}
                                onClick={() => this.onEventSave()} className={"mgr5"} name={Config.lang("CRM_Luu")}/>
                    <ButtonNotSave name={Config.lang("CRM_Khong_luu")} onClick={() => {
                        this.props.onHide()
                    }}/>
                    <Button id="btnSubmit" useSubmitBehavior={true} className="hide"/>
                </FormGroup>
            </form>
        )
    }

    onTextValueChanged(e) {
        // console.log(e);
        switch (e.event.target.name) {
            case "Name":
                this.setState({name: e.event.target.value});
                break;
            case "Notes":
                this.setState({notes: e.event.target.value});
                break;
            case "DescriptionU":
                this.setState({description: e.event.target.value});
                break;
            default:
                break;
        }
    }


}

Acttachment.propTypes = {
    attachmentID: PropTypes.string,
    id: PropTypes.string,
    caption: PropTypes.string,
    value: PropTypes.string,
    tableName: PropTypes.string,

    onHide: PropTypes.func,
    onReloadGrid: PropTypes.func
};


export default connect(state => ({
        getW17F0001: state.w17.getW17F0001,
        addW17F0001: state.w17.addW17F0001,
        editW17F0001: state.w17.editW17F0001
    }),
    (dispatch) => ({
        w17Actions: bindActionCreators(w17Actions, dispatch),
        fileActions: bindActionCreators(fileActions, dispatch)
    })
)(Acttachment);
